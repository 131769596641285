import React, { useEffect } from 'react';
import { useState } from "react";
import {
    Button,
    Card,
    Col,
    Label,
    Row,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
    Form
} from "reactstrap";
import { error, success } from "helpers/notification"
import RetailerDebounceSelect from 'pages/members/component/retailer-debounce-select';
import ConstructorRetailerModel from 'api/ConstructorRetailerModel';


const FormMatchingModal = (props) => {
    const { constructorRetailer } = props
    const [validation, setValidation] = useState({})
    const [modal, setModal] = useState(false);
    const [form, setForm] = useState({
        type: 1
    })

    useEffect(() => {
        setModal(props.open)
    }, [props])


    const isValid = () => {
        setValidation(error)
        if (!form.retailerId) {
            setValidation({ ...error, uids: "Vui lòng nhập chọn cửa hàng" })
            return false;
        }
        return true;
    }

    const submit = async () => {
        if (!isValid()) {
            return;
        }

        let result = await ConstructorRetailerModel.matching(constructorRetailer.id, form.retailerId)
        if (result.error != 0) {
            error(result.msg)
            return;
        }
        success("Matching thành công")
        props.onClose()
        props.onSuccess()
    }


    return (
        <>
            <Modal
                isOpen={modal}
            >
                <ModalHeader tag="h4">
                    Matching Tài khoản cửa hàng
                </ModalHeader>
                <ModalBody className='pt-0'>
                    <Card>
                        <Form>
                            <Row style={{ padding: '15px 0' }}>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="promotion">Địa chỉ hoặc SDT cửa hàng nhà thầu cung cấp</Label>
                                        <input
                                            type="text"
                                            onChange={() => { }}
                                            value={constructorRetailer?.draft}
                                            className="form-control"
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="mb-3 templating-select select2-container">
                                        <label className="control-label">Tài khoản cửa hàng đã xác thực</label>
                                        <RetailerDebounceSelect onChange={(value) => {
                                            setForm({ ...form, retailerId: value })
                                        }} />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                    <ModalFooter>
                        <Button
                            onClick={() => { props.onClose() }}
                            type="button"
                            color="secondary"
                        >
                            Đóng
                        </Button>
                        <Button
                            type="button"
                            color="success"
                            onClick={() => { submit() }}
                        >
                            Chọn
                        </Button>
                    </ModalFooter>


                </ModalBody >
            </Modal >
        </>
    )
}

export default FormMatchingModal;