import * as ApiHelper from './api_helper'

class MatchModel {
    async find(page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                page: page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`match-football/find?${new URLSearchParams(params).toString()}`)
    }

    async create(host, guest, timeStart) {
        let data = Object.fromEntries(
            Object.entries({
                host, guest, timeStart
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.post(`match-football/create`, data)
    }

    async get(id) {
        return ApiHelper.get(`match-football/get?id=${id}`)
    }

    async update(id, host, guest, timeStart) {
        let data = Object.fromEntries(
            Object.entries({
                host, guest, timeStart
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.post(`match-football/update?id=${id}`, data)
    }

    async updateStatus(id, status) {
        let data = {
            id: id,
            status: status
        }
        return ApiHelper.post(`match-football/update-status`, data);
    }

    async updateResult(id, goalHost, goalGuest) {
        let data = {
            goalHost, goalGuest
        }
        return ApiHelper.post(`match-football/update-result?id=${id}`, data);
    }

    async createBroadcast(matchId, promotionId, time) {
        return ApiHelper.get(`match-football/broadcast?matchId=${matchId}&promotionId=${promotionId}&time=${time}`);
    }

    async export(promotionId) {
        let params = Object.fromEntries(
            Object.entries({
                promotionId
            }).filter(([_, v]) => v != null && v != 0)
        );
        window.open(ApiHelper.getApiUrl() +
            `match-football/export-excel?${new URLSearchParams(params).toString()}`, '_blank');
        return;
    }
}

export default new MatchModel();
