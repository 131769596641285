// TableMember

import { Avatar, Button, Image, Popconfirm, Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import TimeUtils from "helpers/time.utils"
import { useHistory } from "react-router-dom"
import queryString from 'query-string'
import SurveyModel from "api/SurveyModel"
import {
    Row, Col,
} from "reactstrap"

// TableSurvey
const TableFormSurvey = props => {
    let history = useHistory();
    const [survey, setSurvey] = useState()
    const { broadcastId, topicId } = queryString.parseUrl(history.location.search).query;

    let columns = [
        {
            title: "Avatar",
            dataIndex: "user",
            render: user => <Avatar src={user.avatar} size="large" />,
        },
        {
            title: "Họ tên",
            dataIndex: "user",
            render: user => user.name
        },
        {
            title: "SDT",
            dataIndex: "user",
            render: user => user.phone
        }
    ]


    if (survey) {
        const fields = survey?.topic?.fields;
        fields.forEach(field => {
            if (field.includes("image")) {
                columns.push({
                    title: field,
                    dataIndex: "questions",
                    render: questions => {
                        const images = questions[field];
                        const arrImage = images && images.split(",")
                        return arrImage ? arrImage.map((item, index) => {
                            return (
                                <Image style={{ width: '60px', height: '60px' }} key={index} src={item} />
                            )
                        }) : ''
                    }
                })
            } else {
                columns.push({
                    title: field,
                    dataIndex: "questions",
                    render: questions => {
                        return questions[field]
                    }
                })
            }
        })
    }

    columns.push({
        title: "Thời gian",
        dataIndex: "updatedTime",
        render: updatedTime => TimeUtils.toDDMMYYYY(updatedTime * 1000)
    })

    columns.push({
        title: "Action",
        dataIndex: "id",
        render: (id) => {
            return <>
                <Popconfirm title="Bạn có chắc?" onConfirm={() => {
                    SurveyModel.delete(id)
                        .then(resp => {
                            fetchData(pagination)
                        })
                }}>
                    <Button danger>Xóa</Button>
                </Popconfirm>
            </>
        }
    })

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [filter, setFilter] = useState({ ...props.filter, ...queryString.parseUrl(history.location.search).query })

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.replace({ search: url })
    };

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await SurveyModel.findForms(
            broadcastId,
            topicId,
            newPagination.current,
            newPagination.pageSize
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    const fetchSurvey = async (broadcastId, topicId) => {
        setLoading(true)
        let results = await SurveyModel.get(
            broadcastId,
            topicId
        )
        setSurvey(results?.data)
        setLoading(false)
    }

    useEffect(() => {
        fetchSurvey(broadcastId, topicId)
    }, [])

    useEffect(() => {
        fetchData({ current: 1, pageSize: pagination.pageSize })
        serialize2Url()
    }, [filter])

    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }


    return (
        <>
            <Row>
                <div className="text-sm-end mb-3">
                    <Row>
                        <Col sm={12}>
                            <div className="btn-group mt-2" role="group">
                                <Button
                                    color="light"
                                    className="btn btn-primary "
                                    style={{ marginLeft: "10px", padding: "0px 30px", boxSizing: 'content-box' }}
                                    size={24}
                                    onClick={() => { SurveyModel.export(broadcastId, topicId) }}
                                >
                                    Xuất Excel
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />

        </>
    )
}

export default TableFormSurvey
