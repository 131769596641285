// TableMember

import React from "react";
import { useParams } from "react-router-dom";
import LivestreamTableForm from "pages/forms/tablev2/LivestreamTableForm";

const LiveStreamProgram = props => {
    const { promotionId } = useParams()

    return (
        <>
            <LivestreamTableForm promotionId={promotionId} />
        </>
    )
}

export default LiveStreamProgram
