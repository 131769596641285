// TableMember

import { Button, Table, Col, Row } from "antd"
import React, { useEffect, useState } from "react"

import TimeUtils from "helpers/time.utils"
import { Link, useHistory } from "react-router-dom"
import queryString from 'query-string'
import AudienceModel from "api/AudienceModel"
import AudienceType from "constants/AudienceType"
import FormAudienceModal from "../modal/form-audience-modal"
import { error, success } from "helpers/notification"
import MultiFormAudienceModal from "../modal/multi-form-audience-modal"

const TableAudience = props => {
    const [createModal, setCreateModal] = useState(false)
    const [multiCreateModal, setMultiCreateModal] = useState(false)

    let history = useHistory();

    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Alias",
            dataIndex: "alias",
            render: alias => {
                return alias || 'Chưa có alias'
            }
        },
        {
            title: "Loại",
            dataIndex: "type",
            render: type => {
                return AudienceType.findByName(type);
            }
        },
        {
            title: "Thời gian",
            dataIndex: "createdTime",
            render: createdTime => TimeUtils.toDDMMYYYY(createdTime * 1000),
        },
        {
            title: "Hành động",
            render: (_, record) => (
                <>
                    <Button type="primary" size="small" style={{ width: "120px" }} onClick={() => history.push(`/audience/${record.id}`)} >
                        Chi tiết
                    </Button>

                </>
            ),
        },
    ]

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [filter, setFilter] = useState({ ...props.filter, ...queryString.parseUrl(history.location.search).query })

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.replace({ search: url })
    };

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await AudienceModel.find(
            newPagination.current,
            newPagination.pageSize
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData({ current: 1, pageSize: pagination.pageSize })
        serialize2Url()
    }, [filter])

    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    return (
        <>
            <Row style={{ justifyContent: 'right' }}>
                <Col sm={12}>
                    <div className="text-sm-end mb-3">
                        <div className="btn-group " role="group">
                            <input
                                className="form-control"
                                type="search"
                                placeholder="Nhập Alias Hoặc ID audience"
                                style={{ marginLeft: "10px" }}
                                onChange={e => setFilter({ ...filter, search: e.target.value })}
                            />
                            <Button type="primary" style={{ marginLeft: "10px", height: '36px', borderRadius: '0.25rem' }} size={28}>
                                <Link onClick={() => { setCreateModal(true) }}>Tạo Audience</Link>
                            </Button>
                            <Button type="dashed" danger style={{ marginLeft: "10px", height: '36px', borderRadius: '0.25rem' }} size={28}>
                                <Link onClick={() => { setMultiCreateModal(true) }}>Tạo hàng loạt</Link>
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />

            <FormAudienceModal open={createModal} toggle={setCreateModal} onSubmitSuccess={(id) => {
                success("Tạo audience thành công")
                fetchData({ current: 1, pageSize: pagination.pageSize })
            }} />

            <MultiFormAudienceModal open={multiCreateModal} toggle={setMultiCreateModal} onSubmitSuccess={() => {
                fetchData({ current: 1, pageSize: pagination.pageSize })
            }} />

        </>
    )
}

export default TableAudience
