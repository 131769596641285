import PromotionType from "./PromotionType";

class BroadcastType {
    static ZNS = 1;
    static POST = 2;
    static NOTIFY_PREDICT_FOOTBALL = 3;
    static MSG = 4;
    static SURVEY = 5;
    static AS_FAST_AS_FIGHTING_MINI_GAME = 6;
    static LIVE_STREAM_BROADCAST_PROMOTION = 7;

    static findName(value) {
        switch (value) {
            case this.ZNS: return 'ZNS'
            case this.POST: return 'Bài viết'
            case this.NOTIFY_PREDICT_FOOTBALL: return 'Mời dự đoán bóng đá'
            case this.MSG: return 'Tin nhắn'
            case this.SURVEY: return 'Khảo sát'
            case this.AS_FAST_AS_FIGHTING_MINI_GAME: return 'Nhanh như chớp'
            case this.LIVE_STREAM_BROADCAST_PROMOTION: return 'Live Stream'
        }
    }

    static findIdByTypePromotion(type) {
        switch (type) {
            case PromotionType.AS_FAST_AS_LIGHTING_MINI_GAME: {
                return this.AS_FAST_AS_FIGHTING_MINI_GAME;
            }
            case PromotionType.LIVE_STREAM_BROADCAST_PROMOTION: {
                return this.LIVE_STREAM_BROADCAST_PROMOTION;
            }
            default: {
                return -1;
            }
        }
    }

}

export default BroadcastType;