import { Popconfirm, Upload } from "antd"
import React, { useEffect, useState } from "react"
import { Button, Card, Col, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { UploadOutlined } from '@ant-design/icons';
import * as ApiHelper from '../../../api/api_helper'
import { JsonView, allExpanded, darkStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import ArticleModel from "api/ArticleModel";
import { success } from "helpers/notification"
import { error } from "helpers/notification"
import { useHistory } from "react-router-dom";

const GeneratorTemplateModal = props => {
    const history = useHistory();
    const [saved, setSaved] = useState(true);
    const [data, setData] = useState()


    useEffect(() => {
        if (props.post && props.post.dataTemplate) {
            setData(props.post.dataTemplate)
        }
    }, [props])


    const execution = () => {
        ArticleModel.generateBytemplate(props.post.id)
            .then(resp => {
                if (resp.error == 0) {
                    success("Thành công!")
                    history.push("/articles?tab=2&templateId=" + props.post.id)
                } else {
                    error(resp.msg)
                }
            })
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
            <ModalHeader className="text-center">{props.title}</ModalHeader>
            <ModalBody>
                <Card>
                    <Form>
                        <Row>
                            <Col sm={12}>
                                <span>Format File</span>
                                <ul>
                                    <li>Mỗi biến sẽ là 1 cột</li>
                                    <li>Mỗi dòng sẽ là một bài viết</li>
                                    <li>Mặc định chương trình sẽ là : promotionId</li>
                                    <li>Tập đối tượng sẽ là: audienceId</li>
                                    <li>Banner sẽ là: cover</li>
                                </ul>
                            </Col>
                            <Col sm={12} className='text-center'>
                                <Upload
                                    action={`${ApiHelper.getApiUrl()}post/upload`}
                                    name='file'
                                    maxCount={1}
                                    withCredentials={true}
                                    onChange={(info) => {
                                        if (info.file.status === 'done') {
                                            if (info?.file?.response?.error == 0) {
                                                setSaved(false)
                                                setData(info?.file?.response?.data?.data)
                                            } else {
                                                error(`${info.file.name} file upload failed.`)
                                            }
                                        } else if (info.file.status === 'error') {
                                            error(`${info.file.name} file upload failed.`)
                                        }
                                    }}>
                                    <Button onClick={() => {
                                    }} icon={<UploadOutlined />}>Chọn File</Button>
                                </Upload>
                            </Col>
                            <Col pt={20} sm={12}>
                                <Label>Nội dung File</Label>
                                <Card>
                                    {data &&
                                        <React.Fragment>
                                            <JsonView data={data} shouldInitiallyExpand={allExpanded} style={darkStyles} />
                                        </React.Fragment>
                                    }
                                </Card>

                            </Col>
                        </Row>

                    </Form>
                </Card>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => { props.toggle(false) }}
                    type="button"
                    color="secondary"
                >
                    Close
                </Button>
                {!saved &&
                    <Popconfirm
                        title="Bạn có chắc?"
                        onConfirm={() => {
                            setSaved(true)
                            props.updataTemplate(data)
                        }}
                    >
                        <Button size="small" color="primary">Lưu File</Button>
                    </Popconfirm>
                }
                {saved &&
                    <Popconfirm
                        title="Bạn có chắc?"
                        onConfirm={() => {
                            execution()
                        }}
                    >
                        <Button
                            type="button"
                            color="success"
                            onClick={() => { }}
                        >
                            Execution
                        </Button>
                    </Popconfirm>
                }
            </ModalFooter>
        </Modal>
    )
}

export default GeneratorTemplateModal;