import React from "react"
import { Card, CardBody, Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";
import GiftTable from "./table/GiftTable";

const Gifts = props => {

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={"Quà tặng"}
                        breadcrumbItem={"Danh sách quà tặng"}
                    />
                    <Card>
                        <CardBody>
                            <GiftTable />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Gifts
