// TableMember

import { Image, Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"
import { Button, Col } from "reactstrap"
import { Row } from "reactstrap"
import queryString from 'query-string'
import GiftModel from "api/GiftModel"


const GiftTable = props => {
    let history = useHistory();
    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Ảnh",
            dataIndex: "link",
            render: link => <Image src={link} width={70} />
        },
        {
            title: "Quà tặng",
            dataIndex: "name",
            render: name => name
        },
        {
            title: "Quota",
            dataIndex: "quota",
            render: quota => quota || 'Unlimited'
        },
        {
            title: "Đối tượng",
            render: gift => {
                const { promotion, audienceId } = gift;

                return (
                    <>
                        {promotion && <span>Chương trình: {promotion.title}</span>}
                        {audienceId && audienceId > 0 && <span>AudienceId: {audienceId}</span>}
                    </>
                )
            }
        },
    ]

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [filter, setFilter] = useState({
        ...props.filter,
        ...queryString.parseUrl(history.location.search).query
    })

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.replace({ search: url })
    };

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await GiftModel.find(
            newPagination.current,
            newPagination.pageSize
        )
        setData(results?.data)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData({ current: 1, pageSize: pagination.pageSize })
        serialize2Url()
    }, [filter])


    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="text-sm-end mb-3">
                        <div className="btn-group" role="group">
                            <Button
                                color="primary"
                                className="btn btn-primary "
                                style={{ marginLeft: "10px", width: "180px" }}
                                onClick={() => history.push('/gifts/create')}
                            >
                                Thêm quà tặng
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    )
}

export default GiftTable
