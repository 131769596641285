import React, { useEffect, useState } from "react"
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import { Select, DatePicker } from "antd"
import { Button, Popconfirm } from 'antd';
import moment from "moment"
import { success } from "helpers/notification"
import { error } from "helpers/notification"
import { useHistory } from "react-router-dom"
import PromotionType from "constants/PromotionType"
import PromotionModel from "api/PromotionModel"
import UploadImage from "components/UploadImage"
import * as CementUtil from "helpers/CementUtil"
import AudienceInput from "pages/audience/form/audience-input"
import PromotionStatus from "constants/PromotionStatus"
import * as QRUtil from '../../common/qr'

const { RangePicker } = DatePicker
const dateFormat = "DD/MM/YYYY"
const CreatePromotion = props => {
    const promotionId = props?.match?.params?.id;
    const [start_time, set_start_time] = useState(moment().subtract(60, "days"))
    const [end_time, set_end_time] = useState(moment())
    const [validation, setValidation] = useState([])
    const [isSubmit, setIsSubmit] = useState(false)

    const [field, setField] = useState({
        promotionId: promotionId,
        timeStart: start_time.toDate().getTime(),
        timeEnd: end_time.toDate().getTime(),
        roleUsers: [2, 3],
    })


    const getPromotion = async id => {
        let result = await PromotionModel.get(id)
        setField({
            ...result.data,
            audienceId: result.data?.audience?.id,
        })
        set_start_time(moment(result.data.timeStart))
        set_end_time(moment(result.data.timeEnd))
    }

    const history = useHistory()

    const changeCalendar = data => {
        set_start_time(data[0])
        set_end_time(data[1])
        let start = moment(data[0]).toDate().getTime()
        let end = moment(data[1]).toDate().getTime()
        setField({ ...field, timeStart: start, timeEnd: end })
    }

    const save = async e => {
        e.preventDefault()
        setIsSubmit(true)
        let isError = validate()
        if (isError) {
            error("Vui lòng nhập đầy đủ!")
            return
        }
        if (!promotionId) {
            let result = await PromotionModel.create({ ...field, status: PromotionStatus.INIT })
            if (result.error != 0) {
                error(result.msg)
                return
            }
            success("Tạo chương trình thành công!")
            history.push(`/promotions/campaigns`)
        } else {
            let result = await PromotionModel.update(promotionId, { ...field })
            if (result.error != 0) {
                error(result.msg)
                return
            }
            success("Lưu chương trình thành công!")
        }

    }

    const validate = () => {
        let isError = false
        let tmp = {}
        if (!field?.title) {
            isError = true
            tmp = { ...tmp, title: "Thiếu tiêu đề" }
        }
        if (!field?.type) {
            isError = true
            tmp = { ...tmp, type: "Chọn loại đơn" }
        }

        if (!field?.audienceId) {
            isError = true;
            tmp = { ...tmp, audienceId: "Vui lòng chọn đối tượng" }
        }
        setValidation(tmp)
        return isError
    }


    const change_images = data => {
        setField({ ...field, image: data[0]?.url })
    }


    useEffect(() => {
        if (promotionId) {
            getPromotion(promotionId)
        }
    }, [promotionId])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={"Chương trình"}
                        breadcrumbItem="Tạo chương trình mới"
                    />
                    <Card>
                        <CardBody>
                            {props.match.params.id && field?.type == PromotionType.HOT_DEAL &&
                                <Row style={{ justifyContent: 'center' }}>
                                    <img style={{ width: '250px' }} src={QRUtil.genQRV2(`https://zalo.me/s/2604973040500424243/form/${props.match.params.id}`)} />
                                </Row>
                            }
                            <Form>
                                <div className="mb-3">
                                    <Label htmlFor="title">
                                        Tiêu đề{" "}
                                        {
                                            <span style={{ color: "red" }}>
                                                {validation["title"]}
                                            </span>
                                        }
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        placeholder="Nhập tiêu đề chương trình"
                                        onChange={e => {
                                            setField({ ...field, title: e.target.value })
                                        }}
                                        invalid={isSubmit && validation["title"] != null}
                                        value={field.title}
                                    />
                                </div>
                                <Row>
                                    <Col sm={4}>
                                        <Label htmlFor="duration" style={{ width: "100%" }}>
                                            Đối tượng{" "}
                                            {
                                                <span style={{ color: "red" }}>
                                                    {validation["audienceId"]}
                                                </span>
                                            }
                                        </Label>
                                        <AudienceInput value={field?.audienceId} onChange={(value) => {
                                            setField({ ...field, audienceId: value })
                                        }} />
                                    </Col>
                                    <Col sm={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="type">
                                                Loại?{" "}
                                                {
                                                    <span style={{ color: "red" }}>
                                                        {validation["type"]}
                                                    </span>
                                                }
                                            </Label>
                                            <select
                                                className="form-control"
                                                value={field?.type}
                                                onChange={e => {
                                                    let config = field.config || {}
                                                    config = { ...config, type: Number(e.target.value) }
                                                    setField({ ...field, type: Number(e.target.value), config: config })
                                                }}
                                            >
                                                <option value={0}>Chọn...</option>
                                                <option value={PromotionType.LOYALTY_PROMOTION}>
                                                    {PromotionType.findName(
                                                        PromotionType.LOYALTY_PROMOTION
                                                    )}
                                                </option>
                                                <option value={PromotionType.PREDICT_FOOTBALL}>
                                                    {PromotionType.findName(
                                                        PromotionType.PREDICT_FOOTBALL
                                                    )}
                                                </option>
                                                <option value={PromotionType.NORMAL_INVOICE}>
                                                    {PromotionType.findName(
                                                        PromotionType.NORMAL_INVOICE
                                                    )}
                                                </option>
                                                <option value={PromotionType.HOT_DEAL}>
                                                    {PromotionType.findName(
                                                        PromotionType.HOT_DEAL
                                                    )}
                                                </option>
                                                <option value={PromotionType.TOTAL_FORCE_PROMOTION}>
                                                    {PromotionType.findName(
                                                        PromotionType.TOTAL_FORCE_PROMOTION
                                                    )}
                                                </option>
                                                <option value={PromotionType.AS_FAST_AS_LIGHTING_MINI_GAME}>
                                                    {PromotionType.findName(
                                                        PromotionType.AS_FAST_AS_LIGHTING_MINI_GAME
                                                    )}
                                                </option>
                                                <option value={PromotionType.LIVE_STREAM_BROADCAST_PROMOTION}>
                                                    {PromotionType.findName(
                                                        PromotionType.LIVE_STREAM_BROADCAST_PROMOTION
                                                    )}
                                                </option>
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        <Label htmlFor="duration" style={{ width: "100%" }}>
                                            Khoảng thời gian
                                        </Label>
                                        <RangePicker
                                            className={"custom-datepicket-ant"}
                                            value={[
                                                moment(start_time, dateFormat),
                                                moment(end_time, dateFormat),
                                            ]}
                                            onChange={changeCalendar}
                                            format={dateFormat}
                                        />
                                    </Col>
                                </Row>
                                {field?.type == PromotionType.HOT_DEAL && (
                                    <Row>
                                        <Col md={4}>
                                            <div className="mb-3 templating-select select2-container">
                                                <label className="control-label">Áp dụng cho những xi măng ?</label>
                                                <Select
                                                    mode="multiple"
                                                    onChange={values => {
                                                        setField({ ...field, cementIds: values })
                                                    }}
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    value={field.cementIds}
                                                >
                                                    {CementUtil.getList().map(item => (
                                                        <Select.Option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col sm={4}>
                                            <div className="mb-3">
                                                <Label htmlFor="type">
                                                    Quà tặng?
                                                </Label>
                                                <select
                                                    id="type"
                                                    className="form-control"
                                                    value={field?.useLuckyDraw}
                                                    onChange={e =>
                                                        setField({ ...field, useLuckyDraw: e.target.value })
                                                    }
                                                >
                                                    <option value={0}>Chọn...</option>
                                                    <option value={true}>
                                                        Lucky draw
                                                    </option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                )}

                                {field?.type == PromotionType.NORMAL_INVOICE && (
                                    <Row>
                                        <Col md={4}>
                                            <div className="mb-3 templating-select select2-container">
                                                <label className="control-label">Áp dụng cho những xi măng ?</label>
                                                <Select
                                                    mode="multiple"
                                                    onChange={values => {
                                                        setField({ ...field, cementIds: values })
                                                    }}
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    value={field.cementIds}
                                                >
                                                    {CementUtil.getList().map(item => (
                                                        <Select.Option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <Label htmlFor="title">
                                                Quota{" "}
                                                {
                                                    <span style={{ color: "red" }}>
                                                        {validation["Quota"]}
                                                    </span>
                                                }
                                            </Label>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="quota"
                                                placeholder="Nhập quota"
                                                onChange={e => {
                                                    setField({ ...field, quota: Number(e.target.value) })
                                                }}
                                                invalid={isSubmit && validation["quota"] != null}
                                                value={field.quota}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Label htmlFor="title">
                                                Số lượng tối thiểu (bao){" "}
                                                {
                                                    <span style={{ color: "red" }}>
                                                        {validation["Quota"]}
                                                    </span>
                                                }
                                            </Label>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="minBags"
                                                placeholder="Nhập quota"
                                                onChange={e => {
                                                    setField({ ...field, minBags: Number(e.target.value) })
                                                }}
                                                invalid={isSubmit && validation["minBags"] != null}
                                                value={field.minBags}
                                            />
                                        </Col>
                                    </Row>
                                )}

                                {field?.type == PromotionType.AS_FAST_AS_LIGHTING_MINI_GAME && (
                                    <Row>
                                        <Col md={4}>
                                            <div className="mb-3 templating-select select2-container">
                                                <Label htmlFor="title">
                                                    Payload{" "}
                                                    {
                                                        <span style={{ color: "red" }}>
                                                            {validation["config.payload"]}
                                                        </span>
                                                    }
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Payload"
                                                    onChange={e => {
                                                        let config = field.config || {}
                                                        config = { ...config, payload: e.target.value }
                                                        setField({ ...field, config })
                                                    }}
                                                    invalid={isSubmit && validation["config.payload"] != null}
                                                    value={field?.config?.payload}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={4}>
                                            <div className="mb-3">
                                                <Label htmlFor="type">
                                                    Thời gian bắt đầu
                                                </Label>
                                                <DatePicker
                                                    value={field?.config?.timeStart && moment(field?.config?.timeStart * 1000)}
                                                    className={"custom-datepicket-ant"}
                                                    showTime
                                                    onChange={(e) => {
                                                        let config = field.config || {}
                                                        config = { ...config, timeStart: e.unix() }
                                                        setField({ ...field, config })
                                                    }} />
                                            </div>
                                        </Col>
                                        <Col sm={4}>
                                            <div className="mb-3">
                                                <Label htmlFor="type">
                                                    Thời gian kết thúc
                                                </Label>
                                                <DatePicker
                                                    className={"custom-datepicket-ant"}
                                                    value={field?.config?.timeEnd && moment(field?.config?.timeEnd * 1000)}
                                                    showTime
                                                    onChange={(e) => {
                                                        let config = field.config || {}
                                                        config = { ...config, timeEnd: e.unix() }
                                                        setField({ ...field, config })
                                                    }} />
                                            </div>
                                        </Col>
                                    </Row>
                                )}

                                {field?.type == PromotionType.LIVE_STREAM_BROADCAST_PROMOTION && (
                                    <>
                                        <Row>
                                            <Col md={4}>
                                                <div className="mb-3 templating-select select2-container">
                                                    <Label htmlFor="title">
                                                        Payload{" "}
                                                        {
                                                            <span style={{ color: "red" }}>
                                                                {validation["config.payload"]}
                                                            </span>
                                                        }
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Payload"
                                                        onChange={e => {
                                                            let config = field.config || {}
                                                            config = { ...config, payload: e.target.value }
                                                            setField({ ...field, config })
                                                        }}
                                                        value={field?.config?.payload}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="type">
                                                        Link Live Stream
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Link"
                                                        onChange={e => {
                                                            let config = field.config || {}
                                                            config = { ...config, link: e.target.value }
                                                            setField({ ...field, config })
                                                        }}
                                                        value={field?.config?.link}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <div className="mb-3 templating-select select2-container">
                                                    <Label htmlFor="title">
                                                        Link Bài viết{" "}
                                                        {
                                                            <span style={{ color: "red" }}>
                                                                {validation["config.linkPost"]}
                                                            </span>
                                                        }
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Link bài viết"
                                                        onChange={e => {
                                                            let config = field.config || {}
                                                            config = { ...config, linkPost: e.target.value }
                                                            setField({ ...field, config })
                                                        }}
                                                        value={field?.config?.linkPost}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <Label htmlFor="promotion">Live</Label>
                                                <div
                                                    className="form-check form-switch form-switch-lg mb-3"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={field?.config?.lived}
                                                        onChange={(e) => {
                                                            let config = field.config || {}
                                                            config = { ...config, lived: e.target.checked }
                                                            setField({ ...field, config })
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                <div className="mb-3">
                                    <Label htmlFor="summary">
                                        Tóm tắt{" "}
                                        {
                                            <span style={{ color: "red" }}>
                                                {validation["summary"]}
                                            </span>
                                        }{" "}
                                    </Label>
                                    <textarea
                                        name="textarea"
                                        label="Textarea"
                                        rows="4"
                                        className={`form-control ${validation["summary"] ? "is-invalid" : ""
                                            }`}
                                        spellCheck="false"
                                        onChange={e =>
                                            setField({ ...field, description: e.target.value })
                                        }
                                        value={field?.description}
                                    />
                                </div>

                                {field?.type == PromotionType.AS_FAST_AS_LIGHTING_MINI_GAME && (
                                    <div className="mb-3">
                                        <Label htmlFor="summary">
                                            Message Reminder{" "}
                                            {
                                                <span style={{ color: "red" }}>
                                                    {validation["config.messageReminder"]}
                                                </span>
                                            }{" "}
                                        </Label>
                                        <textarea
                                            name="textarea"
                                            label="Textarea"
                                            rows="4"
                                            className={`form-control`}
                                            spellCheck="false"
                                            onChange={e => {
                                                let config = field.config || {}
                                                config = { ...config, messageReminder: e.target.value }
                                                setField({ ...field, config })
                                            }}
                                            value={field?.config?.messageReminder}
                                        />
                                    </div>
                                )}
                                <div className="mb-3 justify-content-end">
                                    <Label htmlFor="duration">
                                        Ảnh đại diện{" "}
                                        {
                                            <span style={{ color: "red" }}>
                                                {validation["cover"]}
                                            </span>
                                        }
                                    </Label>
                                    <Row className="mb-3">
                                        <Col sm={12}>
                                            <UploadImage exits_images={field?.image ? [{ url: field.image }] : undefined} change_images={change_images} size={1} />
                                        </Col>
                                    </Row>
                                </div>

                                <div style={{ float: 'right' }} className="mb-3 justify-content-end">
                                    <Popconfirm
                                        title="Are you sure?"
                                        onConfirm={save}
                                        icon={<QuestionCircleOutlined />}
                                    >
                                        <Button style={{ width: '100px' }} type="primary" size="large" > Lưu</Button>
                                    </Popconfirm>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreatePromotion
