import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";

// TableContainer

//Import Images
import profile1 from "assets/images/profile-img.png";
import { Avatar } from "antd";

const ContactCard = props => {
  const { user } = props;
  return (
    <Card className="overflow-hidden">
      <div className="bg-primary bg-soft">
        <Row>
          <Col xs="5" className="align-self-end">
            <img src={profile1} alt="" className="img-fluid" />
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0">
        <Row>
          <Col className="text-center" sm="12">
            <div style={{ marginTop: -30, marginBottom: 20 }}>
              <Avatar size={60} src={user.avatar} />
            </div>
            <h5 className="font-size-15 text-truncate">
              Nguyễn Đình Trung
            </h5>
            <p className="text-muted mb-0 text-truncate">
              Nhà Thầu
            </p>
          </Col>
          <div>
            <Link to={`/members/profile/${user.id}`} className="btn btn-primary  btn-sm">
              <i className="mdi mdi-account" />
            </Link>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};


export default ContactCard;