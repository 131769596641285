class PromotionType {
    static LOYALTY_PROMOTION = 40;
    static PREDICT_FOOTBALL = 51;
    static NORMAL_INVOICE = 60;
    static HOT_DEAL = 70;
    static QUICK_QUESTION_PROMOTION = 80;
    static TOTAL_FORCE_PROMOTION = 100;
    static AS_FAST_AS_LIGHTING_MINI_GAME = 110;
    static LIVE_STREAM_BROADCAST_PROMOTION = 120;

    static findName(value) {
        switch (value) {
            case this.LOYALTY_PROMOTION: return 'Loyalty'
            case this.PREDICT_FOOTBALL: return 'Dự đoán bóng đá'
            case this.NORMAL_INVOICE: return 'Hóa đơn'
            case this.HOT_DEAL : return 'Hot deal'
            case this.TOTAL_FORCE_PROMOTION: return  'Total Force'
            case this.AS_FAST_AS_LIGHTING_MINI_GAME: return 'Nhanh như chớp'
            case this.LIVE_STREAM_BROADCAST_PROMOTION: return 'Live Stream'
        }
    }

    static findColor(value) {
        switch (value) {
            // case this.INIT: return 'orange'
        }
    }
}

export default PromotionType;