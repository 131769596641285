import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthModel from "../../api/AuthModel"

export const login = createAsyncThunk(
    "user/login",
    async (payload, { rejectWithValue }) => {
        // Gọi lên API backend
        // const response = await AuthModel.checkLogin()
        // if (response.error != 0) {
        //     return rejectWithValue(response)
        // }
        // if (!response.data.isLogin) {
        //     window.location.href = response.data.url_login
        // }
        // return response
    }
)

const initialState = {
    user: null,
    isLoading: false,
    error: null,
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},

    extraReducers: builder => {
        // Bắt đầu thực hiện action login (Promise pending)
        builder.addCase(login.pending, state => {
            // Bật trạng thái loading
            state.isLoading = true
        })

        // Khi thực hiện action login thành công (Promise fulfilled)
        builder.addCase(login.fulfilled, (state, action) => {
            // Tắt trạng thái loading, lưu thông tin user vào store
            state.isLoading = false
            // state.user = action.payload.data.user
            state.user = {
                uid: 280671989,
                logged: true,
                name: "Tran Tan Loc",
                admin: false,
                avatar: "https://avatar.talk.zdn.vn/7/f/7/b/0/75/e30dda4d58d40a2de5d4f878553a659a.jpg"
            }
        })

        // Khi thực hiện action login thất bại (Promise rejected)
        builder.addCase(login.rejected, (state, action) => {
            // Tắt trạng thái loading, lưu thông báo lỗi vào store
            state.isLoading = false
            state.error = action.payload
        })
    },
})

// Action creators are generated for each case reducer function
export const { } = authSlice.actions

export default authSlice.reducer
