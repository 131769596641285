import * as ApiHelper from './api_helper'

class ConstructorRetailerModel {

    async getById(id) {
        return ApiHelper.get(`constructor-retailer/get?id=${id}`)
    }

    async findVerifedRetailer({search, cityId, distictId, page, pageSize}) {
        let params = Object.fromEntries(
            Object.entries({
                search,
                cityId,
                distictId,
                page: page && page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0 && v != undefined)
        );
        return ApiHelper.get(`constructor-retailer/find-verified-retailer?${new URLSearchParams(params).toString()}`)
    }


    async findNotVerifedRetailer(search, page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                search,
                page: page && page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`constructor-retailer/find-not-verified-retailer?${new URLSearchParams(params).toString()}`)
    }

    async find(retailerId, page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                retailerId,
                page: page && page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`constructor-retailer/find?${new URLSearchParams(params).toString()}`)
    }

    async matching(constructorRetailerId, retailerId) {
        let params = Object.fromEntries(
            Object.entries({
                constructorRetailerId,
                retailerId,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`constructor-retailer/matching-retailer?${new URLSearchParams(params).toString()}`)
    }

}

export default new ConstructorRetailerModel();
