import * as ApiHelper from './api_helper'

class GiftModel {
    async find(page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                page: page - 1, pageSize
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`gift/find?${new URLSearchParams(params).toString()}`)
    }
    async create(form) {
        let data = Object.fromEntries(Object.entries({ ...form }).filter(([_, v]) => v != null && v != 0));
        return ApiHelper.post2(`gift/create`, data);
    }
}

export default new GiftModel();
