import React from "react"
import { Card, CardBody, Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableFormSurvey from "./table/TableFormSurvey";

const ListFormSurvey = props => {
    const broadcastId = props.match.params.broadcastId;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid><Breadcrumbs
                    title={"Khảo sát"}
                    breadcrumbItem="Danh sách"
                />
                    <Card>
                        <CardBody>
                            <TableFormSurvey  broadcastId={broadcastId}/>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ListFormSurvey
