// TableMember

import { Button, Dropdown, Menu, Table, Tag } from "antd"
import { BarsOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react"
import { PoweroffOutlined, FormOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import * as PostStatus from "constants/PostStatus"
import TimeUtils from "helpers/time.utils"
import { City, District } from "helpers/ProvinceUtil"
import { Link, useHistory } from "react-router-dom"
import {
    Row, Col,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap"
import { success } from "helpers/notification"
import { error } from "helpers/notification"
import { PopupConfirm } from "components/pop-confirm"
import * as UserType from "constants/UserType"
import queryString from "query-string"
import PromotionModel from "api/PromotionModel"
import PromotionStatus from "constants/PromotionStatus"
import PromotionType from "constants/PromotionType"
import classnames from "classnames";
import BroadcastType from "constants/BroadcastType";

const Tab = {
    1: {
        id: 1,
        name: "Tất cả",
        types: 0
    },
    2: {
        id: 2,
        name: "Loyalty",
        types: [PromotionType.LOYALTY_PROMOTION]
    },
    3: {
        id: 3,
        name: "Engagement",
        types: [PromotionType.LIVE_STREAM_BROADCAST_PROMOTION,
        PromotionType.PREDICT_FOOTBALL,
        PromotionType.LIVE_STREAM_BROADCAST_PROMOTION,
        PromotionType.QUICK_QUESTION_PROMOTION,
        PromotionType.AS_FAST_AS_LIGHTING_MINI_GAME
        ]
    },
    4: {
        id: 4,
        name: "Hot Deal",
        types: [PromotionType.HOT_DEAL]
    }
}

const TableCampaigns = props => {

    let history = useHistory()
    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Tiêu đề",
            dataIndex: "title",
        },
        {
            title: "Đối tượng",
            render: (item) => {
                if (item.audience) {
                    return <Tag color={'#40a9ff'}>{item.audience.alias || item.audience.id}</Tag>
                }
                const cityIds = item.cityIds;
                const districtIds = item.districtIds;
                const roleUsers = item.roleUsers;
                return <ul className="basic-ul">
                    <li>{roleUsers && roleUsers.map(item => UserType.findName(item)).join(", ")}</li>
                    <li>Tỉnh/Thành Phố: {cityIds == null ? 'Tất cả' : cityIds?.map(cityId => City.getName(cityId)).join(", ")}</li>
                    <li>Quận/huyện: {districtIds == null ? 'Tất cả' : districtIds?.map(districtId => District.getName(districtId)).join(", ")}</li>
                </ul>
            }
        },
        {
            title: "Bắt đầu",
            dataIndex: "timeStart",
            render: timeStart => TimeUtils.toDDMMYYYY(timeStart),
        },
        {
            title: "Kết thúc",
            dataIndex: "timeEnd",
            render: timeEnd => TimeUtils.toDDMMYYYY(timeEnd),
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: status => (
                <Tag color={PromotionStatus.findColor(status)} key={status}>
                    {PromotionStatus.findName(status)}
                </Tag>
            ),
        },
        {
            title: "Hành động",
            render: (_, record) => (
                <>
                    <Dropdown
                        overlay={
                            <Menu
                                items={[
                                    {
                                        key: "1",
                                        label: (
                                            <div className="col-12 mb-1" style={{ display: "inline" }}>
                                                <Button
                                                    size="small"
                                                    style={{ width: "100%", display: record?.status === PromotionStatus.INIT ? "inline" : 'none' }}
                                                    onClick={() => btn_approved(record)}
                                                >
                                                    Duyệt
                                                </Button>
                                            </div>
                                        ),
                                    },
                                    {
                                        key: "2",
                                        label: (
                                            <Button type="link" className="col-12 mb-1">
                                                <Link to={`/promotions/edit/${record.id}`} style={{ width: "100%" }}>Chỉnh sửa</Link>
                                            </Button>
                                        ),
                                    },
                                    {
                                        key: "3",
                                        label: (
                                            <Button type="link" className="col-12 mb-1"
                                                style={{
                                                    display: (record?.type === PromotionType.AS_FAST_AS_LIGHTING_MINI_GAME
                                                        || record?.type === PromotionType.LIVE_STREAM_BROADCAST_PROMOTION) ? "inline" : "none"
                                                }}>
                                                <Link
                                                    to={`/broadcasts/create?type=${BroadcastType.findIdByTypePromotion(record?.type)}&promotionId=${record.id}&audienceId=${record?.audience?.id}`} style={{ width: "100%" }}
                                                >
                                                    Broadcast
                                                </Link>
                                            </Button>
                                        ),
                                    }
                                ]
                                }
                            />
                        }
                        placement="bottom"
                        arrow
                    >
                        <Button type="text"><BarsOutlined /></Button>
                    </Dropdown >
                    <Button
                        type="primary"
                        icon={<FormOutlined />}
                        onClick={() => history.push(`/program/${record.id}`)}
                    />
                </>
            ),
        },

    ]

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })

    const [filter, setFilter] = useState({
        tab: 1,
        timeActive: true,
        ...props.filter,
        ...queryString.parseUrl(history.location.search).query,
    })

    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [isApprovedOpen, setIsApprovedOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        )
        let url = queryString.stringify(params)
        history.replace({ search: url })
    }

    const btn_approved = row => {
        setIsApprovedOpen(true)
        setSelectedRow(row)
    }

    const updateStatus = async (id, status) => {
        setIsApprovedOpen(false)
        setIsDeleteOpen(false)
        let result = await PromotionModel.updateStatus(id, status)
        if (result.error != 0) {
            let msg =
                status === PromotionStatus.APPROVED
                    ? "Duyệt chương trình thất bại"
                    : "Xóa chương trình thất bại"
            error(msg)
            return
        }
        let msg =
            status === PromotionStatus.APPROVED
                ? "Duyệt chương trình thành công"
                : "Xóa chương trình thất bại"
        success(msg)
        fetchData(pagination)
    }

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await PromotionModel.getPromotions(
            Tab[filter.tab].types,
            filter?.start,
            filter?.end,
            filter?.timeActive,
            newPagination.current,
            newPagination.pageSize
        )

        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData({
            current: 1,
            pageSize: 10,
        })
        serialize2Url()
    }, [filter])

    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="text-sm-end mb-3">
                        <Row>
                            <Col sm={8}>
                                <Nav pills>
                                    {Object.values(Tab).map((tab, index) => {
                                        return (
                                            <NavItem key={index}>
                                                <NavLink
                                                    className={classnames({
                                                        active: filter?.tab == tab.id
                                                    })}
                                                    onClick={() => {
                                                        setFilter({ ...filter, tab: tab.id });
                                                    }}
                                                >
                                                    <i className="bx bx-chat font-size-20 d-sm-none" />
                                                    <span className="d-none d-sm-block">{tab.name}</span>
                                                </NavLink>
                                            </NavItem>
                                        )
                                    })}
                                </Nav>
                            </Col>
                            <Col sm={4}>
                                <div style={{ alignItems: 'center' }} className="btn-group" role="group">
                                    <Checkbox checked={filter?.dev} onChange={(e) => {
                                        setFilter({ ...filter, dev: e.target.checked })
                                    }}>Testing</Checkbox>
                                    <Checkbox checked={filter?.timeActive} onChange={(e) => {
                                        setFilter({ ...filter, timeActive: e.target.checked })
                                    }}>Actived</Checkbox>
                                    <Button type="primary" style={{ marginLeft: "10px", height: '38px' }} size={38}>
                                        <Link to={`/promotions/create`}>Tạo chương trình</Link>
                                    </Button>
                                </div>
                            </Col>
                        </Row>


                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            {isDeleteOpen && (
                <PopupConfirm
                    isOpen={isDeleteOpen}
                    toggle={() => setIsDeleteOpen(false)}
                    handleSubmit={() => updateStatus(selectedRow.id, PostStatus.REMOVED)}
                    title="Bạn có muốn xóa bài viết này?"
                    content={{ id: selectedRow.id, title: selectedRow.title }}
                />
            )}
            {isApprovedOpen && (
                <PopupConfirm
                    isOpen={isApprovedOpen}
                    toggle={() => setIsApprovedOpen(false)}
                    handleSubmit={() =>
                        updateStatus(selectedRow.id, PromotionStatus.APPROVED)
                    }
                    title="Bạn có muốn duyêt bài viết này?"
                    content={{ id: selectedRow.id, title: selectedRow.title }}
                />
            )}
        </>
    )
}

export default TableCampaigns
