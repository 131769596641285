// TableMember

import React from "react";
import { useParams } from "react-router-dom";
import AsFastAsLightingTableForm from "pages/forms/tablev2/AsFastAsLightingTableForm";

const AsFastAsLightingProgram = props => {
    const { promotionId } = useParams()

    return (
        <>
            <AsFastAsLightingTableForm promotionId={promotionId} />
        </>
    )
}

export default AsFastAsLightingProgram
