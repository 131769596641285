// TableMember

import { Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import * as CementUtils from "helpers/CementUtil"
import { useHistory } from "react-router-dom"
import ConstructorRetailerModel from "api/ConstructorRetailerModel"
import { City } from "helpers/ProvinceUtil"
import { Button } from "reactstrap"


const TableOfConstructor = props => {
    const { retailerId } = props
    let history = useHistory();
    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Khách hàng",
            dataIndex: "constructor",
            render: constructor => constructor.name
        },
        {
            title: "SDT",
            dataIndex: "constructor",
            render: constructor => constructor.phone
        },
        {
            title: "Tỉnh thành",
            dataIndex: "constructor",
            render: constructor => City.getName(constructor.cityId) || ''
        },
        {
            title: CementUtils.POWERS.name,
            dataIndex: "cementAccumulation",
            render: cementAccumulation => {
                return <>{cementAccumulation && cementAccumulation[CementUtils.POWERS.id] || 0}</>
            }
        },
        {
            title: CementUtils.WALL_PRO.name,
            dataIndex: "cementAccumulation",
            render: cementAccumulation => {
                return <>{cementAccumulation && cementAccumulation[CementUtils.WALL_PRO.id] || 0}</>
            }
        },
        {
            title: CementUtils.LAVILLA_EXTRA_CC40.name,
            dataIndex: "cementAccumulation",
            render: cementAccumulation => {
                return <>{cementAccumulation && cementAccumulation[CementUtils.LAVILLA_EXTRA_CC40.id] || 0}</>
            }
        },
        {
            title: CementUtils.EXTRA_DURABLE_HOUSING.name,
            dataIndex: "cementAccumulation",
            render: cementAccumulation => {
                return <>{cementAccumulation && cementAccumulation[CementUtils.EXTRA_DURABLE_HOUSING.id] || 0}</>
            }
        },
        {
            title: "Actions",
            dataIndex: "id",
            render: id => (
                <Button color="primary"
                    className="btn btn-primary"
                    onClick={() => history.push(`/loyalty-invoice/forms/?constructorRetailerId=${id}`)}>
                    Danh sách đơn
                </Button>
            )
        }
    ]
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [filter, setFilter] = useState(props.filter)

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await ConstructorRetailerModel.find(
            retailerId,
            newPagination.current,
            newPagination.pageSize
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData({ current: 1, pageSize: pagination.pageSize })
    }, [filter])


    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    return (
        <>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    )
}

export default TableOfConstructor
