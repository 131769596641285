import React, { useEffect, useMemo, useState } from "react"
import { Card, CardBody, Container, CardTitle } from "reactstrap"
import queryString from 'query-string'
import { useHistory } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import LoyaltyInvoiceTableForm from "./tablev2/LoyaltyInvoiceTableForm"
import UserConstructionModel from "api/UserConstructionModel"
import ConstructorRetailerModel from "api/ConstructorRetailerModel"
import { City } from "helpers/ProvinceUtil"

const LoyaltyInvoiceFormsPage = props => {
    const history = useHistory();
    const [userConstruction, setUserConstruction] = useState();
    const [constructorRetailer, setConstructorRetailer] = useState();
    const { userConstructionId, constructorRetailerId } = queryString.parseUrl(history.location.search).query;

    function fetchUserConstruction() {
        if (userConstructionId) {
            UserConstructionModel.getById(userConstructionId)
                .then(resp => {
                    if (resp.error == 0) {
                        setUserConstruction(resp.data);
                    }
                })
        }
    }

    function fetchConstructorRetailer() {
        if (constructorRetailerId) {
            ConstructorRetailerModel.getById(constructorRetailerId)
                .then(resp => {
                    if (resp.error == 0) {
                        setConstructorRetailer(resp.data);
                    }
                })
        }
    }

    useEffect(() => {
        fetchConstructorRetailer()
    }, [constructorRetailerId])

    useEffect(() => {
        fetchUserConstruction()
    }, [userConstructionId])


    const user = useMemo(() => {
        if (userConstruction) {
            return userConstruction.user;
        }
        if (constructorRetailer) {
            return constructorRetailer.constructor;
        }
        return ''
    }, [userConstruction, constructorRetailer])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Hóa đơn"} />
                    <Card>
                        {(userConstruction || constructorRetailer) &&
                            <div style={{ paddingTop: '20px', paddingLeft: '20px' }}>
                                <table>
                                    {user &&
                                        <tr>
                                            <td width={90}>Nhà thầu</td>
                                            <th>{user?.name}</th>
                                        </tr>
                                    }
                                    {userConstruction &&
                                        <tr>
                                            <td width={90}>Công trình</td>
                                            <th>{`${userConstruction?.construction?.address} - ${City.getName(userConstruction?.construction?.cityId)}`}</th>
                                        </tr>
                                    }
                                    {constructorRetailer &&
                                        <tr>
                                            <td width={90}>Cửa hàng</td>
                                            {constructorRetailer?.retailer ?
                                                <th>{`${constructorRetailer?.retailer?.name} - ${City.getName(constructorRetailer?.retailer?.cityId)}`}</th> :
                                                <th>{constructorRetailer.draft}</th>
                                            }
                                        </tr>
                                    }
                                </table>
                            </div>
                        }
                        <CardBody>
                            <LoyaltyInvoiceTableForm />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default LoyaltyInvoiceFormsPage

