import * as ApiHelper from './api_helper'

class TopicSurveyModel {
    async find(page = 1, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                page: page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0 && v != undefined)
        );
        return ApiHelper.get(`topic-survey/find?${new URLSearchParams(params).toString()}`)
    }

    async create(form) {
        return ApiHelper.post2(`topic-survey/create`, form);
    }

}

export default new TopicSurveyModel();
