class ConstructionStatus {
    static INIT = 1;
    static CLOSE = 2;

    static findName(value) {
        switch (value) {
            case this.INIT: return 'Đang mở'
            case this.CLOSE: return 'Đã đóng'
        }
    }

    static findColor(value) {
        switch (value) {
            case this.INIT:
                return '#4bcffa'; // Đỏ đậm
            case this.CLOSE:
                return '#808e9b'; // Xanh lá cây đậm
        }
    }

}

export default ConstructionStatus;