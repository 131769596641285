import * as ApiHelper from './api_helper'

class ArticleModel {
    async getArticles(title, status, timeActive, template, page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                title,
                status,
                timeActive,
                template,
                page: page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`post/list?${new URLSearchParams(params).toString()}`)
    }

    async getArticlesByTemplate(templateId) {
        return ApiHelper.get(`post/find-by-template?templateId=${templateId}`);
    }

    async createOrUpdate(form) {
        if (form.dataTemplate) {
            form.dataTemplate = JSON.stringify(form.dataTemplate)
        }
        let data = Object.fromEntries(Object.entries({ ...form }).filter(([_, v]) => v != null && v != 0));
        return ApiHelper.post2(`post/create-or-update`, data);
    }

    async get(id) {
        return ApiHelper.get(`post/get?id=${id}`);
    }

    async updateStatus(id, status) {
        return ApiHelper.post2(`post/update-status?id=${id}&status=${status}`);
    }

    async update(id, form) {
        let data = Object.fromEntries(Object.entries({ ...form }).filter(([_, v]) => v != null && v != 0));
        return ApiHelper.post(`post/update?id=${id}`, data);
    }

    async generateBytemplate(templateId) {
        return ApiHelper.get(`post/generate-by-template?templateId=${templateId}`);
    }

}

export default new ArticleModel();
