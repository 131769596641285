import { City, District } from "helpers/ProvinceUtil";
import React from "react"

function NameUserConstruction(userConstruction) {
    if (userConstruction) {
        const construction = userConstruction.construction;
        return <span>{construction.address} - {District.getName(construction.districtId)} - {City.getName(construction.cityId)}</span>
    }
    return 'Chưa xác định được công trình'
}

export { NameUserConstruction }