import React from "react"
import Editor from 'ckeditor5-custom-build/build/ckeditor';
// import {Editor as ClassicEditor} from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'


class MyEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.data
        }
    }


    getContent() {
        return this.state.value;
    }


    render() {
        return (
            <CKEditor
                editor={Editor}
                data={this.props.data}
                onChange={(event, editor) => {
                    const data = editor.getData()
                    this.props.setData(data)
                    this.setState({ value: data })
                }}
            />
        )
    }
}

export default MyEditor;
