import React, { useEffect, useMemo, useState } from "react"

import {
    Card,
    CardBody,
    CardHeader,
    CardImg,
    CardTitle,
    Col,
    Container,
    Input,
    Row,
    Table,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import UserModel from "api/UserModel"
import { City, District } from "helpers/ProvinceUtil"

import * as UserType from "constants/UserType"
import * as UserStatus from "constants/UserStatus"
import * as Products from "helpers/CementUtil"
import { Button, DatePicker, Select, Tag } from "antd"
import moment from "moment"
import LabelModel from "api/LabelModel"
import { error, success } from "helpers/notification"
import { PopupConfirm, PopupConfirmWithNote } from "components/pop-confirm"
import TableFormOfUser from "./table/TableFormOfUser"
import StatsBagsCement from "./chart/stats-bags-cement"
import TableOfConstructor from "./table/TableOfConstructor"
const { Option } = Select;

const children = [];
Products.getList().forEach(item => children.push(<Option key={item.id} value={item.id}>{item.name}</Option>))

const ProfileMember = props => {
    const [user, set_user] = useState({})
    const [labels, setLabels] = useState([])
    const [isApprovedOpen, setIsApprovedOpen] = useState(false)
    const [isRejectOpen, setIsRejectOpen] = useState(false)

    const btn_approved = () => {
        setIsApprovedOpen(true)
    }

    const btn_reject = () => {
        setIsRejectOpen(true)
    }

    const getMember = async () => {
        let result = await UserModel.getMember(props.match.params.id)
        set_user(result?.data)
    }

    const getLabels = async () => {
        let result = await LabelModel.getLabels()
        setLabels(result?.data)
    }

    const changeCalendar = (date1, date2) => {
        let tmp = moment(date1).toDate().getTime()
        set_user({ ...user, birthday: Math.floor(tmp / 1000) })
    }

    const update = async () => {
        let result = await UserModel.update(user?.id, user)
        if (result.error != 0) {
            error("Cập nhập thất bại")
            return
        }
        success("Cập nhập thành công")
        getMember()
    }

    const updateStatus = async (status, note) => {
        setIsApprovedOpen(false)
        setIsRejectOpen(false)
        let result = await UserModel.updateStatus(user?.id, status, note)
        if (result.error != 0) {
            error("Thất bại")
            return
        }
        success("Thành công")
        getMember()
    }

    useEffect(() => {
    }, [user])

    useEffect(() => {
        getLabels()
        getMember()
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Thành Viên"} breadcrumbItem="Profile" />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm={3}>
                                    <CardImg
                                        src={user?.avatar}
                                        alt="Skote"
                                        className="rounded-circle avatar-lg"
                                        style={{
                                            verticalAlign: "middle",
                                            position: "absolute",
                                            left: "50%",
                                            top: "50%",
                                            transform: "translate(-50%, -50%)",
                                            backgroundColor: "#FFFFFF",
                                        }}
                                    />
                                </Col>
                                <Col sm="4">
                                    <div className="table-responsive">
                                        <Table className="table-nowrap mb-0">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">ID :</th>
                                                    <td>{user?.id}</td>
                                                </tr>
                                                {
                                                    user.roleId === UserType.CONSTRUCTOR &&
                                                    <tr>
                                                        <th scope="row">Nhà thầu:</th>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="title"
                                                                onChange={e =>
                                                                    set_user({ ...user, name: e.target.value })
                                                                }
                                                                value={user?.name}
                                                            />
                                                        </td>
                                                    </tr>
                                                }

                                                {
                                                    user.roleId === UserType.RETAILER &&
                                                    <tr>
                                                        <th scope="row">Cửa hàng:</th>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="title"
                                                                onChange={e =>
                                                                    set_user({ ...user, name: e.target.value })
                                                                }
                                                                value={user?.name}
                                                            />
                                                        </td>
                                                    </tr>
                                                }

                                                {
                                                    user.roleId === UserType.DISTRIBUTOR &&
                                                    <tr>
                                                        <th scope="row">Nhà phân phối:</th>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="title"
                                                                onChange={e =>
                                                                    set_user({ ...user, name: e.target.value })
                                                                }
                                                                value={user?.name}
                                                            />
                                                        </td>
                                                    </tr>
                                                }

                                                {
                                                    user.roleId === UserType.INDUSTRIAL_CUSTOMER &&
                                                    <tr>
                                                        <th scope="row">Doanh nghiệp:</th>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="title"
                                                                onChange={e =>
                                                                    set_user({ ...user, name: e.target.value })
                                                                }
                                                                value={user?.name}
                                                            />
                                                        </td>
                                                    </tr>
                                                }

                                                <tr>
                                                    <th scope="row">SDT :</th>
                                                    <td><b>{user?.phone}</b></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Tỉnh/Thành phố:</th>
                                                    <td>
                                                        <select
                                                            className="form-control"
                                                            value={user?.cityId}
                                                            onChange={e =>
                                                                set_user({
                                                                    ...user,
                                                                    cityId: Number(e.target.value),
                                                                })
                                                            }
                                                        >
                                                            {City.getList().map(item => (
                                                                <option value={item.key} key={item.key}>
                                                                    {item.value}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Quận/huyện:</th>
                                                    <td>
                                                        <select
                                                            className="form-control"
                                                            value={user?.districtId}
                                                            onChange={e =>
                                                                set_user({
                                                                    ...user,
                                                                    districtId: Number(e.target.value),
                                                                })
                                                            }
                                                        >
                                                            {District.getList(user?.cityId).map(item => (
                                                                <option value={item.key} key={item.key}>
                                                                    {item.value}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Địa chỉ:</th>
                                                    <td>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            value={user?.address}
                                                            onChange={e =>
                                                                set_user({ ...user, address: e.target.value })
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                                <Col sm="4">
                                    <div className="table-responsive">
                                        <Table className="table-nowrap mb-0">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">INSEE ID :</th>
                                                    <td>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            value={user?.inseeId}
                                                            onChange={e =>
                                                                set_user({ ...user, inseeId: e.target.value })
                                                            }
                                                        />
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <th scope="row">Trạng thái :</th>
                                                    <td>
                                                        {" "}
                                                        <Tag color={UserStatus.findColor(user?.status)}>
                                                            {UserStatus.findName(user?.status)}
                                                        </Tag>
                                                    </td>
                                                </tr>
                                                {labels && user && (
                                                    <tr>
                                                        <th scope="row">Labels :</th>
                                                        <td>
                                                            <Select
                                                                mode="multiple"
                                                                style={{ width: '100%' }}
                                                                onChange={(values) => set_user({ ...user, labelIds: values })}
                                                                value={user?.labelIds}
                                                            >
                                                                {labels.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
                                                            </Select>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <th scope="row">Khách hàng:</th>
                                                    <td>{UserType.findName(user?.roleId)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">UTM:</th>
                                                    <td>{user?.utm}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Ghi chú:</th>
                                                    <td>{user?.note}</td>
                                                </tr>
                                                {user?.type == UserType.RETAILER && (
                                                    <tr>
                                                        <th scope="row">Sản phẩm:</th>
                                                        <td>
                                                            <Select
                                                                mode="multiple"
                                                                style={{ width: '100%' }}
                                                                onChange={(values) => set_user({ ...user, products: values })}
                                                                value={user?.products}
                                                            >
                                                                {children}
                                                            </Select>

                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3" style={{ justifyContent: "end" }}>
                                <Col sm={4}>
                                    <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded  mb-2 me-2"
                                        style={{ backgroundColor: "#00BCD4", color: "white", marginLeft: "10px", minWidth: '100px' }}
                                        onClick={update}
                                    >
                                        Cập nhập
                                    </Button>
                                    {user.status != UserStatus.APPROVED && (
                                        <Button
                                            type="button"
                                            color="success"
                                            className="btn-rounded  mb-2 me-2"
                                            style={{ backgroundColor: "#4CAF50", color: "white", marginLeft: "10px", minWidth: '100px' }}
                                            onClick={btn_approved}
                                        >
                                            Duyệt
                                        </Button>
                                    )}
                                    {user.status != UserStatus.REJECTED && (
                                        <Button
                                            type="button"
                                            color="success"
                                            className="btn-rounded  mb-2 me-2"
                                            style={{ backgroundColor: "#F44336", color: "white", marginLeft: "10px", minWidth: '100px' }}
                                            onClick={btn_reject}
                                        >
                                            Từ chối
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Row className="mt-3">
                                {user?.id && <StatsBagsCement userId={user.id} />}
                            </Row>
                        </CardBody>
                    </Card>
                    {user?.roleId === UserType.RETAILER &&
                        <Card>
                            <CardBody>
                                <CardTitle>Khách hàng</CardTitle>
                                <Row className="mt-3" >
                                    {user?.id && <TableOfConstructor retailerId={user.id} />}
                                </Row>
                            </CardBody>
                        </Card>
                    }
                    <Card>
                        <CardBody>
                            <CardTitle>Hoạt động</CardTitle>
                            <Row className="mt-3" >
                                {user?.id && <TableFormOfUser filter={{ userId: user.id }} />}
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
                {isApprovedOpen && (
                    <PopupConfirm
                        isOpen={isApprovedOpen}
                        toggle={() => setIsApprovedOpen(false)}
                        handleSubmit={() => updateStatus(UserStatus.APPROVED, "")}
                        title="Bạn có muốn duyêt người dùng này?"
                        content={{ id: user.id, name: user.name, phone: user.phone }}
                    />
                )}

                {isRejectOpen && (
                    <PopupConfirmWithNote
                        isOpen={isRejectOpen}
                        toggle={() => setIsRejectOpen(false)}
                        handleSubmit={note => updateStatus(UserStatus.REJECTED, note)}
                        title="Bạn có muốn từ chối người dùng này?"
                    />
                )}
            </div>
        </React.Fragment >
    )
}

export default ProfileMember
