import * as ApiHelper from './api_helper'

class GiftOrderModel {

    async updateStatus(id, status, note) {
        return ApiHelper.post2(`gift-order/update-status?id=${id}`, { status, note });
    }

    async get(id) {
        return ApiHelper.get(`gift-order/get?id=${id}`)
    }

    async find({ search, cityId, start, end, promotionId, status, page, pageSize }) {
        let params = Object.fromEntries(
            Object.entries({
                search, cityId, start, end, promotionId, status,
                page: page - 1, pageSize
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`gift-order/find?${new URLSearchParams(params).toString()}`)
    }

    async export({ search, cityId, start, end, promotionId, status }) {
        let params = Object.fromEntries(
            Object.entries({
                search, cityId, start, end, promotionId, status
            }).filter(([_, v]) => v != null && v != 0)
        );
        window.open(ApiHelper.getApiUrl() +
            `gift-order/export-excel?${new URLSearchParams(params).toString()}`, '_blank');
        return;
    }

}

export default new GiftOrderModel();
