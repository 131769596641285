import * as ApiHelper from '../api_helper'

class NotificationModel {
    async find(status, topicId, page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                status,
                topicId,
                page: page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`chat/notification/find?${new URLSearchParams(params).toString()}`)
    }

    async get(id) {
        return ApiHelper.get(`chat/notification/get?id=${id}`)
    }

    async updateStatus(id, status, note) {
        return ApiHelper.post2(`chat/notification/update-status`, { id, status, note });
    }

}

export default new NotificationModel();
