import React, { useEffect, useMemo, useState } from "react"

import { Card, CardBody, Container } from "reactstrap"

import TableMember from "./table/TableMember" //Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import * as UserType from "constants/UserType"

const DistributorMember = props => {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Thành Viên"} breadcrumbItem="Nhà Phân Phối" />
                    <Card>
                        <CardBody>
                            <TableMember filter={{ roleId: UserType.DISTRIBUTOR }} />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DistributorMember
