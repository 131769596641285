import { Image, Tag } from "antd"
import React, { useEffect, useState } from "react"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Table,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { City, District } from "helpers/ProvinceUtil"
import AudienceModel from "api/AudienceModel"
import { error, success } from "helpers/notification"
import TimeUtils from "helpers/time.utils"
import AudienceType from '../../../constants/AudienceType'
import * as UserType from '../../../constants/UserType'


const InfoAudience = props => {
    const audienceId = props.id;
    const [data, setData] = useState({})


    const fetchData = async () => {
        const resp = await AudienceModel.get(audienceId)
        if (resp.error != 0) {
            error("Tải lại trang!")
            return
        }
        setData(resp.data)
    }

    useEffect(() => {
        fetchData()
    }, [])

    console.log(data)

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Audience"} breadcrumbItem="Chi tiết" />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm="6">
                                    <div className="table-responsive">
                                        <Table className="table-nowrap mb-0">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">ID :</th>
                                                    <td>{data?.id}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Alias :</th>
                                                    <td>{data?.alias || 'Chưa có alias'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Loại :</th>
                                                    <td>
                                                        <Tag color={'#40a9ff'}>
                                                            {data?.type && AudienceType.findByName(data.type)}
                                                        </Tag>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <th scope="row">Ngày tạo:</th>
                                                    <td>
                                                        {TimeUtils.toDDMMYYYY(data?.createdTime * 1000)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="table-responsive">
                                        <Table className="table-nowrap mb-0">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Loại tài khoản</th>
                                                    <td>{data?.roleIds ? (data.roleIds.map(roleId => UserType.findName(roleId))) : ('Tất cả')}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Tỉnh/Thành phố:</th>
                                                    <td>{City.getName(data?.cityIds) || 'Tất cả'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Quận huyện</th>
                                                    <td>{District.getName(District?.districtIds) || 'Tất cả'}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default InfoAudience
