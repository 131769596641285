import axios from "axios"

//apply base url for axios
const API_URL =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL_DEV

const ADMIN_DOMAIN =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_DOMAIN_ADMIN
    : process.env.REACT_APP_DOMAIN_ADMIN_DEV

const axiosApi = axios.create({
  baseURL: API_URL,
})

export function getApiUrl() {
  return API_URL;
}

export async function get(url, config = { withCredentials: true }) {
  return await axiosApi.get(url, { ...config }).then(response => response.data).catch(err => {
    window.location.href = ADMIN_DOMAIN + "login"
    return err;
  })
}

export async function post(
  url,
  data,
  config = {
    withCredentials: true,
  }
) {
  let rawResponse = await fetch(API_URL + url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true,
    body: JSON.stringify(data)
  });
  return await rawResponse.json();
}

export async function post2(url, data, config = { withCredentials: true }) {
  return await axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function postArray(url, data, config = { withCredentials: true }) {
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = { withCredentials: true }) {
  return await axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = { withCredentials: true }) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
