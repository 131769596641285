import React, { useEffect, useState } from "react"
import { Card, CardBody, Container } from "reactstrap"
import PromotionType from "../../constants/PromotionType"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PromotionModel from "api/PromotionModel"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import AmazingRaceProgram from "./container/AmazingRaceProgram"
import HotdealProgram from "./container/HotdealProgram"
import ConstructorLoyaltyProgram from "./container/ConstructorLoyaltyProgram"
import TotalForceProgram from "./container/TotalForceProgram"
import AsFastAsLightingProgram from "./container/AsFastAsLightingProgram"
import LiveStreamProgram from "./container/LiveStreamProgram"

const Program = props => {
    const { promotionId } = useParams()
    const [promotion, setPromotion] = useState()

    const fetch = async id => {
        let resp = await PromotionModel.get(id)
        if (resp.error == 0) {
            setPromotion(resp.data)
        }
    }

    useEffect(() => {
        fetch(promotionId)
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs breadcrumbItem={`${promotion?.title}`} />
                    <Card>
                        <CardBody>
                            {promotion?.type == PromotionType.NORMAL_INVOICE && (
                                <AmazingRaceProgram
                                    promotionId={promotion.id}
                                />
                            )}
                            {promotion?.type == PromotionType.HOT_DEAL && (
                                <HotdealProgram
                                    promotionId={promotion.id}
                                />
                            )}
                            {promotion?.type == PromotionType.LOYALTY_PROMOTION && (
                                <ConstructorLoyaltyProgram
                                    promotionId={promotion.id}
                                />
                            )}
                            {promotion?.type == PromotionType.TOTAL_FORCE_PROMOTION && (
                                <TotalForceProgram promotionId={promotion.id} />
                            )}
                            {promotion?.type == PromotionType.AS_FAST_AS_LIGHTING_MINI_GAME && (
                                <AsFastAsLightingProgram promotionId={promotion.id} />
                            )}
                            {promotion?.type == PromotionType.LIVE_STREAM_BROADCAST_PROMOTION && (
                                <LiveStreamProgram promotion={promotion.id} />
                            )}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Program
