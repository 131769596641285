class AudienceType {
    static SECTOR = 1
    static UID_SETS = 2

    static findByName(value) {
        switch(value) {
            case this.SECTOR : {
                return 'Theo khu vực'
            }
            case this.UID_SETS : {
                return 'Tập user'
            }
        }
    }
}

export default AudienceType