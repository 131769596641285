import * as ApiHelper from './api_helper'

class QuestionFormModel {

    async find({ promotionId, page, pageSize }) {
        let params = Object.fromEntries(
            Object.entries({
                promotionId, page, pageSize
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`question-form/find?${new URLSearchParams(params).toString()}`)
    }

}

export default new QuestionFormModel();
