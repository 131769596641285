import * as UserType from '../../constants/UserType'

const CONSTRUCTOR_DOMAIN =
    process.env.REACT_APP_ENV === "production"
        ? process.env.REACT_APP_DOMAIN_CONSTRUCTOR
        : process.env.REACT_APP_DOMAIN_CONSTRUCTOR_DEV

const RETAILER_DOMAIN =
    process.env.REACT_APP_ENV === "production"
        ? process.env.REACT_APP_DOMAIN_RETAILER
        : process.env.REACT_APP_DOMAIN_RETAILER_DEV


export function genQR(roleId, path) {
    if (roleId == UserType.CONSTRUCTOR) {
        return `https://www.qr-code-generator.com/phpqrcode/getCode.php?cht=qr&chl=${encodeURIComponent(CONSTRUCTOR_DOMAIN + path)}&chs=500x500&choe=UTF-8&chld=L|0`
    }
    if (roleId == UserType.RETAILER) {
        return `https://www.qr-code-generator.com/phpqrcode/getCode.php?cht=qr&chl=${encodeURIComponent(RETAILER_DOMAIN + path)}&chs=500x500&choe=UTF-8&chld=L|0`
    }
}

export function genQRV2(link) {
    return `https://www.qr-code-generator.com/phpqrcode/getCode.php?cht=qr&chl=${encodeURIComponent(link)}&chs=500x500&choe=UTF-8&chld=L|0`
}
