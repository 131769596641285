// TableMember

import { Button, Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import TimeUtils from "helpers/time.utils"
import { Link, useHistory } from "react-router-dom"
import queryString from 'query-string'
import TopicSurveyModel from "api/TopicSurveyModel"
import { Row, Col } from "reactstrap"
import FormTopicModal from "../modal/FormTopicModal"

const TableTopicSurvey = props => {

    let history = useHistory();
    const [modal, setModal] = useState(false)
    let columns = [
        {
            title: "ID",
            dataIndex: "id",
            render: id => id
        },
        {
            title: "Tên",
            dataIndex: "title",
            render: title => title
        },
        {
            title: "Câu hỏi bắt đầu",
            dataIndex: "gettingStarted",
            render: gettingStarted => gettingStarted
        },
        {
            title: "Fields",
            dataIndex: "fields",
            render: fields => fields.map((field) => {
                return <Tag key={field} >
                    {field}
                </Tag>
            })
        },
        {
            title: "Thời gian",
            dataIndex: "createdTime",
            render: createdTime => TimeUtils.toDDMMYYYY(createdTime * 1000),
        },
        {
            title: "Hành động",
            render: (_, record) => (
                <>
                    <Button type="primary" size="small" style={{ width: "120px" }}
                        onClick={() => history.push(`/survey/detail?topicId=${record.id}`)} >
                        Chi tiết
                    </Button>

                </>
            ),
        },
    ]

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [filter, setFilter] = useState({ ...props.filter, ...queryString.parseUrl(history.location.search).query })

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.replace({ search: url })
    };

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await TopicSurveyModel.find(
            newPagination.current,
            newPagination.pageSize
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData({ current: 1, pageSize: pagination.pageSize })
        serialize2Url()
    }, [filter])


    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="text-sm-end mb-3">
                        <Row>
                            <Col sm={8}>

                            </Col>
                            <Col sm={4}>
                                <div className="btn-group" role="group">
                                    <Button type="primary" style={{ marginLeft: "10px", height: '38px' }} size={38}>
                                        <Link to={'#'} onClick={() => {
                                            setModal(true)
                                        }}>Thêm mới</Link>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <FormTopicModal open={modal} onClose={() => { setModal(false) }} onSuccess={() => {
                setModal(false);
                fetchData(pagination);
            }} />
        </>
    )
}

export default TableTopicSurvey
