import React, { useEffect, useState } from "react"
import InfoAudience from './component/info-audience'


const DetailAudience = props => {
    const audienceId = props.match.params.audienceId;

    return (
        <React.Fragment>
            <InfoAudience id={audienceId}/>
        </React.Fragment>
    )
}

export default DetailAudience
