import React, { useEffect, useState } from "react"
import { Card, CardBody, Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";
import queryString from 'query-string'
import classnames from "classnames";
import {
    Row, Col,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap"
import VerifiedStoreTable from "./table/VerifiedStoreTable";
import NotVerifiedStoreTable from "./table/NotVerifiedStoreTable";

const ConstructorRetailer = props => {
    const history = useHistory();
    const { tab = 'verified' } = queryString.parseUrl(history.location.search).query

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={"Cửa hàng"}
                        breadcrumbItem={"Cửa hàng"}
                    />
                    <Card>
                        <CardBody>

                            <Row>
                                <Col sm={12}>
                                    <div className="text-sm-end mb-3">
                                        <Row>
                                            <Col sm={12}>
                                                <Nav pills>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({
                                                                active: tab == "verified"
                                                            })}
                                                            onClick={() => {
                                                                history.push(`stores?tab=verified`)
                                                            }}
                                                        >
                                                            <i className="bx bx-chat font-size-20 d-sm-none" />
                                                            <span className="d-none d-sm-block">Đã xác định</span>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({
                                                                active: tab == "not-verified"
                                                            })}
                                                            onClick={() => {
                                                                history.push(`stores?tab=not-verified`)
                                                            }}
                                                        >
                                                            <i className="bx bx-chat font-size-20 d-sm-none" />
                                                            <span className="d-none d-sm-block">Chưa xác định</span>
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            {tab == "verified" && <VerifiedStoreTable />}
                            {tab == "not-verified" && <NotVerifiedStoreTable />}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ConstructorRetailer
