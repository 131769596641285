import React, { useEffect, useState } from "react"

import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Select, DatePicker } from "antd"
import moment from "moment"
import { City, District } from "helpers/ProvinceUtil"
import { success } from "helpers/notification"
import { error } from "helpers/notification"
import { useHistory } from "react-router-dom"
import * as UserType from "constants/UserType"
import PromotionType from "constants/PromotionType"
import PromotionModel from "api/PromotionModel"
import UploadImage from "components/UploadImage"
import * as CementUtil from "helpers/CementUtil"
import * as QRUtil from '../../common/qr'

const { RangePicker } = DatePicker
const dateFormat = "DD/MM/YYYY"

const EditPromotion = props => {
    const [start_time, set_start_time] = useState(moment().subtract(60, "days"))
    const [end_time, set_end_time] = useState(moment())
    const [selectedCities, setSelectedCities] = useState([])
    const [selectedDistricts, setSelectedDistricts] = useState([])
    const [validation, setValidation] = useState([])
    const [isSubmit, setIsSubmit] = useState(false)
    const [done, setDone] = useState(false)

    const [field, setField] = useState({
        timeStart: start_time.toDate().getTime(),
        timeEnd: end_time.toDate().getTime(),
        roleUsers: [2, 3],
    })
    const history = useHistory()

    let cities = City.getList()
    let filteredCities = cities.filter(o => !selectedCities.includes(o.key))

    let districts = selectedCities.flatMap(item =>
        District.getList(City.getByName(item).id)
    )
    let filteredDistricts = districts.filter(
        o => !selectedDistricts.includes(o.key)
    )

    const changeCalendar = data => {
        set_start_time(data[0])
        set_end_time(data[1])
        let start = moment(data[0]).toDate().getTime()
        let end = moment(data[1]).toDate().getTime()
        setField({ ...field, timeStart: start, timeEnd: end })
    }

    const getPromotion = async id => {
        setDone(false)
        let result = await PromotionModel.get(id)
        setField({
            ...result.data,
            roleIds: result.data?.roleUsers.length == 2 ? 0 : result.data.roleUsers,
        })
        let start = moment(result.data.timeStart)
        let end = moment(result.data.timeEnd)
        set_start_time(start)
        set_end_time(end)
        setDone(true)
    }

    const updatePromotion = async e => {
        e.preventDefault()
        setIsSubmit(true)
        let isError = validate()
        if (isError) {
            error("Vui lòng nhập đầy đủ!")
            return
        }
        let result = await PromotionModel.update(field.id, field)
        if (result.error != 0) {
            error(result.msg)
            return
        }
        success("Cập nhập chương trình thành công!")
        history.push(`/promotions/campaigns`)
    }

    const validate = () => {
        let isError = false
        let tmp = {}
        if (!field?.title) {
            isError = true
            tmp = { ...tmp, title: "Thiếu tiêu đề" }
        }
        if (!field?.type) {
            isError = true
            tmp = { ...tmp, type: "Chọn loại đơn" }
        }
        setValidation(tmp)
        return isError
    }
    const change_images = data => {
        setField({ ...field, image: data[0]?.url })
    }


    useEffect(() => {
        let temp = []
        selectedCities.forEach(item => temp.push(City.getByName(item)))
    }, [selectedCities])

    useEffect(() => {
        let temp = []
        setField({ ...field, roleUsers: [2, 3] })
        selectedDistricts.forEach(item => temp.push(item))
    }, [selectedDistricts])

    useEffect(() => {
        getPromotion(props.match.params.id)
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={"Chương trình"}
                        breadcrumbItem="Câp nhập chương trình"
                    />
                    {done && <Card>
                        <CardBody>
                            {field?.type == PromotionType.HOT_DEAL &&
                                <Row style={{ justifyContent: 'center' }}>
                                    <img style={{ width: '250px' }} src={QRUtil.genQRV2(`https://zalo.me/s/2604973040500424243/form/${props.match.params.id}`)} />
                                </Row>
                            }
                            <Form>
                                <div className="mb-3">
                                    <Label htmlFor="title">
                                        Tiêu đề{" "}
                                        {
                                            <span style={{ color: "red" }}>
                                                {validation["title"]}
                                            </span>
                                        }
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="title"
                                        placeholder="Nhập tiêu đề chương trình"
                                        onChange={e => {
                                            setField({ ...field, title: e.target.value })
                                        }}
                                        invalid={isSubmit && validation["title"] != null}
                                        value={field.title}
                                    />
                                </div>
                                <Row>
                                    <Col sm={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="roleIds">
                                                Ai được tham gia chương trình này?
                                            </Label>
                                            <select
                                                id="roleIds"
                                                className="form-control"
                                                value={field?.roleIds}
                                                onChange={e =>
                                                    setField({
                                                        ...field,
                                                        roleIds: Number(e.target.value),
                                                        roleUsers:
                                                            e.target.value == 0
                                                                ? [2, 3]
                                                                : [Number(e.target.value)],
                                                    })
                                                }
                                            >
                                                <option value={0}>Tất cả</option>
                                                <option value={UserType.CONSTRUCTOR}>
                                                    {UserType.findName(UserType.CONSTRUCTOR)}
                                                </option>
                                                <option value={UserType.RETAILER}>
                                                    {UserType.findName(UserType.RETAILER)}
                                                </option>
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="type">
                                                Loại?{" "}
                                                {
                                                    <span style={{ color: "red" }}>
                                                        {validation["type"]}
                                                    </span>
                                                }
                                            </Label>
                                            <select
                                                id="type"
                                                className="form-control"
                                                value={field?.type}
                                                onChange={e =>
                                                    setField({ ...field, type: Number(e.target.value) })
                                                }
                                            >
                                                <option value={0}>Chọn...</option>
                                                <option value={PromotionType.LOYALTY_PROMOTION}>
                                                    {PromotionType.findName(
                                                        PromotionType.LOYALTY_PROMOTION
                                                    )}
                                                </option>
                                                <option value={PromotionType.PREDICT_FOOTBALL}>
                                                    {PromotionType.findName(
                                                        PromotionType.PREDICT_FOOTBALL
                                                    )}
                                                </option>
                                                <option value={PromotionType.NORMAL_INVOICE}>
                                                    {PromotionType.findName(
                                                        PromotionType.NORMAL_INVOICE
                                                    )}
                                                </option>
                                                <option value={PromotionType.HOT_DEAL}>
                                                    {PromotionType.findName(
                                                        PromotionType.HOT_DEAL
                                                    )}
                                                </option>
                                                <option value={PromotionType.QUICK_QUESTION_PROMOTION}>
                                                    {PromotionType.findName(
                                                        PromotionType.QUICK_QUESTION_PROMOTION
                                                    )}
                                                </option>
                                            </select>
                                        </div>
                                    </Col>

                                    <Col sm={4}>
                                        <Label htmlFor="duration" style={{ width: "100%" }}>
                                            Khoảng thời gian
                                        </Label>
                                        <RangePicker
                                            value={[
                                                moment(start_time, dateFormat),
                                                moment(end_time, dateFormat),
                                            ]}
                                            onChange={changeCalendar}
                                            format={dateFormat}
                                        />
                                    </Col>
                                </Row>
                                {field?.type == PromotionType.HOT_DEAL && (
                                    <Row>
                                        <Col md={4}>
                                            <div className="mb-3 templating-select select2-container">
                                                <label className="control-label">Áp dụng cho những xi măng ?</label>
                                                <Select
                                                    disabled
                                                    mode="multiple"
                                                    onChange={values => {
                                                        setField({ ...field, cementIds: values })
                                                    }}
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    value={field.cementIds}
                                                >
                                                    {CementUtil.getList().map(item => (
                                                        <Select.Option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col sm={4}>
                                            <div className="mb-3">
                                                <Label htmlFor="type">
                                                    Quà tặng?
                                                </Label>
                                                <select
                                                    disabled
                                                    id="type"
                                                    className="form-control"
                                                    value={field?.useLuckyDraw}
                                                    onChange={e =>
                                                        setField({ ...field, useLuckyDraw: e.target.value })
                                                    }
                                                >
                                                    <option value={0}>Chọn...</option>
                                                    <option value={true}>
                                                        Lucky draw
                                                    </option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                {field?.type == PromotionType.NORMAL_INVOICE && (
                                    <Row>
                                        <Col md={4}>
                                            <div className="mb-3 templating-select select2-container">
                                                <label className="control-label">Áp dụng cho những xi măng ?</label>
                                                <Select
                                                    mode="multiple"
                                                    onChange={values => {
                                                        setField({ ...field, cementIds: values })
                                                    }}
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    value={field.cementIds}
                                                >
                                                    {CementUtil.getList().map(item => (
                                                        <Select.Option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <Label htmlFor="title">
                                                Quota{" "}
                                                {
                                                    <span style={{ color: "red" }}>
                                                        {validation["Quota"]}
                                                    </span>
                                                }
                                            </Label>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="quota"
                                                placeholder="Nhập quota"
                                                onChange={e => {
                                                    setField({ ...field, quota: Number(e.target.value) })
                                                }}
                                                invalid={isSubmit && validation["quota"] != null}
                                                value={field.quota}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Label htmlFor="title">
                                                Số lượng tối thiểu (bao){" "}
                                                {
                                                    <span style={{ color: "red" }}>
                                                        {validation["Quota"]}
                                                    </span>
                                                }
                                            </Label>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="minBags"
                                                placeholder="Nhập quota"
                                                onChange={e => {
                                                    setField({ ...field, minBags: Number(e.target.value) })
                                                }}
                                                invalid={isSubmit && validation["minBags"] != null}
                                                value={field.minBags}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col md={12}>
                                        <div className="mb-3 templating-select select2-container">
                                            <label className="control-label">Tỉnh /Thành Phố</label>
                                            <Select
                                                mode="multiple"
                                                onChange={value => {
                                                    setSelectedCities(value)
                                                    let temp = []
                                                    value.forEach(item =>
                                                        temp.push(City.getByName(item).id)
                                                    )
                                                    setField({ ...field, cityIds: temp })
                                                }}
                                                style={{
                                                    width: "100%",
                                                }}
                                                value={field.cityIds?.map(item => City.getName(item))}
                                            >
                                                {filteredCities.map(item => (
                                                    <Select.Option key={item.key} value={item.value}>
                                                        {item.value}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="mb-3 templating-select select2-container">
                                            <label className="control-label">Quận/huyện</label>
                                            <Select
                                                mode="multiple"
                                                onChange={value => {
                                                    setSelectedDistricts(value)
                                                    let temp = []
                                                    value.forEach(item =>
                                                        temp.push(District.getByName(item).id)
                                                    )
                                                    setField({ ...field, districtIds: temp })
                                                }}
                                                style={{
                                                    width: "100%",
                                                }}
                                                value={field.districtIds?.map(item =>
                                                    District.getName(item)
                                                )}
                                            >
                                                {filteredDistricts.map(item => (
                                                    <Select.Option key={item.key} value={item.value}>
                                                        {item.value}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Label htmlFor="summary">
                                            Tóm tắt{" "}
                                            {
                                                <span style={{ color: "red" }}>
                                                    {validation["summary"]}
                                                </span>
                                            }{" "}
                                        </Label>
                                        <textarea
                                            name="textarea"
                                            label="Textarea"
                                            rows="4"
                                            className={`form-control ${validation["summary"] ? "is-invalid" : ""
                                                }`}
                                            spellCheck="false"
                                            onChange={e =>
                                                setField({ ...field, description: e.target.value })
                                            }
                                            value={field.description}
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col md={6}>
                                        <Label htmlFor="duration">
                                            Ảnh đại diện{" "}
                                            {
                                                <span style={{ color: "red" }}>
                                                    {validation["cover"]}
                                                </span>
                                            }
                                        </Label>
                                        <Row className="mb-3">
                                            <Col sm={12}>
                                                <UploadImage change_images={change_images} size={1} exits_images={[{ url: field?.image }]} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <div className="mb-3 justify-content-end">
                                    <button
                                        onClick={updatePromotion}
                                        className="btn btn-primary w-md "
                                        style={{ float: "right" }}
                                    >
                                        Cập nhập chương trình
                                    </button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>}
                </Container>
            </div>
        </React.Fragment>
    )
}

export default EditPromotion
