import * as ApiHelper from './api_helper'

class ConstructionModel {
    async find(page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                page: page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`construction/find?${new URLSearchParams(params).toString()}`)
    }

    async get(id) {
        let params = Object.fromEntries(
            Object.entries({
                id,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`construction/get?${new URLSearchParams(params).toString()}`)
    }

}

export default new ConstructionModel();
