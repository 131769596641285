import * as ApiHelper from './api_helper'

class LabelModel {
    async getLabels() {

        return ApiHelper.get(`label/find`)
    }


}

export default new LabelModel();
