import React from "react"

import { Switch, BrowserRouter as Router, useHistory } from "react-router-dom"

// Import Routes all
import { publicRoutes, authProtectedRoutes } from "./routes"

// Import all middleware
import AuthMiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "components/NonAuthLayout"

// Import scss
// import 'antd/dist/antd.css';
import './app.css';
import "./assets/scss/theme.scss"
import "toastr/build/toastr.min.css";
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { login } from "store/auth/auth.slice"

const App = props => {
  function getLayout() {
    return VerticalLayout
  }
  const Layout = getLayout()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(login({}))
  }, [dispatch])

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AuthMiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <AuthMiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

export default App
