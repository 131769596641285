import React, { useEffect, useState } from "react"
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
} from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { DatePicker } from "antd"
import moment from "moment"

import { success } from "helpers/notification"
import { error } from "helpers/notification"
import { useHistory } from "react-router-dom"
import CountryModel from "api/CountryModel"
import MatchModel from "api/MatchModel"

const dateFormat = "hh giờ mm - DD/MM/YYYY"

const CreateMatch = props => {
    const history = useHistory();
    const [start_time, set_start_time] = useState(moment())
    const [validation, setValidation] = useState([])
    const [country, setCountry] = useState([])
    const [field, setField] = useState({
        timeStart: start_time.toDate().getTime()
    })


    const getCountry = async () => {
        let result = await CountryModel.find(1, 100);
        setCountry(result.data.list)
    }

    const changeCalendar = data => {
        set_start_time(data)
        setField({ ...field, timeStart: data.toDate().getTime() })
    }

    const create = async e => {
        e.preventDefault()
        let isError = validate()
        if (isError) {
            error("Vui lòng nhập đầy đủ!")
            return
        }
        let result = await MatchModel.create(field.host, field.guest, field.timeStart);
        if (result.error != 0) {
            error(result.msg)
            return
        }
        success("Tạo trận đấu thành công!")
        history.goBack();
    }

    const validate = () => {
        let isError = false
        let tmp = {}
        if (!field?.host) {
            isError = true
            tmp = { ...tmp, host: "Chọn đội chủ nhà" }
        }
        if (!field?.guest) {
            isError = true
            tmp = { ...tmp, guest: "Thiếu đội khách" }
        }
        setValidation(tmp)
        return isError
    }

    useEffect(() => { getCountry() }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={"Chương trình"}
                        breadcrumbItem="Tạo trận đấu"
                    />
                    <Card>
                        <CardBody>
                            <Form>

                                <Row>
                                    <Col sm={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="roleIds">
                                                Đội chủ nhà
                                                {
                                                    <span style={{ color: "red" }}>
                                                        {validation["host"]}
                                                    </span>
                                                }
                                            </Label>
                                            <select
                                                id="roleIds"
                                                className="form-control"
                                                value={field?.roleIds}
                                                onChange={e =>
                                                    setField({
                                                        ...field,
                                                        host: Number(e.target.value),
                                                    })
                                                }
                                            >
                                                <option value={0}>Chọn ...</option>
                                                {country.map(item => <option value={item.id} key={item.id}>
                                                    {item.name}
                                                </option>)}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="type">
                                                Đội khách
                                                {
                                                    <span style={{ color: "red" }}>
                                                        {validation["guest"]}
                                                    </span>
                                                }
                                            </Label>
                                            <select
                                                id="type"
                                                className="form-control"
                                                value={field?.type}
                                                onChange={e =>
                                                    setField({ ...field, guest: Number(e.target.value) })
                                                }
                                            >
                                                <option value={0}>Chọn...</option>
                                                {country.map(item => <option value={item.id} key={item.id}>
                                                    {item.name}
                                                </option>)}
                                            </select>
                                        </div>
                                    </Col>

                                    <Col sm={12} className="mb-3">
                                        <Label htmlFor="duration" style={{ width: "100%" }}>
                                            Thời gian bắt đầu
                                        </Label>
                                        <DatePicker
                                            showTime={{ format: 'HH:mm' }}
                                            value={
                                                moment(start_time)
                                            }
                                            onChange={changeCalendar}
                                            format={dateFormat}
                                        />
                                    </Col>
                                </Row>

                                <div className="mb-3 justify-content-end">
                                    <button
                                        onClick={create}
                                        className="btn btn-primary w-md "
                                        style={{ float: "right" }}
                                    >
                                        Tạo trận đấu
                                    </button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateMatch
