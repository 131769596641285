import * as ApiHelper from '../api_helper'


class InvoiceLoyaltyFormModel {
    async getForms({ search, status, userId, cityId, promotionId, userConstructionId, constructorRetailerId, start, end, page, pageSize }) {
        let params = Object.fromEntries(
            Object.entries({
                search, status, userId, cityId, promotionId,
                userConstructionId, constructorRetailerId, start, end, page, pageSize
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`loyalty/form/find?${new URLSearchParams(params).toString()}`)
    }

    async getForm(formId) {
        return ApiHelper.get(`loyalty/form/get?id=${formId}`)
    }


    async updateStatus(id, status, note) {
        return ApiHelper.post2(`loyalty/form/update-status?id=${id}`, { status, note });
    }

    async export(promotionId) {
        window.open(ApiHelper.getApiUrl() +
        `loyalty/form/export?promotionId=${promotionId}`, '_blank');
    }
}

export default new InvoiceLoyaltyFormModel();