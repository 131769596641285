import { Image, Tag } from "antd"
import React, { useEffect, useState } from "react"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Table,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { City } from "helpers/ProvinceUtil"
import { error, success } from "helpers/notification"
import TimeUtils from "helpers/time.utils"
import { PopupConfirm, PopupConfirmWithNote } from "components/pop-confirm"
import GiftOrderStatus from "constants/GiftOrderStatus"
import GiftOrderModel from "api/GiftOrderModel"


const ExchangePointGiftOrderForm = props => {
    const [giftOrder, setGiftOrder] = useState(props.data);
    const [isApprovedOpen, setIsApprovedOpen] = useState(false)
    const [isRejectOpen, setIsRejectOpen] = useState(false)
    const [isSendOpen, setIsSendOpen] = useState(false)

    const fetch = async id => {
        let resp = await GiftOrderModel.get(id)
        if (resp.error == 0) {
            setGiftOrder(resp.data)
        }
    }

    const updateStatus = async (status, note) => {
        setIsApprovedOpen(false)
        setIsRejectOpen(false)
        let result = await GiftOrderModel.updateStatus(giftOrder?.id, status, note)
        if (result.error != 0) {
            error("Thất bại")
            return
        }
        success("Thành công")
        fetch(giftOrder.id)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Forms"} breadcrumbItem="Chi tiết" />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm="6">
                                    <div className="table-responsive">
                                        <Table className="table-nowrap mb-0">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">ID :</th>
                                                    <td>{giftOrder?.id}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Họ tên :</th>
                                                    <td>{giftOrder?.user?.name}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">SDT :</th>
                                                    <td><b>{giftOrder?.user?.phone}</b></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Tỉnh/Thành phố:</th>
                                                    <td>{City.getName(giftOrder?.user?.cityId)}</td>
                                                </tr>


                                                <tr>
                                                    <th scope="row">Ngày tạo:</th>
                                                    <td>
                                                        {TimeUtils.toDDMMYYYY(giftOrder?.createdTime * 1000)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="table-responsive">
                                        <Table className="table-nowrap mb-0">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Quà tặng: </th>
                                                    <td>
                                                        {giftOrder?.gift?.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Số lượng: </th>
                                                    <td><b>{giftOrder?.amount}</b></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Số điểm: </th>
                                                    <td>{giftOrder?.point}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Trạng thái :</th>
                                                    <td>
                                                        <Tag color={GiftOrderStatus.findColor(giftOrder?.status)}>
                                                            {GiftOrderStatus.findName(giftOrder?.status)}
                                                        </Tag>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Ghi chú:</th>
                                                    <td>{giftOrder?.note}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3" style={{ justifyContent: "end" }}>
                                <Col sm={4}>
                                    {(giftOrder.status === GiftOrderStatus.INIT)
                                        && <>
                                            <Button
                                                color="primary"
                                                className="btn btn-primary "
                                                style={{ marginLeft: "10px" }}
                                                onClick={() => { setIsApprovedOpen(true) }}
                                            >
                                                Duyệt
                                            </Button>
                                            <Button
                                                color="danger"
                                                className="btn btn-danger "
                                                style={{ marginLeft: "10px" }}
                                                onClick={() => { setIsRejectOpen(true) }}
                                            >
                                                Từ chối
                                            </Button>
                                        </>}
                                    {giftOrder.status === GiftOrderStatus.APPROVED &&
                                        <>
                                            <Button
                                                color="primary"
                                                className="btn btn-primary "
                                                style={{ marginLeft: "10px" }}
                                                onClick={() => { setIsSendOpen(true) }}
                                            >
                                                Gửi quà
                                            </Button>
                                        </>
                                    }
                                </Col>
                            </Row>
                            <PopupConfirm
                                isOpen={isApprovedOpen}
                                toggle={() => setIsApprovedOpen(false)}
                                handleSubmit={() => updateStatus(GiftOrderStatus.APPROVED, "")}
                                title="Bạn có muốn duyêt duyệt này?"
                                content={{ 'Quà tặng': giftOrder.gift.name, 'Số lượng': giftOrder.amount }}
                            />

                            <PopupConfirm
                                isOpen={isSendOpen}
                                toggle={() => setIsSendOpen(false)}
                                handleSubmit={() => updateStatus(GiftOrderStatus.SEND, "")}
                                title="Bạn xác nhận là đã gửi quà đến user?"
                                content={{ 'Quà tặng': giftOrder.gift.name, 'Số lượng': giftOrder.amount }}
                            />

                            <PopupConfirmWithNote
                                isOpen={isRejectOpen}
                                toggle={() => setIsRejectOpen(false)}
                                handleSubmit={note => updateStatus(GiftOrderStatus.REJECTED, note)}
                                title="Bạn có muốn từ chối đơn này?"
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ExchangePointGiftOrderForm
