class FormType {
    static INVOICE_LOYALTY_CTR_TYPE = 40;
    static REGISTER_LOYALTY_RTL_TYPE = 41;
    static INVOICE_RTL_TYPE = 42;
    static INVOICE_CTR_TYPE = 43;
    static PREDICT_FOOTBALL_TYPE = 51;
    static HOT_DEAL_TYPE = 61;
    static QUICK_QUESTION_TYPE = 71;

    static findName(value) {
        switch (value) {
            case this.INVOICE_LOYALTY_CTR_TYPE: return 'Loyalty Cho Nhà Thầu'
            case this.INVOICE_RTL_TYPE: return 'Loyalty Cho Cửa Hàng'
            case this.REGISTER_LOYALTY_RTL_TYPE: return 'Form Đăng ký tham gia Chương trình'
            case this.PREDICT_FOOTBALL_TYPE: return 'Dự đoán bóng đá'
            case this.INVOICE_CTR_TYPE: return 'Hóa đơn mua hàng'
            case this.HOT_DEAL_TYPE: return 'Đơn HOT DEAL'
            case this.QUICK_QUESTION_TYPE: return 'Câu hỏi nhanh';
        }
    }

    static findColor(value) {
        switch (value) {
        }
    }
}

export default FormType;