class BroadcastStatus {
    static INIT = 1;
    static APPROVED = 2;
    static DELETED = 3;
    static CANCELED = 4;
    static DONE = 5;
    static FAILED = 6;

    static findName(value) {
        switch (value) {
            case this.INIT: return 'Khởi tạo'
            case this.APPROVED: return 'Đã duyệt'
            case this.DELETED: return 'Đã xóa'
            case this.CANCELED: return 'Đã hủy'
            case this.DONE: return 'Thành công'
            case this.FAILED: return 'Thất bại'
        }
    }

    static findColor(value) {
        switch (value) {
            case this.INIT: return '#03a9f4'
            case this.APPROVED: return '#ffc107'
            case this.DELETED: return 'Đã xóa'
            case this.CANCELED: return '#9e9e9e'
            case this.DONE: return '#4caf50'
            case this.FAILED: return '#9c27b0'
        }
    }
}

export default BroadcastStatus;