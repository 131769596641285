import React, { useEffect, useRef, useMemo } from 'react';
import { useState } from "react";
import { Select, Spin, Space } from 'antd';
import debounce from 'lodash/debounce';
import UserModel from 'api/UserModel';
import * as UserType from '../../../constants/UserType';
import { City, District } from 'helpers/ProvinceUtil';

const { Option } = Select;

const RetailerDebounceSelect = (props) => {
    const [value, setValue] = useState();

    useEffect(() => {
        if (props.value) {
            UserModel.getMember(props.value)
                .then(resp => {
                    if (resp.error == 0) {
                        setValue(resp.data.name);
                    }
                })
        }
    }, [props.value])

    return (
        <>
            <DebounceSelect
                placeholder={"Nhập SDT để tìm kiếm chính xác hơn"}
                showSearch
                value={value}
                className={'custom-select-ant'}
                fetchOptions={search}
                onChange={(option) => {
                    props.onChange(option.value)
                }}
                style={{
                    width: '100%',
                }}
            />
        </>
    )
}

export default RetailerDebounceSelect;


function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);


    return (
        <Select
            showSearch={true}
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
        >
            {options}
        </Select>
    );
}

async function search(text) {
    return UserModel.getMembers(text, undefined, UserType.RETAILER,
        undefined, undefined, undefined, undefined)
        .then((resp) => resp?.data?.list)
        .then((data) => {
            if (data) {
                return data.map((result) => {
                    return (
                        <Option key={result.id} value={result.id}>
                            <div>
                                <span style={{ fontWeight: '500', color: '#40a9ff' }} >
                                    {result.name}
                                </span>
                                {" "}
                                <span>
                                    SDT: {result.phone}
                                </span>
                            </div>
                            <div>
                                {result.address} {District.getName(result.districtId)} {City.getName(result.cityId)}
                            </div>
                        </Option>
                    )
                })
            }
        });
}
