import RegistrationEventModel from "api/RegistrationEventModel";
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"


const RegistrationEventAnalytics = (props) => {

    const [report, setReport] = useState(null);

    const getAnalyticsForm = async () => {
        let resp = await RegistrationEventModel.getAnalytics();
        if (resp.error === 0) {
            setReport(resp.data)
        }
    }

    useEffect(() => { console.log(props.filter); getAnalyticsForm(); }, [props.filter])

    console.log(report)
    return (
        <Row>
            <Col sm={3}>
                <Card>
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                    Xi măng dân dụng
                                </p>
                                <h4 className="mb-0">{report && report['cc24_xi_mang_dan_dung']}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                        className={
                                            "bx bx-archive-in font-size-24"
                                        }
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col sm={3}>
                <Card>
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                    Xi măng Công nghiệp
                                </p>
                                <h4 className="mb-0">{report && report['cc24_xi_mang_cong_nghiep']}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                        className={
                                            "bx bx-archive-in font-size-24"
                                        }
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col sm={3}>
                <Card>
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                    Conwood
                                </p>
                                <h4 className="mb-0">{report && report['cc24_conwood']}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                        className={
                                            "bx bx-archive-in font-size-24"
                                        }
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>

        </Row>
    )
}

export default RegistrationEventAnalytics