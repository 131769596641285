import React from "react"
import { Card, CardBody, Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import RegistrationEventTable from "./table/RegistrationEventTable";
import RegistrationEventAnalytics from "./component/RegistrationEventAnalytics";

const RegistrationEvents = props => {

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={"Sự kiện"}
                        breadcrumbItem={"Danh sách tham gia sự kiện"}
                    />
                    <RegistrationEventAnalytics />
                    <Card>
                        <CardBody>
                            <RegistrationEventTable />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default RegistrationEvents
