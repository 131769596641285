import React, { useEffect } from 'react';
import { useState } from "react";
import {
    Button,
    Card,
    Col,
    Label,
    Row,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    ModalFooter,
    Form
} from "reactstrap";
import classnames from "classnames"
import * as UserType from "constants/UserType"
import { City, District } from "helpers/ProvinceUtil"
import { Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as ApiHelper from '../../../api/api_helper'
import { error } from "helpers/notification"
import AudienceModel from 'api/AudienceModel';
import AudienceType from 'constants/AudienceType';


const FormAudienceModal = (props) => {
    const [validation, setValidation] = useState({})
    const [modal, setModal] = useState(false);
    const [form, setForm] = useState({
        type: 1
    })

    useEffect(() => {
        setModal(props.open)
    }, [props])

    useEffect(() => {
        let id = props.value;
        if (modal && id) {
            get(id)
        }
    }, [modal])

    const isValid = () => {
        if (!form.alias) {
            setValidation({ ...error, uids: "Vui lòng nhập alias" })
            return false;
        }
        if (form.type == 2 && (!form.uids || form.uids.length <= 0)) {
            setValidation({ ...error, uids: "Vui lòng upload số điện thoại" })
            return false;
        }
        return true;
    }

    const submit = async () => {
        if (!isValid()) {
            return;
        }
        let finalForm = {};
        if (form.type == AudienceType.SECTOR) {
            finalForm = { ...form, uids: undefined }
        } else {
            finalForm = {
                id: form.id, type: AudienceType.UID_SETS,
                uids: form.uids, alias: form.alias
            }
        }

        let result = await AudienceModel.createOrUpdate(finalForm)
        if (result.error != 0) {
            error(result.msg)
            return;
        }

        props.toggle(false)
        props.onSubmitSuccess(result.data.id)
        setModal(false)
    }

    const get = (id) => {
        AudienceModel.get(id)
            .then(payload => {
                if (payload.error < 0) {
                    error(payload.msg)
                    return;
                }
                setForm(payload.data)
            })
    }

    return (
        <>
            <Modal
                isOpen={modal}
            >
                <ModalHeader tag="h4">
                    Audience
                </ModalHeader>
                <ModalBody className='pt-0'>
                    <Card>
                        <ul
                            className="nav nav-tabs nav-tabs-custom pt-2"
                            role="tablist"
                        >
                            <NavItem>
                                <NavLink
                                    to="#"
                                    className={classnames({
                                        active: form.type === 1,
                                    })}
                                    onClick={() => {
                                        setForm({ ...form, type: 1 })
                                    }}
                                >
                                    Khu vực
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to="#"
                                    className={classnames({
                                        active: form.type === 2,
                                    })}
                                    onClick={() => {
                                        setForm({ ...form, type: 2 })
                                    }}
                                >
                                    Số điện thoại
                                </NavLink>
                            </NavItem>
                        </ul>
                        <TabContent className="p-4" activeTab={form.type}>
                            <TabPane tabId={1}>
                                <Form>
                                    <Row>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label htmlFor="promotion">Alias</Label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultChecked={form.alias}
                                                    onChange={(e) => {
                                                        setForm({ ...form, alias: e.target.value })
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label htmlFor="roleIds">Đối tượng?</Label>
                                                <select
                                                    value={form.roleIds ? form.roleIds[0] : 0}
                                                    className="form-control"
                                                    onChange={e => {
                                                        setForm({ ...form, roleIds: [e.target.value] })
                                                    }}
                                                >
                                                    <option value={0}>Tất cả</option>
                                                    <option value={UserType.CONSTRUCTOR}>
                                                        {UserType.findName(UserType.CONSTRUCTOR)}
                                                    </option>
                                                    <option value={UserType.RETAILER}>
                                                        {UserType.findName(UserType.RETAILER)}
                                                    </option>
                                                    <option value={UserType.DISTRIBUTOR}>
                                                        {UserType.findName(UserType.DISTRIBUTOR)}
                                                    </option>
                                                    <option value={UserType.INDUSTRIAL_CUSTOMER}>
                                                        {UserType.findName(UserType.INDUSTRIAL_CUSTOMER)}
                                                    </option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="mb-3 templating-select select2-container">
                                                <label className="control-label">Tỉnh /Thành Phố</label>
                                                <Select
                                                    mode="multiple"
                                                    size={10}
                                                    placeholder="Vui lòng chọn tỉnh thành"
                                                    value={City.getOptionsByIds(form.cityIds || [])}
                                                    onChange={(value) => {
                                                        setForm({ ...form, cityIds: value.map(str => Number(str)) })
                                                    }}
                                                    style={{ width: '100%' }}
                                                    options={City.getOptions()}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="mb-3 templating-select select2-container">
                                                <label className="control-label">Quận/huyện</label>
                                                <Select
                                                    mode="multiple"
                                                    size={10}
                                                    placeholder="Vui lòng chọn quận huyện"
                                                    value={form.districtIds || []}
                                                    onChange={(value) => {
                                                        setForm({ ...form, districtIds: value.map(str => Number(str)) })
                                                    }}
                                                    style={{ width: '100%' }}
                                                    options={District.getOption(form.cityIds || []) || []}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </TabPane>
                            <TabPane tabId={2}>
                                <Form>
                                    <Row>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label htmlFor="promotion">Alias</Label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultChecked={form.alias}
                                                    onChange={(e) => {
                                                        setForm({ ...form, alias: e.target.value })
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <span>Format File: SDT | Tài khoản</span>
                                            <ul>
                                                <li>Nhà thầu</li>
                                                <li>Cửa hàng</li>
                                                <li>Nhà phân phối</li>
                                                <li>Khách hàng công nghiệp</li>
                                            </ul>
                                        </Col>
                                        <Col sm={12} className='text-center'>
                                            <Upload
                                                action={`${ApiHelper.getApiUrl()}audience/upload-phone-to-uid`}
                                                name='file'
                                                maxCount={1}
                                                withCredentials={true}
                                                onChange={(info) => {
                                                    if (info.file.status === 'done') {
                                                        if (info?.file?.response?.error == 0) {
                                                            const data = info?.file?.response?.data;
                                                            if (data && data[0]) {
                                                                setForm({ ...form, uids: data[0].uids })
                                                            }
                                                        } else {
                                                            error(`${info.file.name} file upload failed.`)
                                                        }
                                                    } else if (info.file.status === 'error') {
                                                        error(`${info.file.name} file upload failed.`)
                                                    }
                                                }}>
                                                <Button onClick={() => {

                                                }} icon={<UploadOutlined />}>Chọn File</Button>
                                            </Upload>
                                        </Col>
                                        <Col pt={20} sm={12}>
                                            <Label>Nội dung File</Label>
                                            <p>{form?.uids?.length || 0} Uses</p>
                                        </Col>
                                    </Row>
                                </Form>
                            </TabPane>
                        </TabContent>
                    </Card>
                    <ModalFooter>
                        <Button
                            onClick={() => { props.toggle(false) }}
                            type="button"
                            color="secondary"
                        >
                            Đóng
                        </Button>
                        <Button
                            type="button"
                            color="success"
                            onClick={() => { submit() }}
                        >
                            Chọn
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </>
    )
}

export default FormAudienceModal;