import React, { useEffect, useMemo, useState } from "react";
import ConversationStatus from "constants/ConversationStatus";
import OAChatModel from "api/chat/OAChatModel";
import NotificationModel from "api/chat/NotificationModel";
import { error, success } from "helpers/notification";
import { Button, Card, Col, Row, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Popconfirm, Skeleton, Spin } from "antd";
import { isEmpty, map } from "lodash";
import moment from 'moment';


const ChatBox = ({ conversation }) => {
    const user = conversation?.user;

    const statusClass = useMemo(() => {
        switch (conversation?.status) {
            case ConversationStatus.INIT: {
                return 'mdi mdi-circle text-success align-middle me-1';
            }
            case ConversationStatus.PROCESSING: {
                return 'mdi mdi-circle text-warning align-middle me-1';
            }
            case ConversationStatus.DONE: {
                return 'mdi mdi-circle align-middle me-1';
            }
        }
    }, [conversation])


    return (
        <>
            <Card style={{ marginBottom: '0px' }}>
                <div className="p-4 border-bottom ">
                    <Row>
                        <Col md="4" xs="9">
                            <h5 className="font-size-15 mb-1">
                                {user?.name || "Bắt đầu"}
                            </h5>

                            {conversation?.status != null && (
                                <p className="text-muted mb-0">
                                    <i
                                        className={statusClass}
                                    />
                                    {ConversationStatus.findName(
                                        conversation?.status
                                    )}
                                </p>
                            )}
                        </Col>
                    </Row>
                </div>

                <div>
                    <MessageBox oaid={conversation?.oaid} followerId={conversation?.followerId} />
                </div>
            </Card>
        </>
    )
}

export default ChatBox;


const MessageBox = ({ oaid, followerId }) => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ref, setRef] = useState();
    const [moveBottom, setMoveBottom] = useState(false)

    const fetch = async (offset, expand) => {
        if (!loading) {
            setLoading(true)
            let result = await OAChatModel.findMessage(oaid, followerId, offset, 10);
            if (result.error == 0 && result.data.list) {
                let list = result.data.list;
                list.sort((mess1, mess2) => {
                    return mess1.time - mess2.time;
                })

                if (expand) {
                    setMessages([...list, ...messages])
                } else {
                    setMessages(list);
                }
            }
            setLoading(false)
        }
    }

    const handleScroll = () => {
        if (ref.scrollTop < 100 && !loading) {
            fetch(messages.length, true)
        }
    };

    useEffect(() => {
        if (oaid, followerId) {
            fetch(1, false)
        }
    }, [oaid, followerId])


    useEffect(() => {
        if (!isEmpty(messages) && ref && !moveBottom) {
            ref.scrollTop = ref.scrollHeight + 1000
            setMoveBottom(true)
        }
    }, [messages])

    useEffect(() => {
        setMoveBottom(false)
    }, [oaid, followerId])

    return (
        <div className="chat-conversation p-3">
            <ul className="list-unstyled">
                <PerfectScrollbar
                    id="chatMessageDiv"
                    style={{ height: 'calc(100vh - 94px - 43px - 100px - 32px - 10px - 70px - 70px - 32px - 16px)' }}
                    containerRef={ref => (setRef(ref))}
                    onScroll={handleScroll}
                >
                    {loading &&
                        <li>
                            <div className="text-center">
                                <Spin />
                            </div>
                        </li>
                    }
                    {messages &&
                        map(messages, item => (
                            <li
                                key={"test_k" + item.id}
                                className={item.src == 1 ? "" : "right"}
                            >
                                <div className="conversation-list">
                                    <div className="ctext-wrap">
                                        <div className="conversation-name">
                                            {item.fromDisplayName}
                                        </div>
                                        <p>{item.message || '[Rich Mesage] Không hổ trợ'}</p>
                                        <p className="chat-time mb-0">
                                            <i className="bx bx-time-five align-middle me-1" />
                                            {moment(item.time).format(
                                                "HH:mm DD-MM-YYYY"
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        ))}
                </PerfectScrollbar>
            </ul>
        </div>
    )
}

const StatusButton = ({ conversation, onUpdate }) => {
    let status = conversation?.status;

    const label = useMemo(() => {
        switch (conversation?.status) {
            case ConversationStatus.INIT: {
                return 'Xác nhận';
            }
            case ConversationStatus.PROCESSING: {
                return 'Hoàn thành';
            }
        }
        return ''
    }, [conversation?.status])

    return (
        <>
            {status > 0 && status != ConversationStatus.DONE &&
                <Popconfirm
                    title="Bạn có chắc?"
                    onConfirm={() => {
                        if (status == ConversationStatus.INIT) {
                            onUpdate(ConversationStatus.PROCESSING)
                            return;
                        }
                        if (status == ConversationStatus.PROCESSING) {
                            onUpdate(ConversationStatus.DONE)
                            return;
                        }
                    }}
                >
                    <Button
                        type="button"
                        color="primary"
                        className="bg-transparent btn btn-primary btn-rounded chat-send w-md "
                    >
                        <span style={{ color: '#485ec4' }}>{label}</span>
                    </Button>
                </Popconfirm>
            }
        </>
    )
} 