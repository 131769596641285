import React from "react"
import { Card, CardBody, Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableLeaderBoad from "./table/TableLeaderBoad";

const LeaderBoad = props => {

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid><Breadcrumbs
                    title={"Khuyến mãi"}
                    breadcrumbItem="Lịch thi đấu"
                />
                    <Card>
                        <CardBody>
                            <TableLeaderBoad promotionId={props.match.params.id} />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default LeaderBoad
