class GiftOrderType {
    static LUCKY_WHEEL_GIFT_ORDER = 1;
    static REGISTER_LOYALTY_RTL_TYPE = 41;
    static INVOICE_RTL_TYPE = 42;
    static INVOICE_CTR_TYPE = 43;
    static PREDICT_FOOTBALL_TYPE = 51;

    static findName(value) {
        switch (value) {
            case this.LUCKY_WHEEL_GIFT_ORDER: return 'LUCKY_WHEEL_GIFT_ORDER'
        }
    }

    static findColor(value) {
        switch (value) {
        }
    }
}

export default GiftOrderType;