export const HAS_PROMOTION = 1;
export const NO_PROMOTION = 2;

export function findName(value) {
    // eslint-disable-next-line default-case
    switch (value) {
        case HAS_PROMOTION: return 'Có'
        case NO_PROMOTION: return 'Không'
    }
}

export function findColor(value) {
    // eslint-disable-next-line default-case
    switch (value) {
        case HAS_PROMOTION: return '#20c997'
        case NO_PROMOTION: return '#dc3535'
    }
}

export class CategoryPost {
    static NEWS = 1;
    static TIPS_AND_ADVICE = 2;
    static PROMOTION = 3;
}