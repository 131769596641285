import React, { useState } from "react"
import {
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
} from "reactstrap"
import classnames from "classnames"


//Import Breadcrumb
import TableOrder from "./table/TableOrder"

//redux

const Order = props => {
    const [config, setConfig] = useState({
        status: 0,
    })


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody style={{ padding: '5px' }}>
                                    <Nav pills>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: config.status === 0
                                                })}
                                                onClick={() => {
                                                    setConfig({ status: 0 })
                                                }}
                                                style={{ position: "relative", marginRight: '20px' }}
                                            >
                                                <i className="bx bx-chat font-size-20 d-sm-none" />
                                                <span className="d-none d-sm-block">Tất cả</span>
                                            </NavLink>
                                        </NavItem>

                                        {/* {topics && topics.map(item => (
                                            <NavItem key={item.id}>
                                                <NavLink
                                                    className={classnames({
                                                        active: config.topicId === item.id,
                                                    })}
                                                    onClick={() => {
                                                        setConfig({ ...config, topicId: item.id })
                                                    }}
                                                    style={{ position: "relative", marginRight: '20px' }}
                                                >
                                                    <i className="bx bx-group font-size-20 d-sm-none" />
                                                    <span className="d-none d-sm-block">{item.name}</span>
                                                    {item.count > 0 && (
                                                        <span className="button__badge">
                                                            {item.count}
                                                        </span>
                                                    )}
                                                </NavLink>
                                            </NavItem>
                                        ))} */}
                                    </Nav>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <TableOrder filter={{ status: config.status }} />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Order;



