export default class LinkUtil {

    static initParam(searchParams) {
        var q = searchParams?.get("q");
        return JSON.parse(q ? q : '{}')
    }

    static initParamWithDefault(searchParams, dataDefault) {
        var q = searchParams?.get("q");
        return JSON.parse(q ? q : JSON.stringify(dataDefault))
    }

    static convert2Array(param) {
        return JSON.parse("[" + param + "]");
    }


}