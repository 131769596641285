import * as ApiHelper from './api_helper'

class UserConstructionModel {
    async getById(id) {
        return ApiHelper.get(`user-construction/get?id=${id}`)
    }

    async find(constructionId, page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                constructionId,
                page: page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`user-construction/find?${new URLSearchParams(params).toString()}`)
    }

    async export() {
        window.open(ApiHelper.getApiUrl() +
        `user-construction/export`, '_blank');
    }


}

export default new UserConstructionModel();
