import Pages403 from "pages/error/403"
import Pages404 from "pages/error/404"
import React from "react"

// Dashboard
import Dashboard from "../pages/dashboard/index"
import AnalyticMembers from "../pages/members/analaytics"
import HistoryMember from "pages/members/history"
import ProfileMember from "pages/members/profile"
import AnalyticPromotion from "pages/promotions/analaytics"
import Campaigns from "pages/promotions/campaigns"
import ConstructorMember from "pages/members/contructor"
import RetailerMember from "pages/members/retailer"
import Articles from "pages/articles"
import CreateArticles from "pages/articles/create"
import EditArticles from "pages/articles/edit"
import CreatePromotion from "pages/promotions/create"
import EditPromotion from "pages/promotions/edit"
import Forms from "pages/forms"
import DetailForm from "pages/forms/detail"
import Broadcast from "pages/broadcast"
import CreateBroadcast from "pages/broadcast/create"
import DetailBroadcast from "pages/broadcast/detail"
import Message from "pages/message"
import Matchs from "pages/promotions/matchs"
import CreateMatch from "pages/promotions/matchs/create"
import EditMatch from "pages/promotions/matchs/edit"
import Gifts from "pages/gifts"
import CreateGift from "pages/gifts/create"
import GiftOrders from "pages/gift-order"
import Accumulation from "pages/accumulation"
import LeaderBoad from "pages/promotions/leaderboard"
import DistributorMember from "pages/members/distributor"
import IndustrialMember from "pages/members/industrial"
import Survey from "pages/survey"
import ListFormSurvey from "pages/survey/detail"
import Constructions from "pages/construction"
import DetailConstruction from "pages/construction/detail"
import Audience from "pages/audience"
import DetailAudience from "pages/audience/detail"
import Program from "pages/program"
import Point from "pages/point"
import ConstructorRetailer from "pages/constructor-retailer"
import LoyaltyInvoiceFormsPage from "pages/forms/loyalty-invoice"
import DetailGiftOrderForm from "pages/gift-order/detail"
import DetailMessage from "pages/message/detail"
import Order from "pages/order"
import OrderDetail from "pages/order/detail"
import RegistrationEvents from "pages/registration-event"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // eslint-disable-next-line react/display-name
  { path: "/members/analytic", component: AnalyticMembers },
  { path: "/members/history", component: HistoryMember },
  { path: "/members/constructors", component: ConstructorMember },
  { path: "/members/retailers", component: RetailerMember },
  { path: "/members/distributors", component: DistributorMember },
  { path: "/members/industrials", component: IndustrialMember },
  { path: "/members/profile/:id", component: ProfileMember },

  { path: "/articles/create", component: CreateArticles },
  { path: "/articles/:id/edit", component: CreateArticles },
  { path: "/articles", exact: true, component: Articles },

  { path: "/promotions/analytic", component: AnalyticPromotion },
  { path: "/promotions/campaigns", component: Campaigns },
  { path: "/promotions/create", component: CreatePromotion },
  { path: "/promotions/edit/:id", component: CreatePromotion },
  { path: "/promotions/matchs/leaderboard/:id", component: LeaderBoad },
  { path: "/promotions/matchs/create", component: CreateMatch },
  { path: "/promotions/matchs/edit/:id", component: EditMatch },
  { path: "/promotions/matchs/:id", component: Matchs },
  { path: "/promotions/forms", exact: true, component: Forms },
  { path: "/promotions/forms/:id", component: DetailForm },

  { path: "/constructions", exact: true, component: Constructions },
  { path: "/constructions/:id", component: DetailConstruction },

  { path: "/broadcasts/create", component: CreateBroadcast },
  { path: "/broadcasts/detail/:id", component: DetailBroadcast },
  { path: "/broadcasts", exact: true, component: Broadcast },

  { path: "/surveys", expect: true, component: Survey },
  { path: "/survey/detail", component: ListFormSurvey },

  { path: "/audiences", exact: true, component: Audience },
  { path: "/audience/:audienceId", component: DetailAudience },

  { path: "/message", component: Message },
  { path: "/message/:id", component: DetailMessage },

  { path: "/gifts/create", exact: true, component: CreateGift },
  { path: "/gifts", exact: true, component: Gifts },
  { path: "/gifts/orders", exact: true, component: GiftOrders },
  { path: "/gifts/orders/:id", component: DetailGiftOrderForm },
  { path: "/gift-order/:promotionId", component: GiftOrders },
  { path: "/accumulation/:promotionId", component: Accumulation },

  { path: "/event/registrations", component: RegistrationEvents },

  { path: "/program/:promotionId", component: Program },

  { path: "/stores", component: ConstructorRetailer },

  { path: "/loyalty-invoice/forms", component: LoyaltyInvoiceFormsPage },

  { path: "/point", component: Point },

  { path: "/order/:id", component: OrderDetail },
  { path: "/orders", component: Order },

  { path: "/", exact: true, component: Dashboard },
  { path: "/*", component: Dashboard },
]

const publicRoutes = [
  { path: "/error/403", component: Pages403 },
  { path: "/error/404", component: Pages404 },
]

export { publicRoutes, authProtectedRoutes }
