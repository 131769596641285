import * as ApiHelper from './api_helper'

class FormModel {
    async getForms({ search, status, type, userId, cityId, promotionId, matchId, start, end, page, pageSize }) {
        let params = Object.fromEntries(
            Object.entries({
                search, status, type, userId, cityId, promotionId, matchId, start, end, page: page - 1, pageSize
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`form/find?${new URLSearchParams(params).toString()}`)
    }

    async getPredictFootballForms({ roleId, search, status, type, userId, cityId, matchId, start, end, page, pageSize }) {
        let params = Object.fromEntries(
            Object.entries({
                roleId, search, status, type, userId, cityId, matchId, start, end, page: page - 1, pageSize
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`predict-football-form/find?${new URLSearchParams(params).toString()}`)
    }

    async countPredictForm({ roleId, search, status, type, userId, cityId, promotionId, matchId, start, end }) {
        let params = Object.fromEntries(
            Object.entries({
                roleId, search, status, type, userId, cityId, promotionId, matchId, start, end
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`predict-football-form/count-v2?${new URLSearchParams(params).toString()}`)
    }

    async getForm(formId) {
        return ApiHelper.get(`form/get?id=${formId}`)
    }

    async updateStatus(id, status, note) {
        return ApiHelper.post2(`form/update-status?id=${id}`, { status, note });
    }

    async countByStatus(promotionId, status, start, end) {
        // return ApiHelper.get(`form/count?status=${status}`);
        let params = Object.fromEntries(
            Object.entries({
                promotionId, status, start, end
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`form/count?${new URLSearchParams(params).toString()}`);
    }

    async countByPromotionIdAndMatchIdAndStatus(promotionId, matchId, status) {
        let params = Object.fromEntries(
            Object.entries({
                status, promotionId, matchId
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`form/count?${new URLSearchParams(params).toString()}`);
    }

    async sumBag(status, start, end) {
        let params = Object.fromEntries(
            Object.entries({
                status, start, end
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`form/sum-bag?${new URLSearchParams(params).toString()}`);
    }

    async statsFormDate(start, end) {
        return ApiHelper.get(`form/stats-form-date?start=${start}&end=${end}`)
    }

    async countUserNotEngagedForm(start, end) {
        // count - user - not - engaged - form
        return ApiHelper.get(`form/count-user-not-engaged-form?start=${start}&end=${end}`)
    }

    async getTopPromotion(start, end) {
        return ApiHelper.get(`form/stats-form-promotion?start=${start}&end=${end}`)
    }

    async statsBagCementUser(userId) {
        return ApiHelper.get(`form/stats-bags-cement-user?userId=${userId}`)
    }

    async statsBagCement() {
        return ApiHelper.get(`form/stats-bags-cement`)
    }

    async export(status, type, userId, promotionId, matchId, search, cityId, start, end) {
        let params = Object.fromEntries(
            Object.entries({
                status, type, userId, promotionId, matchId, search, cityId, start, end
            }).filter(([_, v]) => v != null && v != 0)
        );
        window.open(ApiHelper.getApiUrl() +
            `form/export-excel?${new URLSearchParams(params).toString()}`, '_blank');
        return;
    }

    async export_predict_football({ roleId, search, status, cityId, type, userId, matchId, start, end }) {
        let params = Object.fromEntries(
            Object.entries({
                status, type, userId, matchId, cityId, roleId, search, start, end
            }).filter(([_, v]) => v != null && v != 0)
        );
        window.open(ApiHelper.getApiUrl() +
            `predict-football-form/export-excel?${new URLSearchParams(params).toString()}`, '_blank');
        return;
    }

    async count({ search, status, type, userId, cityId, promotionId, matchId, start, end }) {
        let params = Object.fromEntries(
            Object.entries({
                search, status, type, userId, cityId, promotionId, matchId, start, end
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`form/count-v2?${new URLSearchParams(params).toString()}`)
    }

    async sumBagV2({ search, status, type, userId, cityId, promotionId, matchId, start, end }) {
        let params = Object.fromEntries(
            Object.entries({
                search, status, type, userId, cityId, promotionId, matchId, start, end
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`form/sum-bag-v2?${new URLSearchParams(params).toString()}`);
    }
}

export default new FormModel();
