class GiftOrderStatus {
    static INIT = 1;
    static APPROVED = 2;
    static REJECTED = 3;
    static SEND = 4;
    static RECEIVED = 5;

    static findName(value) {
        switch (value) {
            case this.INIT: return 'Chờ duyệt';
            case this.APPROVED: return 'Chờ gửi'
            case this.REJECTED: return 'Đã từ chối'
            case this.SEND: return "Đã gửi quà"
            case this.RECEIVED: return "Đã nhận quà"
        }
    }

    static findNameForLuckyWheel(value) {
        switch (value) {
            case this.INIT: return "Chờ quay"
            case this.APPROVED: return 'Đã nhận'
            case this.REJECTED: return 'Đã từ chối'
            case this.SEND: return "Đã gửi quà"
            case this.RECEIVED: return "Đã nhận quà"
        }
    }

    static findNameForPredictFootball(value) {
        switch (value) {
            case this.INIT: return 'Chờ kết quả'
            case this.APPROVED: return 'Dự đoán chính xác'
            case this.REJECTED: return 'Dự đoán không chính xác'
            case this.SENT_GIFT: return "Đã gửi quà"
            case this.RECEIVED: return "Đã nhận quà"
        }
    }

    static findColor(value) {
        switch (value) {
            case this.INIT: return '#8bc34a'
            case this.RECEIVED: return '#6f42c1'
            case this.SENT_GIFT: return '#ffc107'
            case this.APPROVED: return '#28a745'
            case this.REJECTED: return '#9e9e9e'
        }
    }
}

export default GiftOrderStatus;