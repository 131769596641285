import React, { useState } from "react"
import { Modal, ModalHeader, ModalFooter, ModalBody, Input, Label } from "reactstrap"
import { DatePicker } from "antd"
import moment from "moment"

const BroadcastModal = props => {
    const [start_time, set_start_time] = useState(moment())
    const handleSubmit = () => {
        props.handleSubmit(start_time.toDate().getTime())
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
            <ModalHeader className="text-center">{props.title}</ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    <Label htmlFor="timeStart">
                        Thời gian gửi?
                    </Label>
                    <br />

                    <DatePicker
                        format="YYYY-MM-DD HH:mm:ss"
                        value={start_time}
                        showTime={{
                            defaultValue: moment('00:00:00', 'HH:mm:ss'),
                        }}
                        onChange={data => set_start_time(data)}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={props.toggle}
                    className="btn btn-secondary btn-sm"
                    data-dismiss="modal"
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmit}
                >
                    Confirm
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default BroadcastModal;