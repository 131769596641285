// TableMember

import { Avatar, Button, Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import TimeUtils from "helpers/time.utils"
import { useHistory } from "react-router-dom"
import {
    Col,
} from "reactstrap"
import { Row } from "reactstrap"
import queryString from 'query-string'
import ConversationStatus from "constants/ConversationStatus"
import CementOrderModel from "api/order/CementOrderModel"
import CementOrderStatus from "constants/CementOrderStatus"
import { CurrencyUtils } from "helpers"


// TableNotification
const TableOrder = props => {
    const { status } = props?.filter;
    let history = useHistory();

    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "",
            dataIndex: "user",
            render: (user) => {
                const { avatar } = user;
                return (
                    <>
                        <Avatar src={avatar} size="large" />
                    </>
                )
            }
        },
        {
            title: "Cửa hàng",
            dataIndex: "user",
            render: (user) => user.name
        },
        {
            title: "Nhà Phân Phối",
            dataIndex: "distributor",
            render: (distributor) => distributor.name
        },
        {
            title: "Giá trị",
            dataIndex: "price",
            render: price => <span style={{color : 'red'}}>{CurrencyUtils.formatVND(price)}</span>
        },
        {
            title: "Thời gian",
            dataIndex: "createdTime",
            render: createdTime => TimeUtils.format(createdTime * 1000, "HH:mm - DD/MM/YYYY")

        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: status => {
                const text = CementOrderStatus.findNameByStatus(status);
                const bgColor = CementOrderStatus.findBgColorByStatus(status);
                return (
                    <Tag style={{backgroundColor: `${bgColor}`, color: '#fff'}} key={status}>
                        {text}
                    </Tag>
                )
            },
        },
        {
            title: "Hành động",
            render: (_, record) => (
                <>
                    <Button type="primary" size="small" style={{ width: "100%" }}
                        onClick={() => history.push(`/order/${record.id}`)} >
                        Chi tiết
                    </Button>

                </>
            ),
        },
    ]

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)

    const [filter, setFilter] = useState({
        current: 1,
        pageSize: 10,
        status,
        ...queryString.parseUrl(history.location.search).query
    })

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.replace({ search: url })
    };

    const fetchData = async () => {
        setLoading(true)
        let results = await CementOrderModel.find(
            filter.status,
            filter.current,
            filter.pageSize
        )
        setData(results?.data)
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
        serialize2Url()
    }, [filter])

    useEffect(() => {
        setFilter({ ...filter, status })
    }, [status])

    const handleTableChange = (newPagination, filters, sorter) => {
        setFilter({ ...filter, newPagination })
    }


    return (
        <>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data?.list || []}
                pagination={{ ...filter, total: (data?.totalPage * data?.pageSize || 0) }}
                loading={loading}
                onChange={handleTableChange}
            />

        </>
    )
}

export default TableOrder
