import BroadcastModel from "api/BroadcastModel"
import { error, success } from "helpers/notification"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TimeUtils from "helpers/time.utils"
import BroadcastType from "constants/BroadcastType"
import BroadcastStatus from "constants/BroadcastStatus"
import { Tag } from "antd"
import { PopupConfirm, PopupConfirmWithNote } from "components/pop-confirm"

const DetailBroadcast = props => {
    const [detail, setDetail] = useState({})
    const [approvedModal, setApprovedModal] = useState(false)
    const [rejectedModal, setRejectedModal] = useState(false)
    const [previewModal, setPreviewModal] = useState(false)

    const getBroadcast = async () => {
        let result = await BroadcastModel.get(props.match.params.id)
        if (result.error != 0) {
            error("Lỗi, vui lòng lại trang!")
            return
        }
        setDetail(result.data)
    }

    const updateStatus = async function (status) {
        setApprovedModal(false)
        setRejectedModal(false)
        let result = await BroadcastModel.updateStatus(props.match.params.id, status);
        if (result.error != 0) {
            error("Lỗi, vui lòng thử lại!")
            return;
        }
        success("Cập nhập thành công")
        getBroadcast();
    }

    const preview = async function (phonesStr) {
        setPreviewModal(false)
        let phones = phonesStr.split(',');
        let result = await BroadcastModel.preview(props.match.params.id, phones);
        if (result.error != 0) {
            error("Lỗi, vui lòng thử lại!")
            return;
        }
        success("Gửi thành công thành công")
    }

    useEffect(() => {
        getBroadcast()
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Broadcast"} breadcrumbItem="Chi tiết" />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm="6">
                                    <div className="table-responsive">
                                        <Table className="table-nowrap mb-0">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">ID :</th>
                                                    <td>{detail?.id}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Broadcast :</th>
                                                    <td>{detail?.name}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Thời gian gửi :</th>
                                                    <td>{TimeUtils.format(detail?.timeStart, "HH:mm - DD/MM/YYYY")}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Loaị broadcast :</th>
                                                    <td>{BroadcastType.findName(detail?.type)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Nội dung:</th>
                                                    <td>{JSON.stringify(detail?.content, null, 2)}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="table-responsive">
                                        <Table className="table-nowrap mb-0">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Trạng thái :</th>
                                                    <td>
                                                        <Tag
                                                            color={BroadcastStatus.findColor(detail?.status)}
                                                        >
                                                            {BroadcastStatus.findName(detail?.status)}
                                                        </Tag>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Tât cả :</th>
                                                    <td>{detail?.total}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Số lượt gửi thành công:</th>
                                                    <td>{detail?.countPush}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Số lượt gửi thất bại:</th>
                                                    <td>{detail?.failed}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Số lượt xem :</th>
                                                    <td>{detail?.countView}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3" style={{ justifyContent: "end" }}>
                                <Col sm={4}>
                                    {detail.status === BroadcastStatus.INIT
                                        && <>
                                            <Button
                                                color="primary"
                                                className="btn btn-primary "
                                                style={{ marginLeft: "10px" }}
                                                onClick={() => setApprovedModal(true)}
                                            >
                                                Duyệt
                                            </Button>
                                            <Button
                                                color="secondary"
                                                className="btn btn-secondary "
                                                style={{ marginLeft: "10px" }}
                                                onClick={() => setRejectedModal(true)}
                                            >
                                                Từ chối
                                            </Button>
                                            <Button
                                                color="success"
                                                className="btn btn-success "
                                                style={{ marginLeft: "10px" }}
                                                onClick={() => setPreviewModal(true)}
                                            >
                                                Xem trước
                                            </Button>
                                        </>}

                                    {/* <Button
                                        color="info"
                                        className="btn btn-info "
                                    >
                                        Info
                                    </Button>
                                    <Button
                                        color="warning"
                                        className="btn btn-warning "
                                    >
                                        Warning
                                    </Button>
                                    <Button
                                        color="danger"
                                        className="btn btn-danger "
                                    >
                                        Danger
                                    </Button> */}
                                </Col>
                                {approvedModal && (
                                    <PopupConfirm
                                        isOpen={approvedModal}
                                        toggle={() => setApprovedModal(false)}
                                        handleSubmit={() => updateStatus(BroadcastStatus.APPROVED)}
                                        title="Bạn có muốn duyêt broadcast này?"
                                        content={{ id: detail.id, name: detail?.name }}
                                    />
                                )}
                                {rejectedModal && (
                                    <PopupConfirm
                                        isOpen={rejectedModal}
                                        toggle={() => setRejectedModal(false)}
                                        handleSubmit={() => updateStatus(BroadcastStatus.CANCELED)}
                                        title="Bạn có muốn hủy broadcast này?"
                                        content={{ id: detail.id, name: detail?.name }}
                                    />
                                )}
                                {previewModal && (
                                    <PopupConfirmWithNote
                                        isOpen={previewModal}
                                        toggle={() => setPreviewModal(false)}
                                        handleSubmit={note => preview(note)}
                                        title="Nhập danh sách số điện thoại nhận(cách nhau dấu ',')?"
                                    />
                                )}
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DetailBroadcast
