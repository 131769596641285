import React, { useState } from "react"
import { Modal, ModalHeader, ModalFooter, ModalBody, Input } from "reactstrap"

export const PopupConfirm = props => {
    const handleSubmit = () => {
        props.handleSubmit()
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
            <ModalHeader className="text-center">{props.title}</ModalHeader>
            {props.content && (
                <ModalBody>
                    {typeof props.content === 'string' &&
                        <p>
                            {props.content}
                        </p>
                    }
                    {typeof props.content === 'object' && Object.keys(props.content).map(key => (
                        <p key={key}>
                            <span style={{ fontWeight: 'bold' }}>{key}: </span>{props.content[key]}
                        </p>
                    ))}
                </ModalBody>
            )}
            <ModalFooter>
                <button
                    type="button"
                    onClick={props.toggle}
                    className="btn btn-secondary btn-sm"
                    data-dismiss="modal"
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmit}
                >
                    Confirm
                </button>
            </ModalFooter>
        </Modal>
    )
}

export const PopupConfirmWithNote = props => {

    const [note, setNote] = useState("")

    const handleSubmit = () => {
        props.handleSubmit(note)
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
            <ModalHeader className="text-center">{props.title}</ModalHeader>
            <ModalBody>
                <Input type="textarea" onChange={e => setNote(e.target.value)} />
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={props.toggle}
                    className="btn btn-secondary btn-sm"
                    data-dismiss="modal"
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmit}
                >
                    Confirm
                </button>
            </ModalFooter>
        </Modal>
    )
}
