import * as ApiHelper from './api_helper'

class AudienceModel {

    async createOrUpdate(form) {
        let data = Object.fromEntries(Object.entries({ ...form }).filter(([_, v]) => v != null && v != 0));
        return ApiHelper.post2(`audience/create-or-update`, data);
    }

    async multiCreate(forms) {
        return ApiHelper.postArray(`audience/multi-create`, forms);
    }

    async get(id) {
        return ApiHelper.get(`audience/get?id=${id}`);
    }

    async find(page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                page: page - 1, pageSize
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`audience/find?${new URLSearchParams(params).toString()}`)
    }

    async search(search) {
        return ApiHelper.get(`audience/search?search=${encodeURIComponent(search)}`)
    }
}

export default new AudienceModel();
