import * as ApiHelper from './api_helper'

class AccumulationModel {

    async find({ cityId, search, promotionId, page, pageSize }) {
        let params = Object.fromEntries(
            Object.entries({
                search,
                cityId,
                promotionId,
                page: page - 1,
                pageSize
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`normal-accumulation/find?${new URLSearchParams(params).toString()}`)
    }

    async findLoyalty({ cityId, search, promotionId, page, pageSize }) {
        let params = Object.fromEntries(
            Object.entries({
                search,
                cityId,
                promotionId,
                page: page - 1,
                pageSize
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`loyalty-accumulation/find?${new URLSearchParams(params).toString()}`)
    }


    async leaderboard({ cityId, roleId, search, promotionId, page, pageSize }) {
        let params = Object.fromEntries(
            Object.entries({
                promotionId, cityId, roleId, search,
                page: page - 1, pageSize
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`predict-football-accumulation/leaderboard?${new URLSearchParams(params).toString()}`)
    }

    async export_leader_broad({ cityId, roleId, search, promotionId }) {
        let params = Object.fromEntries(
            Object.entries({
                promotionId, cityId, roleId, search,
            }).filter(([_, v]) => v != null && v != 0)
        );
        window.open(ApiHelper.getApiUrl() +
            `predict-football-accumulation/export-excel?${new URLSearchParams(params).toString()}`, '_blank');
        return;
    }

    async export({ cityId, search, promotionId }) {
        let params = Object.fromEntries(
            Object.entries({
                cityId, search, promotionId
            }).filter(([_, v]) => v != null && v != 0)
        );
        window.open(ApiHelper.getApiUrl() +
            `normal-accumulation/export-excel?${new URLSearchParams(params).toString()}`, '_blank');
        return;
    }

}

export default new AccumulationModel();
