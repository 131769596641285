import * as ApiHelper from '../api_helper'


class AsFastAsLightingFormModel {
    async find({ promotionId, page, pageSize }) {
        let params = Object.fromEntries(
            Object.entries({
                promotionId, page, pageSize
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`asFastAsFighting/findLeaderboard?${new URLSearchParams(params).toString()}`)
    }

    async deleteById(id) {
        return ApiHelper.get(`question-form/delete?id=${id}`)

    }

}

export default new AsFastAsLightingFormModel();