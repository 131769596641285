import FormModel from "api/FormModel";
import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
} from "reactstrap";
import { DatePicker } from "antd"
import moment from "moment"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FormStatus from "constants/FormStatus";
import StatsFormDate from "./chart/stats-form-date";

const { RangePicker } = DatePicker
const dateFormat = "DD/MM/YYYY"

const AnalyticPromotion = props => {

    const [start_time, set_start_time] = useState(moment().subtract(30, "days"))
    const [end_time, set_end_time] = useState(moment())
    const [reports, setReports] = useState([])
    const [useRangeTime, setUseRangeTime] = useState(30)
    const [isChange, setIsChange] = useState(false)



    const fetchData = async (timeStart, timeEnd) => {
        setIsChange(false)
        let start = Math.floor(timeStart.toDate().getTime() / 1000)
        let end = Math.floor(timeEnd.toDate().getTime() / 1000)
        let count = await FormModel.countByStatus(null, null, start, end);
        let countApproved = await FormModel.countByStatus(null, FormStatus.APPROVED, start, end)
        let sum = await FormModel.sumBag(FormStatus.APPROVED, start, end);
        let countUserNotEngagedForm = await FormModel.countUserNotEngagedForm(start, end);
        setReports([
            { title: "Tổng số đơn", iconClass: "bx-copy-alt", description: count?.data },
            { title: "Đơn được duyêt", iconClass: "bx-archive-in", description: countApproved?.data },
            { title: "Số bao", iconClass: "bx-archive-in", description: sum?.data },
            { title: "Số người chưa tham gia đơn", iconClass: "bx-archive-in", description: countUserNotEngagedForm?.data },

        ])
        setIsChange(true)
    }

    const changeSelect = e => {
        setUseRangeTime(Number(e.target.value))
        let start = moment().subtract(60, "days")
        let end = moment()
        if (e.target.value == 7) {
            start = moment().subtract(7, "days")
        }
        if (e.target.value == 30) {
            start = moment().subtract(30, "days")
        }
        set_start_time(start)
        set_end_time(end)
        fetchData(start, end)
    }

    const changeCalendar = data => {
        set_start_time(data[0])
        set_end_time(data[1])
        fetchData(data[0], data[1])
    }

    useEffect(() => { fetchData(start_time, end_time) }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={"Khuyến mãi"}
                        breadcrumbItem="Thống kê"
                    />
                    <Row className="mb-3 justify-content-end">
                        <div className="col-3">
                            <select
                                className="form-control"
                                onChange={changeSelect}
                                value={useRangeTime}
                            >
                                <option value="7">7 Ngày</option>
                                <option value="30">30 Ngày</option>
                                <option value="0">Khoảng thời gian</option>
                            </select>
                        </div>
                        {useRangeTime === 0 && (
                            <div className="col-4">
                                <RangePicker
                                    value={[
                                        moment(start_time, dateFormat),
                                        moment(end_time, dateFormat),
                                    ]}
                                    onChange={changeCalendar}
                                    format={dateFormat}
                                />
                            </div>
                        )}
                    </Row>
                    <Row>
                        {/* Reports Render */}
                        {reports.map((report, key) => (
                            <Col md="3" key={"_col_" + key}>
                                <Card className="mini-stats-wid">
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">
                                                    {report.title}
                                                </p>
                                                <h4 className="mb-0">{report.description}</h4>
                                            </div>
                                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle bg-primary">
                                                    <i
                                                        className={
                                                            "bx " + report.iconClass + " font-size-24"
                                                        }
                                                    ></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-1">Số đơn đăng ký</CardTitle>
                                    {isChange &&
                                        <StatsFormDate start_time={start_time} end_time={end_time} />}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AnalyticPromotion;
