import { DatePicker, Image, Select, Tag } from "antd"
import React, { useEffect, useState } from "react"

//Import Breadcrumb
import { useParams } from "react-router-dom"
import GiftOrderModel from "api/GiftOrderModel"
import ExchangePointGiftOrderForm from "./forms/ExchangePointGiftOrderForm"

const DetailGiftOrderForm = props => {
    const { id } = useParams()
    const [giftOrder, setGiftOrder] = useState()

    const fetch = async id => {
        let resp = await GiftOrderModel.get(id)
        if (resp.error == 0) {
            setGiftOrder(resp.data)
        }
    }

    useEffect(() => {
        fetch(id)
    }, [])

    return (
        <React.Fragment>
            {giftOrder && <ExchangePointGiftOrderForm data={giftOrder} />}
        </React.Fragment>
    )
}

export default DetailGiftOrderForm
