import UserModel from "api/UserModel";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import { DatePicker } from "antd"
import moment from "moment"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import StatsUserDate from "pages/members/chart/stats-user-date";
import TableTopPromotion from "./table/TableTopPromotion";
import { useHistory } from "react-router-dom";
import StatsBagsCement from "./chart/stats-bags-cement";
const { RangePicker } = DatePicker
const dateFormat = "DD/MM/YYYY"

const Dashboard = props => {

  const [start_time, set_start_time] = useState(moment('2000-1-1'))
  const [end_time, set_end_time] = useState(moment())
  const [userReports, setUserReports] = useState([])
  const [userPromotionReports, setUserPromotionReports] = useState([])
  const [useRangeTime, setUseRangeTime] = useState(-1)
  const [isChange, setIsChange] = useState(false)
  let history = useHistory();

  const changeCalendar = data => {
    set_start_time(data[0])
    set_end_time(data[1])
    getReport(data[0], data[1])
  }

  const changeSelect = e => {
    setUseRangeTime(Number(e.target.value))
    let start = moment().subtract(60, "days")
    let end = moment()
    if (e.target.value == 7) {
      start = moment().subtract(7, "days")
    }
    if (e.target.value == 30) {
      start = moment().subtract(30, "days")
    }

    if (e.target.value == -1) {
      start = moment('2000-1-1')
    }

    set_start_time(start)
    set_end_time(end)
    getReport(start, end)
  }

  const getReport = async (timeStart, timeEnd) => {
    setIsChange(false)
    let start = Math.floor(timeStart.toDate().getTime() / 1000)
    let end = Math.floor(timeEnd.toDate().getTime() / 1000)
    let results = await UserModel.analytics(start, end)
    setUserReports([
      {
        title: "Nhà Thầu",
        iconClass: "bx-copy-alt",
        description: `${results.data?.numOfConstructorApproved}`,
        link: `/members/constructors?roleId=2&status=10`
      },
      {
        title: "Đại lý",
        iconClass: "bx-archive-in",
        description: `${results.data?.numOfRetailerApproved}`,
        link: '/members/retailers?roleId=3&status=10'
      },
      {
        title: "Nhà Phân Phối",
        iconClass: "bx-archive-in",
        description: `${results.data?.numOfDistributorApproved}`,
        link: '/members/distributors?roleId=4&status=10'
      },
      {
        title: "Khách hàng công nghiệp",
        iconClass: "bx-archive-in",
        description: `${results.data?.numOfIndustrialCustomersApproved}`,
        link: '/members/distributors?roleId=4&status=10'
      }
    ])

    setUserPromotionReports([
      {
        title: "Đã tham gia ít nhất 1 chương trình",
        iconClass: "bx-archive-in",
        description: `${results.data?.numOfUserEngagePromotion}`,
      },
      {
        title: "Chưa tham gia bất kỳ chương trình nào",
        iconClass: "bx-archive-in",
        description: `${results.data?.numOfUserNotEngagePromotion}`,
      },
    ])
    setIsChange(true)
  }

  useEffect(() => { getReport(start_time, end_time); }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={"Dashboards"}
            breadcrumbItem="Dashboard"
          />
          <Row className="mb-3 justify-content-end">
            <div className="col-3">
              <select
                className="form-control"
                onChange={changeSelect}
                value={useRangeTime}
              >
                <option value={"-1"}>Thời gian</option>
                <option value="7">7 Ngày</option>
                <option value="30">30 Ngày</option>
                <option value="0">Khoảng thời gian</option>
              </select>
            </div>
            {useRangeTime === 0 && (
              <div className="col-4">
                <RangePicker
                  value={[
                    moment(start_time, dateFormat),
                    moment(end_time, dateFormat),
                  ]}
                  onChange={changeCalendar}
                  format={dateFormat}
                />
              </div>
            )}
          </Row>
          <Row>
            {userReports.map((report, key) => (
              <Col md="3" key={"_col_" + key} onClick={() => history.push(report?.link)} className={report?.link ? 'hover' : ''}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          {report.title}
                        </p>
                        <h4 className="mb-0">{report.description}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={
                              "bx " + report.iconClass + " font-size-24"
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>

          <Row>
            <CardTitle className="mb-1">Coverage</CardTitle>
            {userPromotionReports.map((report, key) => (
              <Col md="3" key={"_col_" + key} onClick={() => history.push(report?.link)} className={report?.link ? 'hover' : ''}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          {report.title}
                        </p>
                        <h4 className="mb-0">{report.description}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={
                              "bx " + report.iconClass + " font-size-24"
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>

          <Row>
            <Col lg={6}>
              <Card style={{ height: 'calc(100% - 24px)' }}>
                <CardBody>
                  {isChange && (
                    <TableTopPromotion start_time={start_time} end_time={end_time} />
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-1">Xi măng</CardTitle>
                  {isChange &&
                    <StatsBagsCement />
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="mb-1">Số thành viên đăng ký</CardTitle>
                  {isChange && (
                    <StatsUserDate
                      start_time={Math.floor(
                        start_time.toDate().getTime() / 1000
                      )}
                      end_time={Math.floor(end_time.toDate().getTime() / 1000)}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default (Dashboard);
