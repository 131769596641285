import * as ApiHelper from "./api_helper"

class RegistrationEventModel {
  async find(eventName, page, pageSize) {
    let params = Object.fromEntries(
      Object.entries({
        eventName,
        page: page - 1,
        pageSize,
      }).filter(([_, v]) => v != null && v != 0)
    )
    return ApiHelper.get(
      `registration-event/find?${new URLSearchParams(params).toString()}`
    )
  }
  
  async getAnalytics() {
    return ApiHelper.get(
      `registration-event/count`
    )
  }
}




export default new RegistrationEventModel()
