class ConstructionType {
    static HOUSE = 1;
    static BUILDING = 2;
    static BRIDGES = 3;

    static findName(value) {
        switch (value) {
            case this.HOUSE: return 'Nhà ở'
            case this.BUILDING: return 'Tòa Nhà'
            case this.BRIDGES: return 'Cầu đường'
        }
    }

    static findColor(value) {
        switch (value) {
            case this.HOUSE:
                return '#E74C3C'; // Đỏ đậm
            case this.BUILDING:
                return '#27AE60'; // Xanh lá cây đậm
            case this.BRIDGES:
                return '#3498DB'; // Xanh dương đậm
        }
    }

}

export default ConstructionType;