import * as ApiHelper from './api_helper'

class AuthModel {
    async checkLogin() {
        return ApiHelper.get(`/auth`)
    }

    // async checkLogin() {
    //     return ApiHelper.post(`/auth`, { "test": "login" })
    // }
}

export default new AuthModel();
