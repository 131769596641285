import React, { useEffect, useState } from "react"
import { Card, CardBody, Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";
import GiftOrderTable from "./table/GiftOrderTable";

const GiftOrders = props => {
    const history = useHistory();

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={"Quà tặng"}
                        breadcrumbItem={"Danh sách đổi quà"}
                    />
                    <Card>
                        <CardBody>
                            <GiftOrderTable />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default GiftOrders
