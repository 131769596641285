import React, { useEffect, useMemo, useState } from "react"
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    Table,
    UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import TopicModel from "api/chat/TopicModel"
import "./index.css"
import * as UserType from '../../constants/UserType'
import ConversationSideBar from "./component/conversation-sidebar";
import ChatBox from "./component/chat-box";
import ContactCard from "pages/members/card/ContactCard"
import NotificationModel from "api/chat/NotificationModel"
import { District } from "helpers/ProvinceUtil"
import { City } from "helpers/ProvinceUtil"
import TimeUtils from "helpers/time.utils"
import ConversationStatus from "constants/ConversationStatus"
import { Popconfirm, Tag } from "antd"
import { error, success } from "helpers/notification";

//redux

const DetailMessage = props => {
    const id = props.match.params.id;
    const [message, setMessage] = useState()
    //meta title

    const fetch = async () => {
        let result = await NotificationModel.get(id)
        if (result.error == 0) {
            setMessage(result.data);
        }
    }

    useEffect(() => {
        fetch()
    }, [])

    const user = message?.user;

    return (
        <React.Fragment>
            <div style={{ paddingBottom: 0 }} className="page-content">
                <Container fluid>

                    <Card>
                        <CardBody style={{ padding: '5px' }}>
                            <div className="d-lg-flex">
                                <div style={{ width: '400px' }} className=" me-lg-4">
                                    {user && <ContactCard user={user} />}
                                    {message && user &&
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Thông tin chi tiết</CardTitle>
                                                <div className="table-responsive">
                                                    <Table className="table-nowrap mb-0">
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">Mobile :</th>
                                                                <td>{user.phone}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Tỉnh thành:</th>
                                                                <td>{City.getName(user.cityId)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Chủ đề :</th>
                                                                <td>{message.topic.name}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Thời gian tiếp nhận:</th>
                                                                <td>{TimeUtils.format(message.createdTime * 1000, "HH:mm - DD/MM/YYYY")}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Trạng thái:</th>
                                                                <td>
                                                                    <StatusMessage message={message} refresh={() => {
                                                                        fetch()
                                                                    }} />
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    }
                                </div>
                                <div style={{ width: 'calc(100% - 400px - 24px)', paddingTop: '30px' }} className="user-chat">
                                    <ChatBox conversation={message} />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DetailMessage;

const StatusMessage = ({ message, refresh }) => {
    const [status, setStatus] = useState(message.status)

    const bgColor = useMemo(() => {
        return ConversationStatus.findColor(status)
    }, [status])

    const textColor = useMemo(() => {
        if (status == ConversationSideBar.INIT) {
            return '#495057';
        }
        return '#fff'
    }, [status])

    const updateStatus = async status => {
        let result = await NotificationModel.updateStatus(message.id,
            status,
            undefined);

        if (result.error != 0) {
            error("Lỗi!")
            return;
        }

        success("Thành công!")
        refresh()
        //TODO
    }


    return (
        <>
            <div className="btn-group" role="group">
                <select
                    className="form-control"
                    style={{ lineHeight: '1', fontSize: '0.7125rem', color: textColor, backgroundColor: bgColor, borderColor: bgColor }}
                    onChange={e =>
                        setStatus(Number(e.target.value))
                    }
                    value={status}
                >
                    <option value={ConversationStatus.INIT}>
                        {ConversationStatus.findName(ConversationStatus.INIT)}
                    </option>
                    <option value={ConversationStatus.PROCESSING}>
                        {ConversationStatus.findName(ConversationStatus.PROCESSING)}
                    </option>
                    <option value={ConversationStatus.DONE}>
                        {ConversationStatus.findName(ConversationStatus.DONE)}
                    </option>
                </select>
                {message.status != status &&
                    <div style={{ marginLeft: '5px' }}>
                        <Popconfirm
                            title="Bạn có muốn cập nhật trạng thái này?"
                            onConfirm={() => {
                                updateStatus(status)
                            }}
                        >
                            <Link to={``} className="btn btn-primary  btn-sm">
                                <i className="mdi mdi-checkbox-marked" />
                            </Link>
                        </Popconfirm>
                    </div>
                }

            </div>
        </>
    )

}



