import * as ApiHelper from './api_helper'

class SurveyModel {
    async find(page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                page: page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`survey/find?${new URLSearchParams(params).toString()}`)
    }

    async findForms(broadcastId, topicId , page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                broadcastId,
                topicId,
                page: page - 1,
                pageSize
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`survey/forms/find?${new URLSearchParams(params).toString()}`)
    }

    async get(broadcastId, topicId) {
        let params = Object.fromEntries(
            Object.entries({
                broadcastId,
                topicId
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`survey/get?${new URLSearchParams(params).toString()}`)
    }

    async delete(formId) {
        return ApiHelper.get(`survey/forms/delete?id=${formId}`)
    }

    async export(broadcastId, topicId) {
        let params = Object.fromEntries(
            Object.entries({
                broadcastId,
                topicId
            }).filter(([_, v]) => v != null && v != 0)
        );
        window.open(ApiHelper.getApiUrl() +
            `survey/export-excel?${new URLSearchParams(params).toString()}`, '_blank');
        return;
    }


}

export default new SurveyModel();
