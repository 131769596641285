import React, { useEffect } from 'react';
import { useState } from "react";
import {
    Table,
    Button,
    Card,
    Col,
    Label,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form
} from "reactstrap";
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as ApiHelper from '../../../api/api_helper'
import { error, success } from "helpers/notification"
import AudienceModel from 'api/AudienceModel';
import AudienceType from 'constants/AudienceType';


const MultiFormAudienceModal = (props) => {
    const [modal, setModal] = useState(false);
    const [forms, setForms] = useState();
    const [ids, setIds] = useState();

    useEffect(() => {
        setModal(props.open)
    }, [props])

    const isValid = () => {
        if (forms.length <= 0) {
            error("Vui lòng upload file theo format")
            return false;
        }

        for (const form of forms) {
            if (form.type != AudienceType.UID_SETS) {
                error("Don't support this type")
                return false;
            }
            if (!form.alias) {
                error("Vui lòng đặt tên cho group")
                return false;
            }
            if (!form.uids || form.uids.length <= 0) {
                error("Group không tồn tại thành viên " + form.alias)
                return false;
            }
        }
        return true;
    }

    const submit = async () => {
        if (!isValid()) {
            return;
        }
        let result = await AudienceModel.multiCreate(forms)
        if (result.error != 0) {
            error(result.msg)
            return;
        }
        setForms(undefined)
        success("Đã tạo thành công!");
        let data = result.data
        if (data && data.length > 0) {
            let _ids = data.map(item => item.id);
            setIds(_ids)
        }
        props.onSubmitSuccess()
    }

    return (
        <>
            <Modal
                isOpen={modal}
            >
                <ModalHeader tag="h4">
                    Audience
                </ModalHeader>
                <ModalBody className='pt-0'>
                    <Card style={{padding: '10px'}}>
                        <Form>
                            <Row>
                                <Col sm={12}>
                                    <span>Format File: SDT | Tài khoản | Group (Alias Audience)</span>
                                    <ul>
                                        <li>Nhà thầu</li>
                                        <li>Cửa hàng</li>
                                        <li>Nhà phân phối</li>
                                        <li>Khách hàng công nghiệp</li>
                                    </ul>
                                </Col>
                                <Col sm={12} className='text-center'>
                                    <Upload
                                        action={`${ApiHelper.getApiUrl()}audience/upload-phone-to-uid`}
                                        name='file'
                                        maxCount={1}
                                        withCredentials={true}
                                        onChange={(info) => {
                                            if (info.file.status === 'done') {
                                                if (info?.file?.response?.error == 0) {
                                                    const data = info?.file?.response?.data;
                                                    if (data && data.length > 0) {
                                                        let _forms = data.map((item) => {
                                                            return {
                                                                type: AudienceType.UID_SETS,
                                                                alias: item.groupId,
                                                                uids: item.uids
                                                            }
                                                        })
                                                        setForms(_forms);
                                                    }
                                                } else {
                                                    error(`${info.file.name} file upload failed.`)
                                                }
                                            } else if (info.file.status === 'error') {
                                                error(`${info.file.name} file upload failed.`)
                                            }
                                        }}>
                                        <Button onClick={() => {

                                        }} icon={<UploadOutlined />}>Chọn File</Button>
                                    </Upload>
                                </Col>
                                <Col pt={20} sm={12}>
                                    <Label>Nội dung File</Label>
                                    {forms && (
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Group</th>
                                                    <th>Size</th>
                                                    <th>UIds</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {forms.map((form, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{form.alias}</td>
                                                            <td>{form.uids?.length}</td>
                                                            <td>{form.uids?.join(", ")}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                    <ModalFooter>
                        <Button
                            onClick={() => { props.toggle(false) }}
                            type="button"
                            color="secondary"
                        >
                            Đóng
                        </Button>
                        {forms &&
                            <Button
                                type="button"
                                color="success"
                                onClick={() => { submit() }}
                            >
                                Tạo
                            </Button>
                        }
                        {ids && (
                            <Button
                                type="button"
                                color="success"
                                onClick={() => {
                                    window.open(ApiHelper.getApiUrl() +
                                        `audience/export?ids=${ids}`, '_blank');
                                }}
                            >
                                Export
                            </Button>
                        )}
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </>
    )
}

export default MultiFormAudienceModal;