import UserModel from "api/UserModel";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react"
import TimeUtils from 'helpers/time.utils';
import FormModel from "api/FormModel";

const StatsFormDate = (props) => {

    const [option, setOption] = useState()

    const buildOption = (data) => {
        let dataSeries = []
        var categories = []
        for (var i = data.length - 1; i >= 0; i--) {
            dataSeries.push([data[i].date, data[i].total])
            categories.push(TimeUtils.toStringNotYear(TimeUtils.parseDate(data[i].date)))
        }
        return {
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: categories,
                labels: {
                    format: '{value:%e %b}'
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Số đơn đăng ký',
                data: dataSeries
            }]
        }
    }

    const fetchStatsDate = async () => {
        let { start_time, end_time } = props
        let start = Math.floor(start_time.toDate().getTime() / 1000)
        let end = Math.floor(end_time.toDate().getTime() / 1000)
        let result = await FormModel.statsFormDate(start, end);
        console.log(result.data)
        let data = result.data;
        let opt = buildOption(data)
        setOption(opt)
    }

    useEffect(() => {
        fetchStatsDate();
    }, [])

    return (
        <HighchartsReact constructorType={"chart"}
            highcharts={Highcharts} options={option} />
    )
}

export default StatsFormDate
