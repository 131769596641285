import React, { useEffect, useState } from "react"

import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import UploadImage from "components/UploadImage"

import ArticleModel from "api/ArticleModel"
import { success, error } from "helpers/notification"
import { useHistory } from "react-router-dom"
import PromotionModel from "api/PromotionModel"
import GiftModel from "api/GiftModel"
import AudienceInput from "pages/audience/form/audience-input"
import { Popconfirm } from "antd"
import { submit } from "redux-form"

const CreateGift = props => {
    const [validation, setValidation] = useState([])
    const [isSubmit, setIsSubmit] = useState(false)
    const [promotions, setPromotions] = useState([])

    const [field, setField] = useState({
    })
    const history = useHistory()


    const change_images = data => {
        setField({ ...field, link: data[0]?.url })
    }

    const submit = async e => {
        setIsSubmit(true)
        let isError = validate()
        if (isError) {
            return;
        }
        let result = await GiftModel.create(field)
        if (result.error != 0) {
            error(result.msg)
            return
        }
        success("Tạo quà tặng thành công!")
        history.push(`/gifts`)
    }

    const validate = () => {
        let isError = false
        let tmp = {}
        if (!field?.name) {
            isError = true
            tmp = { ...tmp, name: "Thiếu tên quà tặng" }
        }
        if (!field?.link) {
            isError = true
            tmp = { ...tmp, link: "Thiếu ảnh mô tả" }
        }
        if (!field?.point) {
            isError = true
            tmp = { ...tmp, point: "Thiếu số điểm" }
        }
        setValidation(tmp)
        return isError
    }

    const getPromotion = async () => {
        let result = await PromotionModel.getPromotions(null, null, null, null, 1, 100)
        setPromotions(result.data.list)
    }

    useEffect(() => {
        getPromotion()
    }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Quà tặng"} breadcrumbItem="Thêm quà tặng mới" />
                    <Card>
                        <CardBody>
                            <Form>
                                <Row>
                                    <Col sm={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="title">
                                                Tên quà{" "}
                                                {
                                                    <span style={{ color: "red" }}>
                                                        {validation["title"]}
                                                    </span>
                                                }
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                placeholder="Nhập tiêu đề bài viết"
                                                onChange={e => {
                                                    setField({ ...field, name: e.target.value })
                                                }}
                                                invalid={isSubmit && validation["name"] != null}
                                                value={field.name}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="point">
                                                Số điểm{" "}
                                                {
                                                    <span style={{ color: "red" }}>
                                                        {validation["point"]}
                                                    </span>
                                                }
                                            </Label>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                placeholder="Nhập số điểm"
                                                onChange={e => {
                                                    setField({ ...field, point: Number(e.target.value) })
                                                }}
                                                invalid={isSubmit && validation["point"] != null}
                                                value={field.point}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="promotion">Chương trình</Label>
                                            <select
                                                id="promotion"
                                                className="form-control"
                                                value={field.promotionId}
                                                onChange={e => setField({ ...field, promotionId: Number(e.target.value) })}>
                                                <option value={0}>Chọn...</option>
                                                {promotions
                                                    .map(item => (
                                                        <option value={item.id} key={item.id}>
                                                            {item.title}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="mb-3">
                                            <div className="mb-3">
                                                <Label htmlFor="quota">
                                                    Quota (per account){" "}
                                                    {
                                                        <span style={{ color: "red" }}>
                                                            {validation["quota"]}
                                                        </span>
                                                    }
                                                </Label>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Tối đa số quà mỗi user được đổi"
                                                    onChange={e => {
                                                        setField({ ...field, quota: Number(e.target.value) })
                                                    }}
                                                    invalid={isSubmit && validation["quota"] != null}
                                                    value={field.quota}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="roleIds">Đối tượng?
                                                {" "}
                                                {
                                                    <span style={{ color: "red" }}>
                                                        {validation["audienceId"] || ''}
                                                    </span>
                                                }
                                            </Label>
                                            <AudienceInput
                                                value={field.audienceId}
                                                onChange={(value) => {
                                                    setField({ ...field, audienceId: value })
                                                }} />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="mb-3 justify-content-end">
                                    <Label htmlFor="duration">
                                        Ảnh
                                        {
                                            <span style={{ color: "red" }}>
                                                {validation["link"]}
                                            </span>
                                        }
                                    </Label>
                                    <Row className="mb-3">
                                        <Col sm={12}>
                                            <UploadImage
                                                exits_images={field?.link ? [{ url: field?.link }] : []}
                                                change_images={change_images} size={1} />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mb-3 justify-content-end">
                                    <Popconfirm
                                        title="Bạn có chắc?"
                                        onConfirm={() => {
                                            submit()
                                        }}
                                    >
                                        <button
                                            style={{ float: 'right' }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}
                                            className="btn btn-primary w-md "
                                        >
                                            Lưu
                                        </button>
                                    </Popconfirm>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateGift
