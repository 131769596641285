// TableMember

import React from "react";

import queryString from 'query-string'
import {
    Row, Col,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap"

import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import TotalForceTableForm from "pages/forms/tablev2/TotalForceTableForm";
import GiftOrderTable from "pages/gift-order/table/GiftOrderTable";

const TotalForceProgram = props => {
    let history = useHistory();
    const { promotionId } = useParams()
    const { tab = 'forms' } = queryString.parseUrl(history.location.search).query
    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="text-sm-end mb-3">
                        <Row>
                            <Col sm={12}>
                                <Nav pills>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: tab == "forms"
                                            })}
                                            onClick={() => {
                                                history.push(`/program/${promotionId}?tab=forms`)
                                            }}
                                        >
                                            <i className="bx bx-chat font-size-20 d-sm-none" />
                                            <span className="d-none d-sm-block">Đơn tham gia</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: tab == "gift"
                                            })}
                                            onClick={() => {
                                                history.push(`/program/${promotionId}?tab=gift`)
                                            }}
                                        >
                                            <i className="bx bx-chat font-size-20 d-sm-none" />
                                            <span className="d-none d-sm-block">Quà tặng</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            {tab == "forms" && <TotalForceTableForm promotionId={promotionId} />}
            {tab == "gift" && <GiftOrderTable promotionId={promotionId} />}
        </>
    )
}

export default TotalForceProgram
