// TableMember

import { Button, Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import TimeUtils from "helpers/time.utils"
import { Link, useHistory } from "react-router-dom"
import {
    Col,
} from "reactstrap"
import { DatePicker } from "antd"
import moment from "moment"
import { Row } from "reactstrap"
import queryString from 'query-string'
import BroadcastModel from "api/BroadcastModel"
import BroadcastType from "constants/BroadcastType"
import BroadcastStatus from "constants/BroadcastStatus"
const { RangePicker } = DatePicker
const dateFormat = "DD/MM/YYYY"

// TableBroadcast
const TableBroadcast = props => {

    let history = useHistory();

    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Tiêu đề",
            dataIndex: "name",
        },
        {
            title: "Loại broadcast",
            dataIndex: "type",
            render: type => BroadcastType.findName(type)
        },
        {
            title: "Bắt đầu",
            dataIndex: "timeStart",
            render: timeStart => TimeUtils.format(timeStart, "HH:mm - DD/MM/YYYY")

        },
        {
            title: "Tổng",
            dataIndex: "total",
            render: total => total

        },
        {
            title: "Thành công",
            dataIndex: "countPush",
            render: countPush => countPush

        },
        {
            title: "Thất bại",
            dataIndex: "failed",
            render: failed => failed

        },
        {
            title: "Lượt xem",
            dataIndex: "countView",
            render: countView => countView

        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: status => (
                <Tag color={BroadcastStatus.findColor(status)} key={status}>
                    {BroadcastStatus.findName(status)}
                </Tag>
            ),
        },
        {
            title: "Hành động",
            render: (_, record) => (
                <>
                    <Button type="primary" size="small" style={{ width: "100%" }} onClick={() => history.push(`/broadcasts/detail/${record.id}`)} >
                        Chi tiết
                    </Button>

                </>
            ),
        },
    ]

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [start_time, set_start_time] = useState(props?.filter?.start ? moment(props?.filter?.start) : moment().subtract(30, "days"))
    const [end_time, set_end_time] = useState(moment())
    const [filter, setFilter] = useState({
        ...props.filter,
        start: Math.floor(start_time.toDate().getTime() / 1000),
        end: Math.floor(end_time.toDate().getTime() / 1000),
        ...queryString.parseUrl(history.location.search).query
    })

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.replace({ search: url })
    };

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await BroadcastModel.find(
            filter?.status,
            filter?.start,
            filter?.end,
            newPagination.current,
            newPagination.pageSize
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData({ current: 1, pageSize: pagination.pageSize })
        serialize2Url()
    }, [filter])

    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    const changeCalendar = data => {
        set_start_time(data[0])
        set_end_time(data[1])
        setFilter({
            ...filter,
            start: Math.floor(data[0].toDate().getTime() / 1000),
            end: Math.floor(data[1].toDate().getTime() / 1000)
        })
    }

    const export_excel = () => {
        let { status, start, end } = filter;
        BroadcastModel.export(status, start, end)
    }


    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="text-sm-end mb-3">
                        <div className="btn-group" role="group">
                            <div className="" style={{ marginLeft: "10px" }}>
                                <RangePicker
                                    value={[
                                        moment(start_time, dateFormat),
                                        moment(end_time, dateFormat),
                                    ]}
                                    onChange={changeCalendar}
                                    format={dateFormat} style={{ minWidth: "250px", height: '32px' }}
                                />
                            </div>
                            <select
                                className="form-control"
                                style={{ marginLeft: "10px", height: '32px' }}
                                onChange={e =>
                                    setFilter({ ...filter, status: Number(e.target.value) })
                                }
                                value={filter?.status}
                            >
                                <option value={0}>Tất cả</option>
                                <option value={BroadcastStatus.INIT}>
                                    {BroadcastStatus.findName(BroadcastStatus.INIT)}
                                </option>
                                <option value={BroadcastStatus.APPROVED}>
                                    {BroadcastStatus.findName(BroadcastStatus.APPROVED)}
                                </option>
                                <option value={BroadcastStatus.DELETED}>
                                    {BroadcastStatus.findName(BroadcastStatus.DELETED)}
                                </option>
                                <option value={BroadcastStatus.CANCELED}>
                                    {BroadcastStatus.findName(BroadcastStatus.CANCELED)}
                                </option>
                                <option value={BroadcastStatus.DONE}>
                                    {BroadcastStatus.findName(BroadcastStatus.DONE)}
                                </option>
                                <option value={BroadcastStatus.FAILED}>
                                    {BroadcastStatus.findName(BroadcastStatus.FAILED)}
                                </option>
                            </select>
                            <Button
                                type="primary"
                                style={{ marginLeft: "10px" }}
                                onClick={export_excel}
                            >
                                Export
                            </Button>
                            <Button
                                type="primary"
                                style={{ marginLeft: "10px" }}
                            >
                                <Link to={`/broadcasts/create`}>Tạo Boardcast</Link>
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />

        </>
    )
}

export default TableBroadcast
