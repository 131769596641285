import MatchModel from "api/MatchModel"
import React, { useEffect, useState } from "react"
import {
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Input,
    Label,
    Row,
    Col,
} from "reactstrap"

const FootballResultModal = props => {

    const [match, setMatch] = useState(props?.match)

    const handleSubmit = () => {
        props.handleSubmit({
            ...match,
            goalHost: match.goalHost || 0,
            goalGuest: match.goalGuest || 0
        })
    }

    useEffect(() => { console.log(match) }, [match]);



    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
            <ModalHeader className="text-center">{props.title}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={6}>
                        <h6 className="text-center"> {match?.cHost?.name}</h6>
                    </Col>
                    <Col sm={6}>
                        <h6 className="text-center"> {match?.cGuest?.name}</h6>
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <input
                            className="form-control"
                            type="number"
                            value={match?.goalHost || 0}
                            onChange={e => setMatch({ ...match, goalHost: Number(e.target.value) })}
                        />
                    </Col>
                    <Col sm={6}>
                        <input
                            className="form-control"
                            type="number"
                            value={match?.goalGuest || 0}
                            onChange={e => setMatch({ ...match, goalGuest: Number(e.target.value) })}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={props.toggle}
                    className="btn btn-secondary btn-sm"
                    data-dismiss="modal"
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmit}
                >
                    Confirm
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default FootballResultModal
