import React, { useEffect, useState } from "react"
import queryString from "query-string"
import { Card, CardBody, Container } from "reactstrap"
import PromotionType from "../../constants/PromotionType"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import InvoiceTableForm from "./table/InvoiceTableForm"
import PredictFootballTableForm from "./table/PredictFootballTableForm"
import HotDealTableForm from './table/HotDealTableForm'
import { useHistory } from "react-router-dom"
import PromotionModel from "api/PromotionModel"
import LoyaltyInvoiceTableForm from "./table/LoyaltyInvoiceTableForm"
import LoyaltyInvoiceAnalytics from "./analytics/LoyaltyInvoiceAnalytics"
import InvoiceAnalytics from "./analytics/InvoiceAnalytics"
import PredictFootballAnalytics from "./analytics/PredictFootballAnalytics"
import HotDealAnalytics from "./analytics/HotDealAnalytics"
import QuickQuestionTableForm from "./table/QuickQuestionTableForm"

const Forms = props => {
    const history = useHistory()
    const { promotionId, matchId } = queryString.parseUrl(history.location.search).query
    const [promotion, setPromotion] = useState()
    const [isChange, setIsChange] = useState(false);

    const [filter, setFilter] = useState({});

    const fetchPromotionById = async id => {
        setIsChange(false)
        let resp = await PromotionModel.get(id)
        if (resp.error == 0) {
            setPromotion(resp.data)
        }
        setIsChange(true)
    }

    const updateFilter = (tmp) => {
        setFilter(tmp)
    }

    const update = (promotionId) => {
        fetchPromotionById(promotionId)
    }

    useEffect(() => {
        fetchPromotionById(promotionId)
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                {isChange &&
                    <Container fluid>
                        <Breadcrumbs title={"Forms"} breadcrumbItem={`[${PromotionType.findName(promotion?.type)}]  ${promotion?.title}`} />
                        {promotion?.type == PromotionType.LOYALTY_PROMOTION && (
                            <LoyaltyInvoiceAnalytics promotionId={promotionId} filter={filter} />
                        )}
                        {promotion?.type == PromotionType.NORMAL_INVOICE && (
                            <InvoiceAnalytics promotionId={promotionId} filter={filter} />
                        )}
                        {promotion?.type == PromotionType.PREDICT_FOOTBALL && (
                            <PredictFootballAnalytics promotionId={promotionId} matchId={matchId} filter={filter} />
                        )}
                        {promotion?.type == PromotionType.HOT_DEAL && (
                            <HotDealAnalytics promotionId={promotionId} filter={filter} />
                        )}
                        <Card>
                            <CardBody>
                                {promotion?.type == PromotionType.LOYALTY_PROMOTION && (
                                    <LoyaltyInvoiceTableForm
                                        filter={{ promotionId: promotionId }} updateFilter={updateFilter}
                                    />
                                )}
                                {promotion?.type == PromotionType.NORMAL_INVOICE && (
                                    <InvoiceTableForm filter={{ promotionId: promotionId, start: promotion.timeStart }} updateFilter={updateFilter} />
                                )}
                                {promotion?.type == PromotionType.PREDICT_FOOTBALL && (
                                    <PredictFootballTableForm reload={update} updateFilter={updateFilter} />
                                )}
                                {promotion?.type == PromotionType.HOT_DEAL && (
                                    <HotDealTableForm filter={{ promotionId: promotionId, start: promotion.timeStart }} updateFilter={updateFilter} />
                                )}
                                {promotion?.type == PromotionType.QUICK_QUESTION_PROMOTION && (
                                    <QuickQuestionTableForm filter={{ promotionId: promotionId }} updateFilter={updateFilter} />
                                )}
                            </CardBody>
                        </Card>
                    </Container>}
            </div>
        </React.Fragment>
    )
}

export default Forms
