import FormModel from "api/FormModel";
import FormStatus from "constants/FormStatus";
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"


const HotDealAnalytics = (props) => {

    const [report, setReport] = useState(null);

    const getAnalyticsForm = async () => {
        let query = {
            search: props?.filter?.search,
            type: props?.filter?.type,
            userId: props?.filter?.userId,
            cityId: props?.filter?.cityId,
            promotionId: props?.filter?.promotionId,
            start: props?.filter?.start,
            end: props?.filter?.end,
        }
        if (!query.promotionId) {
            return;
        }
        let resTotal = await FormModel.count({ ...query, status: 0 });
        let resApproved = await FormModel.count({ ...query, status: FormStatus.APPROVED });
        let resRejected = await FormModel.count({ ...query, status: FormStatus.REJECTED });
        setReport({ total: resTotal?.data, approved: resApproved?.data, rejected: resRejected.data });
    }

    useEffect(() => {
        console.log(props.filter);
        getAnalyticsForm();
    }, [props.filter])

    return (
        <Row>
            <Col sm={4}>
                <Card>
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                    Tổng số đơn
                                </p>
                                <h4 className="mb-0">{report?.total}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                        className={
                                            "bx bx-archive-in font-size-24"
                                        }
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col sm={4}>
                <Card>
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                    Số đơn đã duyệt
                                </p>
                                <h4 className="mb-0">{report?.approved}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                        className={
                                            "bx bx-archive-in font-size-24"
                                        }
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col><Col sm={4}>
                <Card>
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                    Số đơn từ chối
                                </p>
                                <h4 className="mb-0">{report?.rejected}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                        className={
                                            "bx bx-archive-in font-size-24"
                                        }
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default HotDealAnalytics