// TableMember

import { Table, Tag, Button } from "antd"
import React, { useEffect, useState } from "react"

import TimeUtils from "helpers/time.utils"
import { City } from "helpers/ProvinceUtil"
import { useHistory } from "react-router-dom"
import queryString from 'query-string'
import FormModel from "api/FormModel"
import FormStatus from "constants/FormStatus"
import { PopupConfirm } from '../../../components/pop-confirm'
import PromotionModel from '../../../api/PromotionModel'
import * as QuickQuestionUtil from '../../../common/quick-question'

import {
    Row, Col,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap"
import classnames from "classnames";

const QuickQuestionTableForm = props => {
    const {promotionId} = props.filter
    const [promotion, setPromotion] = useState()
    const [isStartPopup, set_isStartPopup] = useState(false)
    const [isEndPopup, set_isEndPopup] = useState(false)
    let history = useHistory();
    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Họ tên",
            dataIndex: "user",
            render: user => user.name
        },
        {
            title: "SDT",
            dataIndex: "user",
            render: user => user.phone
        },
        {
            title: "Tỉnh/Thành Phố",
            dataIndex: "user",
            render: user => City.getName(user?.cityId),
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: status => (
                <Tag color={FormStatus.findColor(status)} key={status}>
                    {FormStatus.findName(status)}
                </Tag>
            ),
        },
        {
            title: "Đáp án",
            dataIndex: "answers",
            render: answers => {
                let q1 = answers.filter(a => a.questionId == 1)[0];
                return (
                    <Tag color={QuickQuestionUtil.mapIdAns2Color(q1.ans)} key={q1.ans}>
                        {QuickQuestionUtil.mapIdAns2String(q1.ans)}
                    </Tag>
                )
            },
        },
        {
            title: "Số may mắn",
            dataIndex: "answers",
            render: answers => {
                let q2 = answers.filter(a => a.questionId == 2)[0];
                return (
                    <Tag color={'red'} key={q2.ans}>
                        {q2.ans}
                    </Tag>
                )
            },
        },
        {
            title: "Thời gian",
            dataIndex: "updatedTime",
            render: updatedTime => TimeUtils.toDDMMYYYY(updatedTime * 1000),
        },
        {
            title: "Hành động",
            dataIndex: "id",
            render: id => (
                <Button color="primary"
                    className="btn btn-primary"
                    onClick={() => history.push(`/promotions/forms/${id}?promotionId=${promotionId}`)}>
                    Chi tiết
                </Button>
            ),
        },
    ]
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [filter, setFilter] = useState({
        ...props.filter,
        ...queryString.parseUrl(history.location.search).query
    })

    const fetchPromotion = () => {
        PromotionModel.get(filter.promotionId)
            .then(resp => {
                if (resp.error == 0) {
                    setPromotion(resp.data)
                }
            })
    }

    useEffect(() => {
        fetchPromotion()
    }, [])

    const toggleStatusPromotion = (isOpen) => {
        PromotionModel.toggleStatushotDeal({
            promotionId: filter.promotionId,
            open: isOpen
        }).then(resp => {
            fetchPromotion()
        })
    }

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.replace({ search: url })
    };

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await FormModel.getForms(
            {
                search: filter?.search,
                status: filter?.status,
                type: filter?.type,
                userId: filter?.userId,
                cityId: filter?.cityId,
                promotionId: filter?.promotionId,
                matchId: filter?.matchId,
                page: newPagination.current,
                pagesize: newPagination.pageSize
            }
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData({ current: 1, pageSize: pagination.pageSize })
        serialize2Url()
        props.updateFilter(filter)
    }, [filter])


    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    const export_excel = () => {
        let { status, type, userId, promotionId, matchId, search, cityId, start, end } = filter;
        FormModel.export(status, type, userId, promotionId, matchId, search, cityId, start, end)
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="text-sm-end mb-3">
                        <Row>
                            <Col sm={4}>
                                <Nav pills>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: true
                                            })}
                                            onClick={() => {
                                                history.push(`/forms?promotionId=${filter?.promotionId}`)
                                            }}
                                        >
                                            <i className="bx bx-chat font-size-20 d-sm-none" />
                                            <span className="d-none d-sm-block">Đơn tham gia</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                            <Col sm={8}>
                                <div className="btn-group" role="group">
                                    <input
                                        className="form-control"
                                        type="search"
                                        placeholder="SDT hoặc tên khách hàng"
                                        style={{ marginLeft: "10px" }}
                                        onChange={e => setFilter({ ...filter, search: e.target.value })}
                                    />
                                    <select
                                        className="form-control"
                                        style={{ marginLeft: "10px" }}
                                        onChange={e =>
                                            setFilter({ ...filter, status: Number(e.target.value) })
                                        }
                                        value={filter?.status ? filter?.status : 0}
                                    >
                                        <option value={0}>Trạng thái</option>
                                        <option value={FormStatus.INIT}>{FormStatus.findName(FormStatus.INIT)}</option>
                                        <option value={FormStatus.APPROVED}>{FormStatus.findName(FormStatus.APPROVED)}</option>
                                        <option value={FormStatus.REJECTED}>{FormStatus.findName(FormStatus.REJECTED)}</option>
                                        <option value={FormStatus.SENT_GIFT}>{FormStatus.findName(FormStatus.SENT_GIFT)}</option>
                                        <option value={FormStatus.RECEIVED}>{FormStatus.findName(FormStatus.RECEIVED)}</option>
                                    </select>
                                    <Button
                                        color="light"
                                        className="btn btn-light "
                                        style={{ marginLeft: "10px", height: '38px' }}
                                        size={24}
                                        onClick={export_excel}
                                    >
                                        Export
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            {isStartPopup && (
                <PopupConfirm
                    isOpen={isStartPopup}
                    toggle={() => set_isStartPopup(false)}
                    handleSubmit={() => {
                        toggleStatusPromotion(true);
                        set_isStartPopup(false)
                    }}
                    title="Bạn có chắc?"
                />
            )}
            {isEndPopup && (
                <PopupConfirm
                    isOpen={isEndPopup}
                    toggle={() => set_isEndPopup(false)}
                    handleSubmit={() => {
                        toggleStatusPromotion(false);
                        set_isEndPopup(false)
                    }}
                    title="Bạn có chắc?"
                />
            )}
        </>
    )
}

export default QuickQuestionTableForm
