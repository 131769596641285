import React from "react"

import { Card, CardBody, Container } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableArticle from "./table/TableArticle"


const Articles = props => {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Bài viết"} breadcrumbItem="Danh sách bài viêt" />
                    <Card>
                        <CardBody>
                            <TableArticle />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Articles
