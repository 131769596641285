// TableMember

import { Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import TimeUtils from "helpers/time.utils"
import { City } from "helpers/ProvinceUtil"
import { useHistory } from "react-router-dom"
import { Button, Col } from "reactstrap"
import { Row } from "reactstrap"
import queryString from 'query-string'
import FormModel from "api/FormModel"
import FormStatus from "constants/FormStatus"
import { DatePicker } from "antd"
import moment from "moment"
import InvoiceLoyaltyFormModel from "api/loylaty/InvoiceLoyaltyFormModel"
const { RangePicker } = DatePicker
const dateFormat = "DD/MM/YYYY"

const LoyaltyInvoiceTableForm = props => {
    const {promotionId} = props;
    let history = useHistory();
    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Họ tên",
            dataIndex: "user",
            render: user => user.name
        },
        {
            title: "SDT",
            dataIndex: "user",
            render: user => user.phone
        },
        {
            title: "Tỉnh/Thành Phố",
            dataIndex: "user",
            render: user => City.getName(user?.cityId),
        },
        {
            title: "Số bao",
            dataIndex: "bags",
            render: bags => <b>{bags}</b>,
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: status => (
                <Tag color={FormStatus.findColor(status)} key={status}>
                    {FormStatus.findName(status)}
                </Tag>
            ),
        },
        {
            title: "Thời gian",
            dataIndex: "updatedTime",
            render: updatedTime => TimeUtils.toDDMMYYYY(updatedTime * 1000),
        },
        {
            title: "Hành động",
            dataIndex: "id, form",
            render: (id, form) => {
                return (
                    <Button color="primary"
                        className="btn btn-primary"
                        onClick={() => history.push(`/promotions/forms/${form.id}?promotionId=${form.promotionId}`)}>
                        Chi tiết
                    </Button>
                )
            },
        },
    ]
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 0,
        pageSize: 10,
    })
    const [start_time, set_start_time] = useState(moment().subtract(30, "days"))
    const [end_time, set_end_time] = useState(moment())

    const [filter, setFilter] = useState({
        ...props.filter,
        start: Math.floor(start_time.toDate().getTime() / 1000),
        end: Math.floor(end_time.toDate().getTime() / 1000),
        ...queryString.parseUrl(history.location.search).query
    })

    const changeCalendar = data => {
        set_start_time(data[0])
        set_end_time(data[1])
        setFilter({
            ...filter,
            start: Math.floor(data[0].toDate().getTime() / 1000),
            end: Math.floor(data[1].toDate().getTime() / 1000)
        })
    }

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.replace({ search: url })
    };

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await InvoiceLoyaltyFormModel.getForms(
            {
                search: filter?.search,
                status: filter?.status,
                userId: filter?.userId,
                cityId: filter?.cityId,
                promotionId: promotionId,
                userConstructionId: filter?.userConstructionId,
                constructorRetailerId: filter?.constructorRetailerId,
                start: filter?.start,
                end: filter?.end,
                page: newPagination.current - 1,
                pagesize: newPagination.pageSize
            }
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData({ current: 1, pageSize: pagination.pageSize })
        serialize2Url()
    }, [filter])


    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    const export_excel = () => {
        InvoiceLoyaltyFormModel.export(promotionId)
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="filter-group" style={{ paddingBottom: '18px' }} role="group">
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="search"
                                placeholder="SDT hoặc tên khách hàng"
                                onChange={e => setFilter({ ...filter, search: e.target.value })}
                            />
                        </div>
                        <div className="form-group">
                            <select
                                className="form-control"
                                onChange={e =>
                                    setFilter({ ...filter, cityId: Number(e.target.value) })
                                }
                                value={filter?.cityId ? filter?.cityId : 0}
                            >
                                <option value={0}>Tỉnh/Thành Phố</option>
                                {City.getList().map(value => {
                                    return (
                                        <option key={value.key} value={value.key}>
                                            {value.value}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="form-group">
                            <select
                                className="form-control"
                                onChange={e =>
                                    setFilter({ ...filter, status: Number(e.target.value) })
                                }
                                value={filter?.status ? filter?.status : 0}
                            >
                                <option value={0}>Trạng thái</option>
                                <option value={FormStatus.INIT}>{FormStatus.findName(FormStatus.INIT)}</option>
                                <option value={FormStatus.APPROVED}>{FormStatus.findName(FormStatus.APPROVED)}</option>
                                <option value={FormStatus.REJECTED}>{FormStatus.findName(FormStatus.REJECTED)}</option>
                                <option value={FormStatus.SENT_GIFT}>{FormStatus.findName(FormStatus.SENT_GIFT)}</option>
                                <option value={FormStatus.RECEIVED}>{FormStatus.findName(FormStatus.RECEIVED)}</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <RangePicker
                                value={[
                                    moment(start_time, dateFormat),
                                    moment(end_time, dateFormat),
                                ]}
                                onChange={changeCalendar}
                                format={dateFormat}
                            />
                        </div>
                        <div className="form-group">
                            <select
                                className="form-control"
                                onChange={e =>
                                    setFilter({ ...filter, status: Number(e.target.value) })
                                }
                                value={filter?.status ? filter?.status : 0}
                            >
                                <option value={0}>Trạng thái</option>
                                <option value={FormStatus.INIT}>{FormStatus.findName(FormStatus.INIT)}</option>
                                <option value={FormStatus.APPROVED}>{FormStatus.findName(FormStatus.APPROVED)}</option>
                                <option value={FormStatus.REJECTED}>{FormStatus.findName(FormStatus.REJECTED)}</option>
                                <option value={FormStatus.SENT_GIFT}>{FormStatus.findName(FormStatus.SENT_GIFT)}</option>
                                <option value={FormStatus.RECEIVED}>{FormStatus.findName(FormStatus.RECEIVED)}</option>
                            </select>
                        </div>
                        <div style={{ width: '65px' }} className="form-group">
                            <Button
                                color="primary"
                                className="btn btn-primary "
                                size={24}
                                onClick={export_excel}
                            >
                                Export
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    )
}

export default LoyaltyInvoiceTableForm
