import React, { useEffect, useState } from "react"

import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Row,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import { DatePicker, Select } from "antd"
import moment from "moment"

import ArticleModel from "api/ArticleModel"
import { success } from "helpers/notification"
import { error } from "helpers/notification"
import { useHistory } from "react-router-dom"
import * as PostStatus from "constants/PostStatus"
import { PopupConfirm } from "components/pop-confirm"
import BroadcastModel from 'api/BroadcastModel';
import BroadcastType from "constants/BroadcastType"
import * as queryString from 'query-string';
import AudienceInput from "pages/audience/form/audience-input"
import TopicSurveyModel from "api/TopicSurveyModel"
import LinkUtil from "helpers/LinkUtil"

const CreateBroadcast = props => {
    const [validation, setValidation] = useState([])
    const [isSubmit, setIsSubmit] = useState(false)
    const [post, setPost] = useState([])
    const [topicSurveys, setTopicSurveys] = useState([])
    const [estModal, setEstModal] = useState(false);


    let query = queryString.parse(props.location.search)

    const [field, setField] = useState({
        timeStart: moment().toDate().getTime(),
        type: query?.type || 0,
        content: {
            topicId: query?.topicId ? Number(query?.topicId) : 0,
            postIds: query?.postIds ? LinkUtil.convert2Array(query?.postIds) : [],
            msg: null,
            promotionId: query.promotionId,
            started: false
        },
        audienceId: query.audienceId
    })


    const history = useHistory()


    const create = async () => {
        setIsSubmit(true)
        let isError = validate()
        if (isError) {
            return
        }
        let result = await BroadcastModel.create(field)
        if (result.error != 0) {
            error(result.msg)
            return
        }
        success("Tạo thành công!")
        history.push(`/broadcasts`)
        setEstModal(false)
    }

    const validate = () => {
        let error = false
        let validation = {}

        if (!field?.name) {
            error = true
            validation = { ...validation, name: "Vui lòng nhập tiêu đề" }
        }

        if (!field?.audienceId && !(field?.content?.postIds?.length >= 2)) {
            error = true
            validation = { ...validation, audienceId: "Vui lòng chon đối tượng" }
        }

        if (!field?.timeStart) {
            error = true
            validation = { ...validation, timeStart: "Vui lòng nhập thời gian" }
        }

        if (!field?.type || field.type <= 0) {
            error = true
            validation = { ...validation, type: "Vui lòng chọn loại broadcast" }
        }

        if (field.type == BroadcastType.SURVEY && (!field?.content?.topicId || field.content.topicId <= 0)) {
            error = true
            validation = { ...validation, topicId: "Vui lòng chọn chủ đề" }
        }

        setValidation(validation)
        return error
    }

    const getPost = async function (e) {
        let result = await ArticleModel.getArticles(
            null,
            PostStatus.APPROVED,
            null,
            false,
            1,
            100
        )
        setPost(result.data.list)
    }

    const getSurveys = async function (e) {
        let result = await TopicSurveyModel.find(1, 20);
        setTopicSurveys(result.data.list)
    }

    useEffect(() => {
        getPost()
        getSurveys()
    }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Broadcast"} breadcrumbItem="Tạo broadcast mới" />
                    <Card>
                        <CardBody>
                            <Row>
                                <div className="mb-3">
                                    <Label htmlFor="name">
                                        Tiêu đề{" "}
                                        {
                                            <span style={{ color: "red" }}>
                                                {validation["name"]}
                                            </span>
                                        }
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        placeholder="Nhập tiêu đề broadcast"
                                        onChange={e => {
                                            setField({ ...field, name: e.target.value })
                                        }}
                                        invalid={isSubmit && validation["title"] != null}
                                        value={field.title}
                                    />
                                </div>
                            </Row>
                            <Row>
                                {!(field?.content?.postIds?.length >= 2) && <Col sm={3}>
                                    <div className="mb-3">
                                        <Label htmlFor="roleIds">Đối tượng?
                                            {" "}
                                            {
                                                <span style={{ color: "red" }}>
                                                    {validation["audienceId"] || ''}
                                                </span>
                                            }
                                        </Label>
                                        <AudienceInput
                                            value={field.audienceId}
                                            onChange={(value) => {
                                                setField({ ...field, audienceId: value })
                                            }} />
                                    </div>
                                </Col>
                                }
                                <Col sm={3}>
                                    <div className="mb-3">
                                        <Label htmlFor="timeStart">
                                            Thời gian gửi?
                                            {" "}
                                            {
                                                <span style={{ color: "red" }}>
                                                    {validation["timeStart"] || ''}
                                                </span>
                                            }
                                        </Label>
                                        <br />

                                        <DatePicker
                                            className="form-control"
                                            format="YYYY-MM-DD HH:mm"
                                            value={moment(field?.timeStart)}
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm'),
                                            }}
                                            onChange={data => {
                                                console.log(data.toDate())
                                                setField({ ...field, timeStart: data.toDate().getTime() })
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col sm={3}>
                                    <div className="mb-3">
                                        <Label htmlFor="type">Loại broadcast?
                                            {" "}
                                            {
                                                <span style={{ color: "red" }}>
                                                    {validation["type"] || ''}
                                                </span>
                                            }
                                        </Label>
                                        <select
                                            className="form-control"
                                            value={field?.type}
                                            onChange={e =>
                                                setField({ ...field, type: Number(e.target.value), content: {} })
                                            }
                                        >
                                            <option value={0}>None</option>
                                            <option value={BroadcastType.ZNS}>Gửi ZNS</option>
                                            <option value={BroadcastType.POST}>Gửi bài viết</option>
                                            <option value={BroadcastType.MSG}>Gửi tin nhắn</option>
                                            <option value={BroadcastType.SURVEY}>Khảo sát</option>
                                            <option value={BroadcastType.AS_FAST_AS_FIGHTING_MINI_GAME}>Nhanh Như Chớp</option>
                                            <option value={BroadcastType.LIVE_STREAM_BROADCAST_PROMOTION}>Live Stream</option>

                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>

                                    {field?.type == BroadcastType.SURVEY &&
                                        < div className="mb-3">
                                            <Label htmlFor="type">Chủ đề khảo sát
                                                {" "}
                                                {
                                                    <span style={{ color: "red" }}>
                                                        {validation["topicId"] || ''}
                                                    </span>
                                                }
                                            </Label>
                                            <select
                                                className="form-control"
                                                value={field?.content?.topicId}
                                                onChange={e =>
                                                    setField({ ...field, content: { msg: undefined, postId: undefined, topicId: Number(e.target.value) } })
                                                }
                                            >
                                                <option value={0}>None</option>
                                                {
                                                    topicSurveys && topicSurveys.map((topic, index) => {
                                                        return <option key={index} value={topic.id}>{topic.title}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    }
                                </Col>
                            </Row>
                            <Row>

                                {field?.type == BroadcastType.POST && (
                                    <Col sm={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="promotion">Bài viết</Label>
                                            <Select
                                                mode="multiple"
                                                placeholder="Please select"
                                                value={field?.content?.postIds || []}
                                                onChange={(values) => {
                                                    setField({ ...field, content: { postIds: values, msg: undefined, topicId: undefined } })
                                                }}
                                                style={{ width: '100%' }}
                                                options={post.map(item => ({
                                                    value: item.id,
                                                    label: item.title
                                                }))}
                                            />
                                        </div>
                                    </Col>
                                )}

                                {field?.type == BroadcastType.MSG && (
                                    <Col sm={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="promotion">Tin nhắn</Label>

                                            <Input type="textarea" value={field?.content.msg} onChange={e => {
                                                setField({ ...field, content: { msg: e.target.value, postId: undefined, topicId: undefined } })
                                            }}></Input>
                                        </div>
                                    </Col>
                                )}

                                {field?.type == BroadcastType.AS_FAST_AS_FIGHTING_MINI_GAME && (
                                    <Col sm={2}>
                                        <Label htmlFor="promotion">Đã bắt đầu</Label>
                                        <div
                                            className="form-check form-switch form-switch-lg mb-3"
                                        >
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                defaultChecked={field?.content?.started || false}
                                                onClick={(value) => {
                                                    setField({ ...field, content: { ...field?.content, started: value.target.checked } })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                )}

                            </Row>

                            <div className="mb-3 justify-content-end">
                                <button
                                    onClick={() => {
                                        if (!validate()) {
                                            setEstModal(true)
                                        }
                                    }}
                                    className="btn btn-primary w-md "
                                    style={{ float: "right" }}
                                >
                                    Tạo broadcast
                                </button>
                            </div>

                            {estModal && (
                                <PopupConfirm
                                    isOpen={estModal}
                                    toggle={() => setEstModal(false)}
                                    handleSubmit={create}
                                    title="Bạn có muốn tạo broadcast này?"
                                    content={{ name: field.name, size: field?.userIds?.length }}
                                />
                            )}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    )
}

export default CreateBroadcast
