import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import React, { useEffect, useState } from "react";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
    });

const UploadImage = (props) => {

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        const _images = props.exits_images
            ? props.exits_images.map(_ => ({ uid: Math.random(), url: _.url })) : []
        if (_images) {
            setFileList(_images)
        }
    }, [props])

    const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        let tmp = newFileList.filter((file) => {
            return file?.response?.error == 0;
        }).map((file) => {
            return { url: file?.response?.data[0]?.url };
        });
        if (tmp.length > 0) {
            props.change_images(tmp);
        }
    };


    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Tải ảnh
            </div>
        </div>
    );

    return (
        <>
            <Upload
                action="https://udev.com.vn/api/image/upload"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                name={`images`}
                disabled={props.disabled}
                accept="image/*"
            >
                {fileList.length >= props.size ? null : uploadButton}
            </Upload>
            <Modal
                open={previewVisible}
                title={previewTitle}
                onCancel={handleCancel}
            >
                <img
                    alt="example"
                    style={{
                        width: "100%",
                    }}
                    src={previewImage}
                />
            </Modal>
        </>
    );
};

export default UploadImage;
