// TableMember

import { Table } from "antd"
import UserModel from "api/UserModel"
import React, { useEffect, useState } from "react"

const TableCountLabel = props => {
    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Label",
            dataIndex: "label",
        },
        {
            title: "Số lượng thành viên",
            dataIndex: "numOfUser",
        },
    ]
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState({})

    const fetchData = async () => {
        setLoading(true)
        let { start_time, end_time } = props;
        let start = Math.floor(
            start_time.toDate().getTime() / 1000
        )
        let end = Math.floor(
            end_time.toDate().getTime() / 1000
        )
        let results = await UserModel.countLabels(start, end);
        setData(results?.data)
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [filter])

    const handleTableChange = (newPagination, filters, sorter) => {
        // setPagination(newPagination)
        fetchData()
    }

    return (
        <>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                // pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    )
}

export default TableCountLabel
