import * as ApiHelper from '../api_helper'

class TopicModel {
    async find() {
        return ApiHelper.get(`chat/topic/find`)
    }

}

export default new TopicModel();
