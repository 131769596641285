import * as ApiHelper from './api_helper'

class PromotionModel {
    async getPromotions(types, start, end, timeActive, page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                types,
                start, end, timeActive,
                page: page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`promotion/list?${new URLSearchParams(params).toString()}`)
    }

    async create(form) {
        let data = Object.fromEntries(Object.entries({ ...form }).filter(([_, v]) => v != null && v != 0));
        return ApiHelper.post2(`promotion/create`, data);
    }

    async updateStatus(id, status) {
        return ApiHelper.get(`promotion/update-status?id=${id}&status=${status}`);
    }

    async get(id) {
        return ApiHelper.get(`promotion/get?id=${id}`);
    }

    async update(id, form) {
        let data = Object.fromEntries(Object.entries({ ...form }).filter(([_, v]) => v != null && v != 0));
        return ApiHelper.post2(`promotion/update?id=${id}`, data);
    }

    async toggleStatushotDeal(form) {
        let data = Object.fromEntries(Object.entries({ ...form }).filter(([_, v]) => v != null && v != 0));
        return ApiHelper.post(`promotion/toggle-status-hot-deal`, data);
    }
}

export default new PromotionModel();
