import React, { useEffect, useRef, useState } from "react"

import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import { DatePicker, Popconfirm } from "antd"
import moment from "moment"
import UploadImage from "components/UploadImage"
import ArticleModel from "api/ArticleModel"
import { success } from "helpers/notification"
import { error } from "helpers/notification"
import { useHistory } from "react-router-dom"
import MyEditor from "components/CKEditor"
import PromotionModel from "api/PromotionModel"
import AudienceInput from '../audience/form/audience-input'
import { CategoryPost } from "constants/PostType"
import GeneratorTemplateModal from "./modal/GeneratorTemplateModal"

const dateFormat = "DD/MM/YYYY"

const CreateArticles = props => {
    const id = props?.match?.params?.id;
    const editorRef = useRef()
    const history = useHistory();
    const [validation, setValidation] = useState([])
    const [isSubmit, setIsSubmit] = useState(false)
    const [promotions, setPromotions] = useState([])
    const [isMapPromotion, setIsMapPromotion] = useState(false);
    const [field, setField] = useState({
        timeStart: moment().subtract(60, "days").toDate().getTime()
    })
    const [loading, setLoading] = useState(false)

    const [genTempPopup, setGenTempPopup] = useState({
        isShow: false
    })

    const change_images = data => {
        setField({ ...field, cover: data[0]?.url })
    }

    const submit = async (params) => {
        let isError = validate()
        if (isError) {
            return
        }

        let result = await ArticleModel.createOrUpdate({
            ...field,
            content: editorRef.current.getContent(),
            ...params
        })

        if (result.error != 0) {
            error(result.msg)
            return
        }

        success("Thành công!")
        if (!id) {
            history.push(`/articles`)
        }
    }

    const getPost = async id => {
        setLoading(true)
        let result = await ArticleModel.get(id)
        setField(result.data)
        if (result.data?.promotionId > 0) {
            setIsMapPromotion(true)
        }
        setLoading(false)
    }

    const validate = () => {
        let isError = false
        let tmp = {}
        if (!field?.title) {
            isError = true
            tmp = { ...tmp, title: "Thiếu tiêu đề" }
        }
        if (!field?.audienceId) {
            isError = true
            tmp = { ...tmp, audienceId: "Đối tượng không được để trống" }
        }
        if (!field?.summary) {
            isError = true
            tmp = { ...tmp, summary: "Thiếu mô tả" }
        }

        if (!field?.categoryId || field.categoryId <= 0) {
            isError = true
            tmp = { ...tmp, categoryId: "Vui lòng chọn thể loại bài viết" }
        }

        console.log(field)
        if (field?.template && !field.name) {
            isError = true
            tmp = { ...tmp, name: "Vui lòng nhập tên template" }
        }

        if (!editorRef.current.getContent()) {
            isError = true
            tmp = { ...tmp, content: "Thiếu nội dung" }
        }
        if (!field?.cover) {
            isError = true
            tmp = { ...tmp, cover: "Thiếu ảnh bìa" }
        }


        setValidation(tmp)
        return isError
    }

    const getPromotion = async () => {
        let result = await PromotionModel.getPromotions(null, null, null, null, 1, 20)
        setPromotions(result.data.list)
    }

    useEffect(() => {
        getPromotion()
    }, [])

    useEffect(() => {
        if (id) {
            getPost(id)
        }
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Bài viết"} breadcrumbItem="Tạo bài viết mới" />
                    <Card>
                        <CardBody>
                            {!loading &&
                                <Form>
                                    <Row>
                                        <Col sm={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="title">
                                                    Tiêu đề{" "}
                                                    {
                                                        <span style={{ color: "red" }}>
                                                            {validation["title"]}
                                                        </span>
                                                    }
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="title"
                                                    placeholder="Nhập tiêu đề bài viết"
                                                    onChange={e => {
                                                        setField({ ...field, title: e.target.value })
                                                    }}
                                                    invalid={isSubmit && validation["title"] != null}
                                                    defaultValue={field.title}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={2}>
                                            <Label htmlFor="promotion">Xem trước</Label>
                                            <div
                                                className="form-check form-switch form-switch-lg mb-3"
                                            >
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    defaultChecked={field?.preview || false}
                                                    onClick={(value) => {
                                                        setField({ ...field, preview: value.target.checked })
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <Label htmlFor="promotion">Liên kết chương trình</Label>
                                            <div
                                                className="form-check form-switch form-switch-lg mb-3"
                                            >
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={isMapPromotion}
                                                    onChange={(value) => {
                                                        setIsMapPromotion(value.target.checked)
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <Label htmlFor="promotion">Template</Label>
                                            <div
                                                className="form-check form-switch form-switch-lg mb-3"
                                            >
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    defaultChecked={field?.template || false}
                                                    onClick={(value) => {
                                                        setField({ ...field, template: value.target.checked })
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={3}>
                                            <div className="mb-3">
                                                <Label htmlFor="roleIds">Đối tượng?
                                                    {" "}
                                                    {
                                                        <span style={{ color: "red" }}>
                                                            {validation["audienceId"] || ''}
                                                        </span>
                                                    }
                                                </Label>
                                                <AudienceInput
                                                    value={field.audienceId}
                                                    onChange={(value) => {
                                                        setField({ ...field, audienceId: value })
                                                    }} />
                                            </div>
                                        </Col>
                                        <Col sm={3}>
                                            <Label htmlFor="duration" style={{ width: "100%" }}>
                                                Thời gian bắt đầu
                                            </Label>
                                            <DatePicker
                                                className="form-control"
                                                style={{ padding: '0.43rem 0.75rem' }}
                                                value={
                                                    moment.unix(field.timeStart / 1000)
                                                }
                                                onChange={(value) => {
                                                    setField({ ...field, timeStart: moment(value).toDate().getTime() })
                                                }}
                                                format={dateFormat}
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <Label htmlFor="duration" style={{ width: "100%" }}>
                                                Thể loại
                                                {" "}
                                                {
                                                    <span style={{ color: "red" }}>
                                                        {validation["categoryId"] || ''}
                                                    </span>
                                                }{" "}
                                            </Label>
                                            <select
                                                className="form-control"
                                                value={field.categoryId}
                                                onChange={e => setField({ ...field, categoryId: Number(e.target.value) })}>
                                                <option value={0}>Chọn...</option>
                                                <option value={CategoryPost.NEWS}>Tin tức</option>
                                                <option value={CategoryPost.TIPS_AND_ADVICE}>Mẹo và tư vấn</option>
                                                <option value={CategoryPost.PROMOTION}>Chương trình</option>
                                            </select>
                                        </Col>

                                        {isMapPromotion &&
                                            <Col md={3}>
                                                <div className="mb-3">
                                                    <Label htmlFor="promotion">Chương trình</Label>
                                                    <select
                                                        id="promotion"
                                                        className="form-control"
                                                        value={field.promotionId}
                                                        onChange={e => setField({ ...field, promotionId: Number(e.target.value) })}>
                                                        <option value={0}>Chọn...</option>
                                                        {promotions
                                                            .map(item => (
                                                                <option value={item.id} key={item.id}>
                                                                    {item.title}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                            </Col>
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <div className="mb-3">
                                                <Label htmlFor="title">
                                                    Tên template{" "}
                                                    {
                                                        <span style={{ color: "red" }}>
                                                            {validation["name"]}
                                                        </span>
                                                    }
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    placeholder="Nhập tên template"
                                                    onChange={e => {
                                                        setField({ ...field, name: e.target.value })
                                                    }}
                                                    invalid={isSubmit && validation["name"] != null}
                                                    defaultValue={field.name}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="mb-3">
                                        <Label htmlFor="summary">
                                            Tóm tắt (<b style={{ color: 'red' }}>{field?.summary?.length || 0}</b>/250 ký tự){" "}
                                            {
                                                <span style={{ color: "red" }}>
                                                    {validation["summary"] || ''}
                                                </span>
                                            }{" "}
                                        </Label>
                                        <textarea
                                            name="textarea"
                                            label="Textarea"
                                            rows="4"
                                            className={`form-control ${validation["summary"] ? "is-invalid" : ""}`}
                                            spellCheck="false"
                                            onChange={e =>
                                                setField({ ...field, summary: e.target.value })
                                            }
                                            value={field.summary}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <Label htmlFor="content">
                                            Nội dung{" "}
                                            {
                                                <span style={{ color: "red" }}>
                                                    {validation["content"] || ''}
                                                </span>
                                            }
                                        </Label>
                                        <MyEditor
                                            ref={editorRef}
                                            data={field?.content}
                                            setData={data => {
                                            }}
                                        />
                                    </div>

                                    <div className="mb-3 justify-content-end">
                                        <Label htmlFor="duration">
                                            Ảnh đại diện{" "}
                                            {
                                                <span style={{ color: "red" }}>
                                                    {validation["cover"] || ''}
                                                </span>
                                            }
                                        </Label>
                                        <Row className="mb-3">
                                            <Col sm={12}>
                                                <UploadImage change_images={change_images} size={1}
                                                    exits_images={field?.cover ? [{ url: field?.cover }] : []} />
                                            </Col>
                                        </Row>
                                    </div>
                                    {id && field && field.template &&
                                        <div className="mb-3 justify-content-end">
                                            <button
                                                style={{ float: 'right', marginLeft: '15px' }}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setGenTempPopup({ isShow: true })
                                                }}
                                                className="btn btn-success w-md "
                                            >
                                                Generator
                                            </button>
                                        </div>
                                    }
                                    <div className="mb-3 justify-content-end">
                                        <Popconfirm
                                            title="Bạn có chắc?"
                                            onConfirm={() => {
                                                submit()
                                            }}
                                        >
                                            <button
                                                style={{ float: 'right' }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                }}
                                                className="btn btn-primary w-md "
                                            >
                                                Lưu
                                            </button>
                                        </Popconfirm>
                                    </div>
                                </Form>
                            }
                        </CardBody>
                    </Card>
                    <GeneratorTemplateModal
                        post={field}
                        isOpen={genTempPopup.isShow}
                        toggle={() => setGenTempPopup({ isShow: false })}
                        updataTemplate={(data) => {
                            submit({ dataTemplate: data })
                        }}
                        title="Generator"
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateArticles
