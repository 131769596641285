// TableMember

import { Avatar, Table, Tag } from "antd"
import UserModel from "api/UserModel"
import React, { useEffect, useMemo, useState } from "react"

import * as UserStatus from "constants/UserStatus"
import * as UserType from "constants/UserType"
import TimeUtils from "helpers/time.utils"
import { City } from "helpers/ProvinceUtil"
import { useHistory, useLocation } from "react-router-dom"
import {
    Button, Col,
} from "reactstrap"
import { Row } from "reactstrap"
import LabelModel from "api/LabelModel"
import queryString from 'query-string'
import { error } from 'helpers/notification';



const TableMember = props => {
    let history = useHistory();
    let columns = [
        {
            title: "#",
            dataIndex: "id",
        },
        {
            title: "INSEE ID",
            dataIndex: "inseeId"
        },
        {
            title: "Ảnh",
            dataIndex: "avatar",
            render: avatar => <Avatar src={avatar} size="large" />,
        },
        {
            title: "Họ tên",
            dataIndex: "name",
        },
        {
            title: "SDT",
            dataIndex: "phone",
        },
        {
            title: "Tỉnh/Thành Phố",
            dataIndex: "cityId",
            render: cityId => City.getName(cityId),
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: status => (
                <Tag color={UserStatus.findColor(status)} key={status}>
                    {UserStatus.findName(status)}
                </Tag>
            ),
        },
        {
            title: "Ngày đăng ký",
            dataIndex: "createdTime",
            render: createdTime => TimeUtils.toDDMMYYYY(createdTime * 1000),
        },
        {
            title: "Hành động",
            dataIndex: "id",
            render: id => (
                <Button color="primary" className="btn btn-primary" onClick={() => history.push(`/members/profile/${id}`)}>
                    Chi tiết
                </Button>
            ),
        },
    ]
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [filter, setFilter] = useState({ ...props.filter, ...queryString.parseUrl(history.location.search).query })
    const [labels, setLabels] = useState([])

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.replace({ search: url })
    };

    useMemo(async () => {
        let results = await LabelModel.getLabels()
        setLabels(results?.data)
    }, [])

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await UserModel.getMembers(
            filter?.search,
            filter?.labelId,
            filter?.roleId,
            filter?.city,
            filter?.status,
            newPagination.current,
            newPagination.pageSize
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData({ current: 1, pageSize: pagination.pageSize })
        serialize2Url()
    }, [filter])

    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    const export_excel = () => {
        let { status, city, roleId, labelId } = filter
        if (pagination.total > 500) {
            error("Không thể xuất file lớn 500 dòng")
            return;
        }
        UserModel.export(status, city, labelId, roleId)
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="text-sm-end">
                        <div className="btn-group mb-3" role="group">
                            <select
                                className="form-control"
                                style={{ marginLeft: "10px" }}
                                onChange={e =>
                                    setFilter({ ...filter, labelId: Number(e.target.value) })
                                }
                                value={filter?.labelId ? filter?.labelId : 0}
                            >
                                <option value={0}>Labels</option>
                                {labels?.map(item => (
                                    <option value={item.id} key={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                            <input
                                className="form-control"
                                type="search"
                                placeholder="SDT hoặc tên khách hàng"
                                style={{ marginLeft: "10px" }}
                                onChange={e => setFilter({ ...filter, search: e.target.value })}
                            />
                            {!props?.filter?.roleId && (
                                <select
                                    className="form-control"
                                    style={{ marginLeft: "10px" }}
                                    onChange={e =>
                                        setFilter({ ...filter, roleId: Number(e.target.value) })
                                    }
                                    value={filter?.roleId ? filter?.roleId : 0}
                                >
                                    <option value={0}>Khách hàng</option>
                                    <option value={UserType.CONSTRUCTOR}>
                                        {UserType.findName(UserType.CONSTRUCTOR)}
                                    </option>
                                    <option value={UserType.RETAILER}>
                                        {UserType.findName(UserType.RETAILER)}
                                    </option>
                                </select>
                            )}
                            <select
                                className="form-control"
                                style={{ marginLeft: "10px" }}
                                onChange={e =>
                                    setFilter({ ...filter, city: Number(e.target.value) })
                                }
                                value={filter?.city ? filter?.city : 0}
                            >
                                <option value={0}>Tỉnh/Thành Phố</option>
                                {City.getList().map(value => {
                                    return (
                                        <option key={value.key} value={value.key}>
                                            {value.value}
                                        </option>
                                    )
                                })}
                            </select>
                            <select
                                className="form-control"
                                style={{ marginLeft: "10px" }}
                                onChange={e =>
                                    setFilter({ ...filter, status: Number(e.target.value) })
                                }
                                value={filter?.status ? filter?.status : 0}
                            >
                                <option value={0}>Trạng thái</option>
                                <option value={UserStatus.APPROVED}>Đã duyệt</option>
                                <option value={UserStatus.WAITING_ACTIVE}>Chờ active</option>
                                <option value={UserStatus.WAIT_APPROVAL}>Chờ duyệt</option>
                                <option value={UserStatus.WAIT_COMPLETE_PROFILE}>
                                    Chờ hoàn thành hồ sơ
                                </option>
                            </select>
                            <Button
                                color="primary"
                                className="btn btn-primary "
                                style={{ marginLeft: "10px" }}
                                size={24}
                                onClick={export_excel}
                            >
                                Export
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    )
}

export default TableMember
