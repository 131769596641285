// TableMember

import { Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import { City } from "helpers/ProvinceUtil"
import { useHistory, useParams } from "react-router-dom"
import queryString from 'query-string'

import {
    Row, Col,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap"
import classnames from "classnames";
import AccumulationModel from "api/AccumulationModel"
import { Button } from 'reactstrap';

const AccumulationTable = props => {
    let { promotionId } = useParams();
    let history = useHistory();
    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Họ tên",
            dataIndex: "user",
            render: user => user.name
        },
        {
            title: "SDT",
            dataIndex: "user",
            render: user => user.phone
        },
        {
            title: "Tỉnh/Thành Phố",
            dataIndex: "user",
            render: user => City.getName(user?.cityId),
        },
        {
            title: "Số bao",
            dataIndex: "historyBags",
            render: historyBags => historyBags
        },
        {
            title: "Số lượt đã quay",
            dataIndex: "countGift",
            render: countGift => countGift
        },
        {
            title: "Action",
            dataIndex: "user",
            render: user => (
                <Button color="primary"
                    className="btn btn-primary"
                    onClick={() => history.push(`/members/profile/${user.id}`)}>
                    Chi tiết
                </Button>
            )
        }
    ]
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })

    const [filter, setFilter] = useState({
        ...props.filter,
        ...pagination,
        ...queryString.parseUrl(history.location.search).query
    })

    useEffect(() => {
        setFilter({
            ...props.filter,
            ...pagination,
            ...queryString.parseUrl(history.location.search).query
        })
    }, [history.location.search])

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.push({ search: url })
    };

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await AccumulationModel.find(
            {
                search: filter?.search,
                cityId: filter?.cityId,
                promotionId,
                page: filter?.current,
                pageSize: filter?.pageSize
            }
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData({ current: filter.current, pageSize: filter.pageSize })
        serialize2Url()
    }, [filter])


    const handleTableChange = (newPagination, filters, sorter) => {
        setFilter({ ...filters, current: newPagination.current, pageSize: newPagination.pageSize })
    }

    const export_excel = () => {
        let { cityId, search } = filter;
        AccumulationModel.export({ cityId, search, promotionId })
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="text-sm-end mb-3">
                        <Row>
                            <Col sm={4}>
                                <Nav pills>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: false
                                            })}
                                            onClick={() => {
                                                history.push(`/forms?promotionId=${promotionId}`)
                                            }}
                                        >
                                            <i className="bx bx-chat font-size-20 d-sm-none" />
                                            <span className="d-none d-sm-block">Đơn tham gia</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: false
                                            })}
                                            onClick={() => {
                                                history.push(`/gift-order/${promotionId}`)
                                            }}
                                        >
                                            <i className="bx bx-chat font-size-20 d-sm-none" />
                                            <span className="d-none d-sm-block">Quà tặng</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: true
                                            })}
                                            onClick={() => {
                                                history.push(`/accumulation/${promotionId}`)
                                            }}
                                        >
                                            <i className="bx bx-chat font-size-20 d-sm-none" />
                                            <span className="d-none d-sm-block">Bảng xếp hạng</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                            <Col sm={8}>
                                <div className="btn-group" role="group">
                                    <input
                                        className="form-control"
                                        type="search"
                                        placeholder="SDT hoặc tên khách hàng"
                                        style={{ marginLeft: "10px" }}
                                        onChange={e => setFilter({ ...filter, search: e.target.value })}
                                    />
                                    <select
                                        className="form-control"
                                        style={{ marginLeft: "10px" }}
                                        onChange={e =>
                                            setFilter({ ...filter, cityId: Number(e.target.value) })
                                        }
                                        value={filter?.cityId ? filter?.cityId : 0}
                                    >
                                        <option value={0}>Tỉnh/Thành Phố</option>
                                        {City.getList().map(value => {
                                            return (
                                                <option key={value.key} value={value.key}>
                                                    {value.value}
                                                </option>
                                            )
                                        })}
                                    </select>
                                    <Button
                                        color="primary"
                                        className="btn btn-primary "
                                        style={{ marginLeft: "10px" }}
                                        size={24}
                                        onClick={export_excel}
                                    >
                                        Export
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    )
}

export default AccumulationTable
