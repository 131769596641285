import { Table } from "antd"
import FormModel from "api/FormModel"
import React, { useEffect, useState } from "react"

const TableTopPromotion = props => {
    let columns = [
        {
            title: "ID",
            dataIndex: "promotionId",
        },
        {
            title: "Chương trình",
            dataIndex: "promotion",
            render: promotion => promotion.title
        },
        {
            title: "Số lượng",
            dataIndex: "total",
        },
    ]
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)

    const fetchData = async () => {
        setLoading(true)
        let { start_time, end_time } = props;
        let start = Math.floor(
            start_time.toDate().getTime() / 1000
        )
        let end = Math.floor(
            end_time.toDate().getTime() / 1000
        )
        let results = await FormModel.getTopPromotion(start, end);
        setData(results?.data)
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleTableChange = (newPagination, filters, sorter) => {
        fetchData()
    }

    return (
        <>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={false}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    )
}

export default TableTopPromotion
