import * as ApiHelper from '../api_helper'


class TotalForceFormModel {
    async getForms({ search, status, userId, cityId, promotionId, page, pageSize }) {
        let params = Object.fromEntries(
            Object.entries({
                search, status, userId, cityId, promotionId,
                page, pageSize
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`total-force/form/find?${new URLSearchParams(params).toString()}`)
    }

    async getForm(formId) {
        return ApiHelper.get(`total-force/form/get?id=${formId}`)
    }

}

export default new TotalForceFormModel();