import React from "react";
import GiftOrderStatus from "constants/GiftOrderStatus";
import GiftOrderType from "constants/GiftOrderType";
import TimeUtils from "helpers/time.utils";
import { Image, Tag} from "antd"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Table,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"


const ListGift = (giftOrder) => {
    return (
        <>
            <Breadcrumbs title={"Chi tiết"} breadcrumbItem="Quà tặng" />
            <Card>
                <CardBody>
                    <Row>
                        <Col sm="3">
                            <div className="table-responsive">
                                <Table className="table-nowrap mb-0">
                                    <tbody>
                                        <tr>
                                            <th scope="row">ID :</th>
                                            <td>{giftOrder.id}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Số lượng :</th>
                                            <td>{giftOrder.amount}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Số điểm :</th>
                                            <td>{giftOrder.point}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Trạng thái :</th>
                                            <td>
                                                <Tag color={GiftOrderStatus.findColor(giftOrder.status)}>
                                                    {GiftOrderStatus.findName(giftOrder.status)}
                                                </Tag>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Loại đơn:</th>
                                            <td>{GiftOrderType.findName(giftOrder.type)}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Ngày tạo:</th>
                                            <td>
                                                {TimeUtils.toDDMMYYYY(giftOrder.createdTime * 1000)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col sm="9">
                            <div className="table-responsive">
                                <Table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Ảnh</th>
                                            <th>Tên quà</th>
                                            <th>Số điểm</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {giftOrder.gift && (
                                            <tr key={giftOrder.gift.id}>
                                                <th scope="row">{giftOrder.gift.id}</th>
                                                <td><Image src={giftOrder.gift.link} width={70} /></td>
                                                <td>{giftOrder.gift.name}</td>
                                                <td>{giftOrder.gift.point}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default ListGift;