// TableMember

import { Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import { City } from "helpers/ProvinceUtil"
import { useHistory } from "react-router-dom"
import queryString from 'query-string'

import {
    Row, Col,
} from "reactstrap"
import { Button } from 'reactstrap';
import ConstructorRetailerModel from "api/ConstructorRetailerModel"
import * as CementUtils from "helpers/CementUtil"

const VerifiedStoreTable = props => {
    let history = useHistory();
    let columns = [
        {
            title: "Họ tên",
            dataIndex: "user",
            render: user => user.name
        },
        {
            title: "SDT",
            dataIndex: "user",
            render: user => user.phone
        },
        {
            title: "Tỉnh/Thành Phố",
            dataIndex: "user",
            render: user => City.getName(user?.cityId),
        },
        {
            title: CementUtils.POWERS.name,
            dataIndex: "salesVolume",
            render: salesVolume => {
                return <b>{salesVolume[CementUtils.POWERS.id] || 0}</b>
            }
        },
        {
            title: CementUtils.WALL_PRO.name,
            dataIndex: "salesVolume",
            render: salesVolume => {
                return <b>{salesVolume[CementUtils.WALL_PRO.id] || 0}</b>
            }
        },
        {
            title: CementUtils.LAVILLA_EXTRA_CC40.name,
            dataIndex: "salesVolume",
            render: salesVolume => {
                return <b>{salesVolume[CementUtils.LAVILLA_EXTRA_CC40.id] || 0}</b>
            }
        },
        {
            title: CementUtils.EXTRA_DURABLE_HOUSING.name,
            dataIndex: "salesVolume",
            render: salesVolume => {
                return <b>{salesVolume[CementUtils.EXTRA_DURABLE_HOUSING.id] || 0}</b>
            }
        },
        {
            title: "Action",
            dataIndex: "user",
            render: user => (
                <Button color="primary"
                    className="btn btn-primary"
                    onClick={() => history.push(`/members/profile/${user.id}`)}>
                    Chi tiết
                </Button>
            )
        }
    ]
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)

    const [pagination, setPagination] = useState({
        current: 0,
        pageSize: 10,
    })

    const [filter, setFilter] = useState({
        ...props.filter,
        ...pagination,
        ...queryString.parseUrl(history.location.search).query
    })

    useEffect(() => {
        setFilter({
            ...props.filter,
            ...pagination,
            ...queryString.parseUrl(history.location.search).query
        })
    }, [history.location.search])

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.push({ search: url })
    };

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await ConstructorRetailerModel.findVerifedRetailer(
            {
                search: filter?.search,
                cityId: filter?.cityId,
                districtId: filter?.districtId,
                page: filter?.current,
                pageSize: filter?.pageSize
            }
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData({ current: Number(filter.current), pageSize: filter.pageSize })
        serialize2Url()
    }, [filter])


    const handleTableChange = (newPagination, filters, sorter) => {
        setFilter({
            ...filters, current: Number(newPagination.current),
            pageSize: newPagination.pageSize
        })
        setPagination(newPagination)
    }


    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="text-sm-end mb-3">
                        <Row>
                            <Col sm={4}>
                            </Col>
                            <Col sm={8}>
                                <div className="btn-group" role="group">
                                    <input
                                        className="form-control"
                                        type="search"
                                        placeholder="SDT hoặc tên khách hàng"
                                        style={{ marginLeft: "10px" }}
                                        onChange={e => setFilter({ ...filter, search: e.target.value })}
                                    />
                                    <select
                                        className="form-control"
                                        style={{ marginLeft: "10px" }}
                                        onChange={e =>
                                            setFilter({ ...filter, cityId: Number(e.target.value) })
                                        }
                                        value={filter?.cityId ? filter?.cityId : 0}
                                    >
                                        <option value={0}>Tỉnh/Thành Phố</option>
                                        {City.getList().map(value => {
                                            return (
                                                <option key={value.key} value={value.key}>
                                                    {value.value}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    )
}

export default VerifiedStoreTable
