export const CONSTRUCTOR = 2;
export const RETAILER = 3;
export const DISTRIBUTOR = 4;
export const INDUSTRIAL_CUSTOMER = 5;

export function findName(value) {
    // eslint-disable-next-line default-case
    switch (value) {
        case CONSTRUCTOR: return 'Nhà thầu'
        case RETAILER: return 'Đại lý'
        case DISTRIBUTOR: return 'Nhà Phân Phối'
        case INDUSTRIAL_CUSTOMER: return 'Khách hàng công nghiệp'
        default: return 'Người quan tâm'
    }
}

export function findColor(value) {
    // eslint-disable-next-line default-case
    switch (value) {
        case CONSTRUCTOR: return '#007bff'
        case RETAILER: return '#ffc107'
        case DISTRIBUTOR: return '#17a2b8'
        case INDUSTRIAL_CUSTOMER: return '#dc3545'
    }
}