// TableMember

import { Button, Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import TimeUtils from "helpers/time.utils"
import { Link, useHistory } from "react-router-dom"
import queryString from 'query-string'
import SurveyModel from "api/SurveyModel"

// TableSurvey
const TableSurvey = props => {

    let history = useHistory();

    let columns = [
        {
            title: "ID",
            dataIndex: "broadcastId",
        },
        {
            title: "Tên",
            dataIndex: "name",
        },
        {
            title: "Thời gian",
            dataIndex: "createdTime",
            render: createdTime => TimeUtils.toDDMMYYYY(createdTime * 1000),
        },
        {
            title: "Hành động",
            render: (_, record) => (
                <>
                    <Button type="primary" size="small" style={{ width: "120px" }} onClick={() => history.push(`/survey/detail?broadcastId=${record.broadcastId}`)} >
                        Chi tiết
                    </Button>

                </>
            ),
        },
    ]

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [filter, setFilter] = useState({ ...props.filter, ...queryString.parseUrl(history.location.search).query })

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.replace({ search: url })
    };

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await SurveyModel.find(
            newPagination.current,
            newPagination.pageSize
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData({ current: 1, pageSize: pagination.pageSize })
        serialize2Url()
    }, [filter])

    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    return (
        <>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />

        </>
    )
}

export default TableSurvey
