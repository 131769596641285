import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const ref = useRef();
  useEffect(() => {
    const pathName = props.location.pathname;
    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

            <li className="menu-title">Main</li>

            <li>
              <Link to="/dashboard" >
                <i className="bx bx-home-circle"></i>
                <span>Dashboard</span>
              </Link>
            </li>

            <li className="menu-title">Management</li>

            <li>
              <Link to="/members/" >
                <i className="bx bx-briefcase-alt-2"></i>
                <span>Thành viên</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/members/analytic">Thống kê</Link>
                </li>
                <li>
                  <Link to="/members/constructors">
                    Nhà thầu
                  </Link>
                </li>
                <li>
                  <Link to="/members/retailers">
                    Đại lý
                  </Link>
                </li>
                <li>
                  <Link to="/members/distributors">
                    Nhà Phân Phối
                  </Link>
                </li>
                <li>
                  <Link to="/members/industrials">
                    Khách hàng công nghiệp
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/promotion/" >
                <i className="bx bx-briefcase-alt-2"></i>
                <span>Chương trình</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/promotions/analytic">Thống kê</Link>
                </li>
                <li>
                  <Link to="/promotions/campaigns">
                    Chương trình
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/point" >
                <i className="bx bx-briefcase-alt-2"></i>
                <span>Điểm tích lũy</span>
              </Link>
            </li>
            <li>
              <Link to="/constructions" >
                <i className="bx bx-briefcase-alt-2"></i>
                <span>Công trình</span>
              </Link>
            </li>
            <li>
              <Link to="/stores" >
                <i className="bx bx-briefcase-alt-2"></i>
                <span>Cửa hàng</span>
              </Link>
            </li>
            <li>
              <Link to="/articles" >
                <i className="bx bx-briefcase-alt-2"></i>
                <span>Bài viết</span>
              </Link>
            </li>
            <li>
              <Link to="/broadcasts" >
                <i className="bx bx-briefcase-alt-2"></i>
                <span>Broadcasts</span>
              </Link>
            </li>
            <li>
              <Link to="/surveys" >
                <i className="bx bx-briefcase-alt-2"></i>
                <span>Khảo sát</span>
              </Link>
            </li>
            <li>
              <Link to="/message" >
                <i className="bx bx-briefcase-alt-2"></i>
                <span>Thông điệp</span>
              </Link>
            </li>
            <li>
              <Link to="/gifts" >
                <i className="bx bx-briefcase-alt-2"></i>
                <span>Quà tặng</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/gifts">
                    Danh sách quà tặng
                  </Link>
                </li>
                <li>
                  <Link to="/gifts/orders">Đổi quà</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/audiences" >
                <i className="bx bx-briefcase-alt-2"></i>
                <span>Audience</span>
              </Link>
            </li>
            <li>
              <Link to="/orders" >
                <i className="bx bx-briefcase-alt-2"></i>
                <span>Đặt hàng</span>
              </Link>
            </li>
            <li>
              <Link to="/event/registrations" >
                <i className="bx bx-briefcase-alt-2"></i>
                <span>Sự kiện</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(SidebarContent));
