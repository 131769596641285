import { DatePicker, Image, Select, Tag } from "antd"
import React, { useEffect, useState } from "react"

//Import Breadcrumb
import { useHistory } from "react-router-dom"
import queryString from "query-string"
import PromotionModel from "api/PromotionModel"
import PromotionType from "constants/PromotionType"
import HotdealInvoiceForm from "./invoices/hot-deal"
import LoyaltyInvoiceForm from "./invoices/loyalty"
import PredictFootballForm from './invoices/football'
import QuickQuestionForm from "./invoices/quick-question"
import BasicInvoideForm from "./invoices/basic"
import TotalForceForm from "./invoices/total-force"




const DetailForm = props => {
    const formId = props.match.params.id;
    let history = useHistory()
    const { promotionId } = queryString.parseUrl(history.location.search).query
    const [promotion, setPromotion] = useState()

    const fetchPromotionById = async id => {
        let resp = await PromotionModel.get(id)
        if (resp.error == 0) {
            setPromotion(resp.data)
        }
    }

    useEffect(() => {
        fetchPromotionById(promotionId)
    }, [])


    return (
        <React.Fragment>
            {promotion &&
                <>
                    {promotion.type === PromotionType.HOT_DEAL && <HotdealInvoiceForm promotion={promotion} formId={formId} />}
                    {promotion.type === PromotionType.LOYALTY_PROMOTION && <LoyaltyInvoiceForm promotion={promotion} formId={formId} />}
                    {promotion.type === PromotionType.PREDICT_FOOTBALL && <PredictFootballForm promotion={promotion} formId={formId} />}
                    {promotion.type === PromotionType.QUICK_QUESTION_PROMOTION && <QuickQuestionForm promotion={promotion} formId={formId} />}
                    {promotion.type === PromotionType.NORMAL_INVOICE && <BasicInvoideForm promotion={promotion} formId={formId} />}
                    {promotion.type === PromotionType.TOTAL_FORCE_PROMOTION && <TotalForceForm promotion={promotion} formId={formId} />}
                </>
            }

        </React.Fragment>
    )
}

export default DetailForm
