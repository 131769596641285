// TableMember

import { Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"
import queryString from "query-string"
import RegistrationEventModel from "api/RegistrationEventModel"
import { Col, Row } from "reactstrap"

const RegistrationEventTable = props => {
  let history = useHistory()
  let columns = [
    {
      title: "UUID",
      dataIndex: "uuid",
    },
    {
      title: "UserAgent",
      dataIndex: "userAgent",
    },
    {
      title: "Event",
      dataIndex: "eventName",
      render: eventName => <Tag>{eventName}</Tag>,
    },
  ]

  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  })
  const [filter, setFilter] = useState({
    ...props.filter,
    ...queryString.parseUrl(history.location.search).query,
  })

  const serialize2Url = () => {
    let params = Object.fromEntries(
      Object.entries(filter).filter(([_, v]) => v != null && v != 0)
    )
    let url = queryString.stringify(params)
    history.replace({ search: url })
  }

  const fetchData = async newPagination => {
    setLoading(true)
    let results = await RegistrationEventModel.find(
      filter.eventName,
      newPagination.current,
      newPagination.pageSize
    )
    setData(results?.data?.list)
    setPagination({
      ...newPagination,
      total: results?.data?.total,
    })
    setLoading(false)
  }

  useEffect(() => {
    fetchData({ current: 1, pageSize: pagination.pageSize })
    serialize2Url()
  }, [filter])

  const handleTableChange = (newPagination, filters, sorter) => {
    setPagination(newPagination)
    fetchData(newPagination)
  }

  return (
    <>
      <Row>
        <Col sm={12}>
          <div className="text-sm-end mb-3">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              className="btn-group"
              role="group"
            >
              <div style={{ fontWeight: "600" }}>
                Tổng: {pagination?.total || 0} người tham gia
              </div>
              <select
                className="form-control"
                style={{ width: 150, marginLeft: "10px", lineHeight: "1.3" }}
                onChange={e =>
                  setFilter({ ...filter, eventName: e.target.value })
                }
                value={filter.status}
              >
                <option value={undefined}>Tất cả</option>
                <option value={"cc24_conwood"}>Xi măng dân dụng</option>
                <option value={"cc24_xi_mang_cong_nghiep"}>
                  Xi măng công nghiệp
                </option>
                <option value={"cc24_xi_mang_dan_dung"}>Conwood</option>
              </select>
            </div>
          </div>
        </Col>
      </Row>
      <Table
        columns={columns}
        rowKey={record => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  )
}

export default RegistrationEventTable
