import { City, District } from "helpers/ProvinceUtil";
import React from "react";


function NameConstructorRetailer(constructorRetailer) {
    if (constructorRetailer) {
        if (constructorRetailer?.retailer) {
            const retailer = constructorRetailer.retailer;
            return <>
                <span style={{ display: 'block' }}>{retailer.name}</span>
                <span>Địa chỉ: {retailer.address} - {District.getName(retailer.districtId)} - {City.getName(retailer.cityId)} </span>
            </>
        }
        return <span>{constructorRetailer.draft}</span>
    }
    return 'Không xác định được'
}

export { NameConstructorRetailer }