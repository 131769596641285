import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { isEmpty, map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CementOrderModel from "api/order/CementOrderModel";
import { error } from "helpers/notification";
import { City } from "helpers/ProvinceUtil";
import { District } from "helpers/ProvinceUtil";
import TimeUtils from "helpers/time.utils";
import { CurrencyUtils } from "helpers";
import { Modal, Select, Tag, Input } from "antd";
import CementOrderStatus from "constants/CementOrderStatus";
import { createPortal } from "react-dom";
import { success } from "toastr";


const OrderDetail = props => {
    const orderId = props.match.params.id;

    const [data, setData] = useState()

    const fetchData = async () => {
        const resp = await CementOrderModel.findById(orderId)
        if (resp.error != 0) {
            error("Tải lại trang!")
            return
        }
        setData(resp.data)
    }

    const refresh = useCallback(() => {
        fetchData()
    }, [])

    useEffect(() => {
        fetchData()
    }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Invoices" breadcrumbItem="CHI TIẾT ĐƠN" />
                    {!isEmpty(data) && (
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div className="invoice-title">
                                            <h4 className="font-size-16">
                                                Mã đơn # {data.id}

                                                <span style={{ marginLeft: '10px' }}>
                                                    <Tag style={{ color: '#fff', backgroundColor: `${CementOrderStatus.findBgColorByStatus(data.status)}` }}>{CementOrderStatus.findNameByStatus(data.status)}</Tag>
                                                </span>
                                            </h4>
                                        </div>
                                        <hr />
                                        <Row>
                                            <Col xs="6">
                                                <address>
                                                    <strong>Bên mua:</strong>
                                                    <br />
                                                    <span>Cửa hàng: {data.user.name}</span>
                                                    <br />
                                                    <span>SDT: {data.user.phone}</span>
                                                    <br />
                                                    <span>Địa chỉ: {District.getName(data.user.districtId)}, {City.getName(data.user.cityId)}</span>
                                                </address>
                                            </Col>
                                            <Col xs="6" className="text-end">
                                                <address>
                                                    <strong>Bên bán:</strong>
                                                    <br />
                                                    <span>NPP: {data.distributor.name}</span>
                                                    <br />
                                                    <span>SDT: {data.distributor.phone}</span>
                                                    <br />
                                                    <span>Địa chỉ: {District.getName(data.distributor.districtId)}, {City.getName(data.distributor.cityId)}</span>
                                                </address>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="6" className="mt-3">
                                                <address>
                                                    <strong>Địa chỉ nhận</strong>
                                                    <br />
                                                    {data.address}, {District.getName(data.districtId)},{City.getName(data.cityId)}
                                                    <br />
                                                    <span>Ghi chú: <span style={{ fontStyle: 'italic' }}>{data.note}</span></span>
                                                </address>
                                            </Col>
                                            <Col xs="6" className="mt-3 text-end">
                                                <address>
                                                    <strong>Thời gian nhận</strong>
                                                    <br />
                                                    {TimeUtils.format(data.timeSchedule, 'DD/MM/YYYY')}
                                                    <br />
                                                </address>
                                            </Col>
                                        </Row>
                                        <div className="py-2 mt-3">
                                            <h3 className="font-size-15 fw-bold">Sản phẩm</h3>
                                        </div>
                                        <div className="table-responsive">
                                            <Table className="table-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th>Tên</th>
                                                        <th className="text-end">Số lượng</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {map(
                                                        data.items,
                                                        (item, key) => (
                                                            <tr key={key}>
                                                                <td width={40}>{key + 1}</td>
                                                                <td>{item.name}</td>
                                                                <td style={{ color: 'red' }} className="text-end">x {item.quantity}</td>
                                                            </tr>
                                                        )
                                                    )}
                                                    <tr>
                                                        <td colSpan="2" className="text-end">
                                                            Tổng
                                                        </td>
                                                        <td style={{ color: 'red', fontWeight: '600' }} className="text-end">
                                                            {CurrencyUtils.formatVND(data.price)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <UpdateStatusButton order={data} refresh={refresh} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Container>

            </div>
        </React.Fragment>
    );
};



export default OrderDetail;


const NOTES = ["Cửa hàng đặt thử", "NPP chưa sẵn sàng", "Cửa hàng không có nhu cầu nữa"]

const UpdateStatusButton = React.memo(({ order, refresh }) => {
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({
    })

    const submit = useCallback(() => {
        let status = state.status;
        if (status <= 0) {
            error("Vui chọn trạng thái")
            return;
        }

        if (status === CementOrderStatus.ADMIN_REJECTED && !state.note) {
            error("Vui lòng cho biết lý do")
            return;
        }

        CementOrderModel.updateStatus(order.id, state.status, state.note)
            .then(resp => {
                setOpen(false)
                if (resp.error === 0) {
                    success("Thành công")
                    refresh()
                } else {
                    error(resp.msg)
                }
            })

    }, [order, state]);

    const listStatus = useMemo(() => {
        const list = [
            { value: CementOrderStatus.ADMIN_APPROVED, label: 'Đã duyệt' },
            { value: CementOrderStatus.ADMIN_REJECTED, label: 'Từ chối' },
            { value: CementOrderStatus.DELIVERED, label: 'Đang giao' },
            { value: CementOrderStatus.DONE, label: 'Hoàn thành' }
        ]
        return list.filter(o => o.value > order.status)
    }, [order])

    return (
        <>
            {order.status !== CementOrderStatus.DONE && <div className="d-print-none">
                <div className="float-end">
                    <Link onClick={() => {
                        setOpen(true)
                    }} to="#" className="btn btn-primary w-md ">
                        Cập nhật trạng thái
                    </Link>
                </div>
            </div>
            }
            {createPortal(
                <>
                    <Modal
                        title="Thay đổi trạng thái"
                        open={open}
                        onOk={() => {
                            submit()
                        }}
                        onCancel={() => {
                            setOpen(false)
                        }}
                    >
                        <label>Trạng thái </label>
                        <br />
                        <Select
                            value={state.status}
                            style={{ width: 200 }}
                            onChange={(value) => {
                                setState({
                                    status: value
                                })
                            }}
                            options={listStatus}
                        />
                        <br />
                        <br />
                        {state.status == CementOrderStatus.ADMIN_REJECTED &&
                            <>
                                <label>Vui lòng cho biết lý do</label>
                                <br />
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={(note) => {
                                        console.log(note)
                                        setState({
                                            ...state,
                                            note
                                        })
                                    }}
                                    options={map(NOTES, (note, _) => {
                                        return ({
                                            value: note,
                                            label: note
                                        })
                                    })}
                                />
                            </>
                        }
                    </Modal>
                </>
                , document.body)}
        </>
    )
})

