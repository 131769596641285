// TableMember

import { Table, Tag } from "antd"
import React, { useEffect, useState } from "react";

import TimeUtils from "helpers/time.utils"
import { City } from "helpers/ProvinceUtil"
import { useHistory } from "react-router-dom"
import queryString from 'query-string'
import FormModel from "api/FormModel"
import FormStatus from "constants/FormStatus"
import { DatePicker } from "antd"
import moment from "moment"
import {
    Row, Col,
    Nav,
    NavItem,
    NavLink,
    Button,
} from "reactstrap"

import classnames from "classnames";

const { RangePicker } = DatePicker
const dateFormat = "DD/MM/YYYY"

const InvoiceTableForm = props => {
    const {promotionId} = props.filter
    let history = useHistory();
    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Họ tên",
            dataIndex: "user",
            render: user => user.name
        },
        {
            title: "SDT",
            dataIndex: "user",
            render: user => user.phone
        },
        {
            title: "Số bao",
            dataIndex: "bags",
            render: bags => bags
        },
        {
            title: "Tỉnh/Thành Phố",
            dataIndex: "user",
            render: user => City.getName(user?.cityId),
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: status => (
                <Tag color={FormStatus.findColor(status)} key={status}>
                    {FormStatus.findName(status)}
                </Tag>
            ),
        },
        {
            title: "Thời gian",
            dataIndex: "updatedTime",
            render: updatedTime => TimeUtils.toDDMMYYYY(updatedTime * 1000),
        },
        {
            title: "Hành động",
            dataIndex: "id",
            render: id => (
                <Button color="primary"
                    className="btn btn-primary"
                    onClick={() => history.push(`/promotions/forms/${id}?promotionId=${promotionId}`)}>
                    Chi tiết
                </Button>
            ),
        },
    ]
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })

    const [start_time, set_start_time] = useState(props?.filter?.start ? moment(props?.filter?.start) : moment().subtract(30, "days"))
    const [end_time, set_end_time] = useState(moment())

    const [filter, setFilter] = useState({
        ...props.filter,
        start: Math.floor(start_time.toDate().getTime() / 1000),
        end: Math.floor(end_time.toDate().getTime() / 1000),
        ...queryString.parseUrl(history.location.search).query
    })

    const changeCalendar = data => {
        set_start_time(data[0])
        set_end_time(data[1])
        setFilter({
            ...filter,
            start: Math.floor(data[0].toDate().getTime() / 1000),
            end: Math.floor(data[1].toDate().getTime() / 1000)
        })
    }

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.replace({ search: url })
    };

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await FormModel.getForms(
            {
                search: filter?.search,
                status: filter?.status,
                type: filter?.type,
                userId: filter?.userId,
                cityId: filter?.cityId,
                promotionId: filter?.promotionId,
                matchId: filter?.matchId,
                start: filter?.start,
                end: filter?.end,
                page: newPagination.current,
                pagesize: newPagination.pageSize
            }
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData({ current: 1, pageSize: pagination.pageSize })
        serialize2Url()
        props.updateFilter(filter)
    }, [filter])


    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    const export_excel = () => {
        let { status, type, userId, promotionId, matchId, search, cityId, start, end } = filter;
        FormModel.export(status, type, userId, promotionId, matchId, search, cityId, start, end)
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="text-sm-end mb-3">
                        <Row>
                            <Col sm={12}>
                                <Nav pills>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: true
                                            })}
                                            onClick={() => {
                                                history.push(`/forms?promotionId=${filter?.promotionId}`)
                                            }}
                                        >
                                            <i className="bx bx-chat font-size-20 d-sm-none" />
                                            <span className="d-none d-sm-block">Đơn tham gia</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: false
                                            })}
                                            onClick={() => {
                                                history.push(`/gift-order/${filter?.promotionId}`)
                                            }}
                                        >
                                            <i className="bx bx-chat font-size-20 d-sm-none" />
                                            <span className="d-none d-sm-block">Quà tặng</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: false
                                            })}
                                            onClick={() => {
                                                history.push(`/accumulation/${filter?.promotionId}`)
                                            }}
                                        >
                                            <i className="bx bx-chat font-size-20 d-sm-none" />
                                            <span className="d-none d-sm-block">Bảng xếp hạng</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                            <Col sm={12}>
                                <div className="btn-group" role="group">
                                    <input
                                        className="form-control"
                                        type="search"
                                        placeholder="SDT hoặc tên khách hàng"
                                        style={{ marginLeft: "10px" }}
                                        onChange={e => setFilter({ ...filter, search: e.target.value })}
                                    />
                                    <select
                                        className="form-control"
                                        style={{ marginLeft: "10px" }}
                                        onChange={e =>
                                            setFilter({ ...filter, cityId: Number(e.target.value) })
                                        }
                                        value={filter?.cityId ? filter?.cityId : 0}
                                    >
                                        <option value={0}>Tỉnh/Thành Phố</option>
                                        {City.getList().map(value => {
                                            return (
                                                <option key={value.key} value={value.key}>
                                                    {value.value}
                                                </option>
                                            )
                                        })}
                                    </select>
                                    <select
                                        className="form-control"
                                        style={{ marginLeft: "10px" }}
                                        onChange={e =>
                                            setFilter({ ...filter, status: Number(e.target.value) })
                                        }
                                        value={filter?.status ? filter?.status : 0}
                                    >
                                        <option value={0}>Trạng thái</option>
                                        <option value={FormStatus.INIT}>{FormStatus.findName(FormStatus.INIT)}</option>
                                        <option value={FormStatus.APPROVED}>{FormStatus.findName(FormStatus.APPROVED)}</option>
                                        <option value={FormStatus.REJECTED}>{FormStatus.findName(FormStatus.REJECTED)}</option>
                                        <option value={FormStatus.SENT_GIFT}>{FormStatus.findName(FormStatus.SENT_GIFT)}</option>
                                        <option value={FormStatus.RECEIVED}>{FormStatus.findName(FormStatus.RECEIVED)}</option>
                                    </select>
                                    <div className="" style={{ marginLeft: "10px" }}>
                                        <RangePicker
                                            value={[
                                                moment(start_time, dateFormat),
                                                moment(end_time, dateFormat),
                                            ]}
                                            onChange={changeCalendar}
                                            format={dateFormat} style={{ padding: "0.47rem 0.75rem", minWidth: "250px" }}
                                        />
                                    </div>
                                    <Button
                                        color="primary"
                                        className="btn btn-primary "
                                        style={{ marginLeft: "10px", padding: "0.47rem 0.75rem" }}
                                        size={24}
                                        onClick={export_excel}
                                    >
                                        Export
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    )
}

export default InvoiceTableForm
