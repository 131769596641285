class ConversationStatus {
    static INIT = 1;
    static PROCESSING = 2;
    static DONE = 10;

    static findName(value) {
        switch (value) {
            case this.INIT: return 'Khởi tạo'
            case this.PROCESSING: return 'Đang xử lý'
            case this.DONE: return 'Hoàn thành'
        }
    }

    static findColor(value) {
        switch (value) {
            case this.INIT: return 'orange'
            case this.PROCESSING: return '#20c997'
            case this.DONE: return '#dc3545'
        }
    }
}

export default ConversationStatus;