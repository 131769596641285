import * as ApiHelper from './api_helper'

class PointModel {

    async find({ cityId, search, page, pageSize }) {
        let params = Object.fromEntries(
            Object.entries({
                search,
                cityId,
                page: page - 1,
                pageSize
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`point/find?${new URLSearchParams(params).toString()}`)
    }

}

export default new PointModel();
