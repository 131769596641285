class MatchFootballStatus {
    static INIT = 1;
    static PROCESSING = 2;
    static DONE = 3;
    static APPROVED = 4;

    static findName(value) {
        switch (value) {
            case this.INIT: return 'Khởi tạo'
            case this.PROCESSING: return 'Đang xử lý'
            case this.DONE: return 'Hoàn thành'
            case this.APPROVED: return 'Đã duyệt'
        }
    }

    static findColor(value) {
        switch (value) {
            case this.INIT: return 'orange'
            case this.APPROVED: return '#20c997'
        }
    }
}

export default MatchFootballStatus;