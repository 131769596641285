import React, { useState, useEffect } from "react";
import { Tag, Divider, Skeleton } from "antd";
import OAChatModel from "api/chat/OAChatModel";
import ConversationStatus from "constants/ConversationStatus";
import { map } from "lodash";
import InfiniteScroll from 'react-infinite-scroll-component';
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Link } from "react-router-dom"
import NotificationModel from "api/chat/NotificationModel";
import * as UserType from '../../../constants/UserType';


const ConversationSideBar = ({ onClick, topicId, roleId, style }) => {
    const [selected, setSelected] = useState()
    const [data, setData] = useState([]);


    const fetchNotifications = async (_topicId, offset) => {
        let result = await NotificationModel.find(_topicId, offset, 10)
        return result.data.list;
    }

    const fetchConversationOAs = async (_roleId, offset) => {
        let result = await OAChatModel.find(_roleId, offset, 10);
        if (result.error == 0 && result.data.list) {
            let list = result.data.list;
            return list.map(item => {
                let user = item.user;
                let avatar = user?.avatar || item.fromAvatar;
                let name = user?.name || item.fromDisplayName;
                return {
                    oaid: item.oaid,
                    id: item.messageId,
                    followerId: item.fromId,
                    user: {
                        id: item?.user?.id,
                        avatar: avatar,
                        name: name,
                        roleId: item?.user?.roleId || 0
                    }
                }
            })
        }
    }

    const load = async (_topicId, _roleId, offset, expand) => {
        let newData;
        if (_topicId === 0) {
            newData = await fetchConversationOAs(_roleId, offset);
        } else {
            newData = await fetchNotifications(_topicId, offset)
        }

        if (newData) {
            expand ? setData([...data, ...newData]) : setData(newData);
        }
    }

    useEffect(() => {
        setData([]);
        load(topicId, roleId, 1, false)
    }, [roleId, topicId])

    useEffect(() => {
        if (selected) {
            onClick(selected)
        }
    }, [selected])

    return (
        <PerfectScrollbar id="ConversationSideBar" style={style} >
            <InfiniteScroll
                dataLength={data.length}
                next={() => {
                    load(topicId, roleId, 1, true);
                }}
                hasMore={data.length < 100}
                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                scrollableTarget="ConversationSideBar"
            >
                {map(data, item => (
                    <li
                        key={item.id}
                        className={
                            item.id === selected?.id
                                ?
                                "active"
                                : ""
                        }
                    >
                        <Link
                            to="#"
                            onClick={() => {
                                setSelected(item)
                            }}
                        >
                            <div className="d-flex">
                                <div className="align-self-center me-3">
                                    <img
                                        src={item.user.avatar}
                                        className="rounded-circle avatar-xs"
                                    />
                                </div>

                                <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="text-truncate font-size-14 mb-1">
                                        {item.user.name}
                                    </h5>
                                    <p className="text-truncate mb-0">
                                        {item.status &&
                                            <Tag
                                                color={ConversationStatus.findColor(
                                                    item.status
                                                )}
                                                key={item.status}
                                            >
                                                {ConversationStatus.findName(
                                                    item.status
                                                )}
                                            </Tag>
                                        }
                                        {item.user && item.user.roleId >= 0 &&
                                            <Tag
                                                color={UserType.findColor(
                                                    item.user.roleId
                                                )}
                                                key={item.user.roleId}
                                            >
                                                {UserType.findName(
                                                    item.user.roleId
                                                )}
                                            </Tag>
                                        }
                                    </p>
                                </div>
                                <div className="font-size-11">
                                    {/* {chat.time} */}
                                </div>
                            </div>
                        </Link>
                    </li>
                ))}
            </InfiniteScroll>
        </PerfectScrollbar>
    )
}

export default ConversationSideBar;