export function mapIdAns2String(id) {
    switch (id) {
        case 1:
            return 'Câu A';
        case 2:
            return 'Câu B';
        case 3:
            return 'Câu C';
        case 4:
            return 'Câu D';
    }
}

export function mapIdAns2Color(id) {
    switch (id) {
        case 1:
            return '#8bc34a';
        case 2:
            return '#4caf50';
        case 3:
            return '#009688';
        case 4:
            return '#00bcd4';
    }
}