import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { DatePicker } from "antd"
import moment from "moment"
import UserModel from "api/UserModel"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import StatsUserCity from "./chart/stats-user-city"
import StatsUserDate from "./chart/stats-user-date"
import TableCountLabel from "./table/TableCountLabel"

const { RangePicker } = DatePicker
const dateFormat = "DD/MM/YYYY"



const AnalyticMembers = props => {
    const [start_time, set_start_time] = useState(moment().subtract(30, "days"))
    const [end_time, set_end_time] = useState(moment())
    const [reports, setReports] = useState([])
    const [useRangeTime, setUseRangeTime] = useState(30)
    const [isChange, setIsChange] = useState(false)

    const fetchCount = async (timeStart, timeEnd) => {
        setIsChange(false)
        let start = Math.floor(timeStart.toDate().getTime() / 1000)
        let end = Math.floor(timeEnd.toDate().getTime() / 1000)
        let result = await UserModel.countMembers(start, end)
        setReports([
            {
                title: "Đã duyệt",
                iconClass: "bx-copy-alt",
                description: `${result.data?.numApprovedUser}`,
            },
            {
                title: "Chờ duyệt",
                iconClass: "bx-archive-in",
                description: `${result.data?.numWaitingReviewUser}`,
            },
            {
                title: "Từ chối",
                iconClass: "bx-archive-in",
                description: `${result.data?.numRejectUser}`,
            },
        ])
        setIsChange(true)
    }

    const changeCalendar = data => {
        set_start_time(data[0])
        set_end_time(data[1])
        fetchCount(data[0], data[1])
    }

    const changeSelect = e => {
        setUseRangeTime(Number(e.target.value))
        let start = moment().subtract(60, "days")
        let end = moment()
        if (e.target.value == 7) {
            start = moment().subtract(7, "days")
        }
        if (e.target.value == 30) {
            start = moment().subtract(30, "days")
        }
        set_start_time(start)
        set_end_time(end)
        fetchCount(start, end)
    }

    useEffect(() => {
        console.log(start_time)
    }, [start_time])

    useEffect(() => {
        console.log(end_time)
    }, [end_time])

    useEffect(() => {
        fetchCount(start_time, end_time)
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title={"Thành Viên"} breadcrumbItem="Thống kê" />
                    <Row className="mb-3 justify-content-end">
                        <div className="col-3">
                            <select
                                className="form-control"
                                onChange={changeSelect}
                                value={useRangeTime}
                            >
                                <option value="7">7 Ngày</option>
                                <option value="30">30 Ngày</option>
                                <option value="0">Khoảng thời gian</option>
                            </select>
                        </div>
                        {useRangeTime === 0 && (
                            <div className="col-4">
                                <RangePicker
                                    value={[
                                        moment(start_time, dateFormat),
                                        moment(end_time, dateFormat),
                                    ]}
                                    onChange={changeCalendar}
                                    format={dateFormat}
                                />
                            </div>
                        )}
                    </Row>
                    <Row>
                        {/* Reports Render */}
                        {reports.map((report, key) => (
                            <Col md="4" key={"_col_" + key}>
                                <Card className="mini-stats-wid">
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">{report.title}</p>
                                                <h4 className="mb-0">{report.description}</h4>
                                            </div>
                                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle bg-primary">
                                                    <i
                                                        className={
                                                            "bx " + report.iconClass + " font-size-24"
                                                        }
                                                    ></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col lg={5}>
                            <Card>
                                <CardBody>
                                    {isChange && (
                                        <StatsUserCity
                                            start_time={start_time}
                                            end_time={end_time}
                                        />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={7}>
                            <Card>
                                <CardBody>
                                    {isChange && (
                                        <TableCountLabel
                                            start_time={start_time}
                                            end_time={end_time}
                                        />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-1">Số thành viên đăng ký</CardTitle>
                                    {isChange && (
                                        <StatsUserDate
                                            start_time={Math.floor(
                                                start_time.toDate().getTime() / 1000
                                            )}
                                            end_time={Math.floor(end_time.toDate().getTime() / 1000)}
                                        />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AnalyticMembers
