import FormModel from "api/FormModel"
import FormStatus from "constants/FormStatus"
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"

const PredictFootballAnalytics = props => {
    const [report, setReport] = useState(null)

    const getAnalyticsForm = async () => {
        let query = {
            search: props?.filter?.search,
            type: props?.filter?.type,
            userId: props?.filter?.userId,
            cityId: props?.filter?.cityId,
            promotionId: props?.filter?.promotionId,
            roleId: props?.filter?.roleId,
            matchId: props?.filter?.matchId,
            start: props?.filter?.start,
            end: props?.filter?.end,
        }

        let resTotal = await FormModel.countPredictForm({ ...query, status: 0 });
        let resCorrect = await FormModel.countPredictForm({ ...query, status: FormStatus.APPROVED });
        let resWrong = await FormModel.countPredictForm({ ...query, status: FormStatus.REJECTED });
        setReport({
            total: resTotal?.data,
            correct: resCorrect?.data,
            wrong: resWrong.data,
        })
    }

    useEffect(() => { console.log(props.filter); getAnalyticsForm(); }, [props.filter])

    return (
        <Row>
            <Col sm={4}>
                <Card>
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">Tổng số đơn dự đoán</p>
                                <h4 className="mb-0">{report?.total}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                    <i className={"bx bx-archive-in font-size-24"}></i>
                                </span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col sm={4}>
                <Card>
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">Số đơn dự đoán đúng</p>
                                <h4 className="mb-0">{report?.correct}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                    <i className={"bx bx-archive-in font-size-24"}></i>
                                </span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col sm={4}>
                <Card>
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">Số đơn dự đoán sai</p>
                                <h4 className="mb-0">{report?.wrong}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                    <i className={"bx bx-archive-in font-size-24"}></i>
                                </span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default PredictFootballAnalytics
