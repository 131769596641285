import { Image, Tag } from "antd"
import React, { useEffect, useState } from "react"
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { City, District } from "helpers/ProvinceUtil"
import { error, success } from "helpers/notification"
import TimeUtils from "helpers/time.utils"
import { useHistory } from "react-router-dom"
import queryString from "query-string"
import ConstructionModel from "api/ConstructionModel"
import ConstructionType from "constants/ConstructionType"
import ConstructionStatus from "constants/ConstructionStatus"
import TableUserConstruction from "./table/TableUserConstruction"


const DetailConstruction = props => {
    const constructionId = props.match.params.id;
    let history = useHistory()

    const [data, setData] = useState({})
    const { action } = queryString.parseUrl(history.location.search).query


    const fetchData = async () => {
        const resp = await ConstructionModel.get(constructionId)
        if (resp.error != 0) {
            error("Tải lại trang!")
            return
        }
        setData(resp.data)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Forms"} breadcrumbItem="Chi tiết" />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm="6">
                                    <div className="table-responsive">
                                        {data &&
                                            <Table className="table-nowrap mb-0">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">ID :</th>
                                                        <td>{data.id}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Loại công trình</th>
                                                        <td>{<Tag color={ConstructionType.findColor(data.type)}>{ConstructionType.findName(data.type)}</Tag>}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Địa chỉ :</th>
                                                        <td><b>{data.address}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Tỉnh/Thành phố:</th>
                                                        <td>{City.getName(data.cityId)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Quận/Huyện:</th>
                                                        <td>{District.getName(data.districtId)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Trạng thái :</th>
                                                        <td>
                                                            <Tag color={ConstructionStatus.findColor(data?.status)}>
                                                                {ConstructionStatus.findName(data?.status)}
                                                            </Tag>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Ngày tạo:</th>
                                                        <td>
                                                            {TimeUtils.toDDMMYYYY(data?.createdTime * 1000)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        }
                                    </div>
                                </Col>
                                <Col sm="6">

                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>

                        <CardBody>
                            <CardTitle>
                                Danh sách nhà thầu đã tích đơn
                            </CardTitle>
                            <TableUserConstruction constructionId={constructionId} />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DetailConstruction
