// TableMember

import { Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import { City } from "helpers/ProvinceUtil"
import { useHistory } from "react-router-dom"
import { Button, Col } from "reactstrap"
import { Row } from "reactstrap"
import queryString from 'query-string'
import FormModel from "api/FormModel"
import MatchModel from "api/MatchModel"
import FormStatus from "constants/FormStatus"
import FootballResultModal from "../modal/FootballResultModal"
import { error, success } from 'helpers/notification';
import * as UserType from 'constants/UserType'

import { DatePicker } from "antd"
import moment from "moment"
import MatchFootballStatus from '../../../constants/MatchFootballStatus';
const { RangePicker } = DatePicker
const dateFormat = "DD/MM/YYYY"

const PredictFootballTableForm = props => {
    const {promotionId} = props.filter
    let history = useHistory();
    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Họ tên",
            dataIndex: "user",
            render: user => user.name
        },
        {
            title: "Tỉnh/Thành Phố",
            dataIndex: "user",
            render: user => City.getName(user?.cityId),
        },
        {
            title: "Trận đấu",
            dataIndex: "match",
            render: match => `${match.oHost.name} - ${match.oGuest.name}`
        },
        {
            title: "Dự đoán",
            render: form => {
                let winner = form.winner;
                if (winner == form.match.oHost.id) {
                    return form.match.oHost.name;
                }
                if (winner == form.match.oGuest.id) {
                    return form.match.oGuest.name;
                }
                return "Hòa"
            }
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: status => (
                <Tag color={FormStatus.findColor(status)} key={status}>
                    {FormStatus.findNameForPredictFootball(status)}
                </Tag>
            ),
        }, {
            title: "Hành động",
            dataIndex: "id",
            render: id => (
                <Button color="primary"
                    className="btn btn-primary"
                    onClick={() => history.push(`/promotions/forms/${id}?promotionId=${promotionId}`)}>
                    Chi tiết
                </Button>
            ),
        },
    ]
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [match, setMatch] = useState(null)
    const [modalFootballResult, setModalFootballResult] = useState(false);
    const [start_time, set_start_time] = useState(moment().subtract(30, "days"))
    const [end_time, set_end_time] = useState(moment())

    const [filter, setFilter] = useState({
        ...props.filter,
        start: Math.floor(start_time.toDate().getTime() / 1000),
        end: Math.floor(end_time.toDate().getTime() / 1000),
        ...queryString.parseUrl(history.location.search).query
    })

    const changeCalendar = data => {
        set_start_time(data[0])
        set_end_time(data[1])
        setFilter({
            ...filter,
            start: Math.floor(data[0].toDate().getTime() / 1000),
            end: Math.floor(data[1].toDate().getTime() / 1000)
        })
    }
    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.replace({ search: url })
    };

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await FormModel.getPredictFootballForms(
            {
                search: filter?.search,
                status: filter?.status,
                type: filter?.type,
                userId: filter?.userId,
                cityId: filter?.cityId,
                matchId: filter?.matchId,
                roleId: filter?.roleId,
                start: filter?.start,
                end: filter?.end,
                page: newPagination.current,
                pagesize: newPagination.pageSize
            }
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    const fetchMatch = async () => {
        let res = await MatchModel.get(filter.matchId);
        setMatch(res.data)
    }

    useEffect(() => {
        fetchData({ current: 1, pageSize: pagination.pageSize })
        fetchMatch();
        serialize2Url()
        props.updateFilter(filter)
    }, [filter])

    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    const updateFootballResult = async (data) => {
        setModalFootballResult(false)
        let res = await MatchModel.updateResult(filter.matchId, data.goalHost, data.goalGuest);
        if (res.error != 0) {
            error(res.msg)
            return;
        }
        success("Thành công!")
        props.reload(filter.promotionId)
    }

    const export_excel = () => {
        FormModel.export_predict_football({
            search: filter?.search,
            status: filter?.status,
            type: filter?.type,
            userId: filter?.userId,
            cityId: filter?.cityId,
            matchId: filter?.matchId,
            roleId: filter?.roleId,
            start: filter?.start,
            end: filter?.end,
        })
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="text-sm-end mb-3">
                        <div className="btn-group" role="group">
                            <input
                                className="form-control"
                                type="search"
                                placeholder="SDT hoặc tên khách hàng"
                                style={{ marginLeft: "10px" }}
                                onChange={e => setFilter({ ...filter, search: e.target.value })}
                            />
                            <select
                                className="form-control"
                                style={{ marginLeft: "10px" }}
                                onChange={e =>
                                    setFilter({ ...filter, cityId: Number(e.target.value) })
                                }
                                value={filter?.cityId ? filter?.cityId : 0}
                            >
                                <option value={0}>Tỉnh/Thành Phố</option>
                                {City.getList().map(value => {
                                    return (
                                        <option key={value.key} value={value.key}>
                                            {value.value}
                                        </option>
                                    )
                                })}
                            </select>
                            <select
                                className="form-control"
                                style={{ marginLeft: "10px" }}
                                onChange={e =>
                                    setFilter({ ...filter, status: Number(e.target.value) })
                                }
                                value={filter?.status ? filter?.status : 0}
                            >
                                <option value={0}>Trạng thái</option>
                                <option value={FormStatus.INIT}>{FormStatus.findNameForPredictFootball(MatchFootballStatus.INIT)}</option>
                                <option value={FormStatus.APPROVED}>{FormStatus.findNameForPredictFootball(FormStatus.APPROVED)}</option>
                                <option value={FormStatus.REJECTED}>{FormStatus.findNameForPredictFootball(FormStatus.REJECTED)}</option>
                                <option value={FormStatus.SENT_GIFT}>{FormStatus.findNameForPredictFootball(FormStatus.SENT_GIFT)}</option>
                                <option value={FormStatus.RECEIVED}>{FormStatus.findNameForPredictFootball(FormStatus.RECEIVED)}</option>
                            </select>
                            <select
                                className="form-control"
                                style={{ marginLeft: "10px" }}
                                onChange={e =>
                                    setFilter({ ...filter, roleId: Number(e.target.value) })
                                }
                                value={filter?.roleId ? filter?.roleId : 0}
                            >
                                <option value={0}>Khách hàng</option>
                                <option value={UserType.CONSTRUCTOR}>{UserType.findName(UserType.CONSTRUCTOR)}</option>
                                <option value={UserType.RETAILER}>{UserType.findName(UserType.RETAILER)}</option>
                            </select>
                            <div className="" style={{ marginLeft: "10px" }}>
                                <RangePicker
                                    value={[
                                        moment(start_time, dateFormat),
                                        moment(end_time, dateFormat),
                                    ]}
                                    onChange={changeCalendar}
                                    format={dateFormat} style={{ padding: "0.47rem 0.75rem", minWidth: "250px" }}
                                />
                            </div>
                            <Button
                                color="primary"
                                className="btn btn-primary "
                                style={{ marginLeft: "10px", height: '38px' }}
                                size={24}
                                onClick={export_excel}
                            >
                                Export
                            </Button>
                            <Button
                                color="primary"
                                className="btn btn-primary"
                                style={{ width: "350px", marginLeft: "10px", height: '38px' }}
                                onClick={() => setModalFootballResult(true)}
                            >
                                Kết quả
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />

            {modalFootballResult &&
                <FootballResultModal
                    isOpen={modalFootballResult}
                    toggle={() => setModalFootballResult(false)}
                    handleSubmit={data => updateFootballResult(data)}
                    title="Kết quả trận đấu???"
                    match={match}
                />}
        </>
    )
}

export default PredictFootballTableForm
