// TableMember

import { Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import TimeUtils from "helpers/time.utils"
import { useHistory } from "react-router-dom"
import { Button, Col } from "reactstrap"
import FormModel from "api/FormModel"
import FormStatus from "constants/FormStatus"


const TableFormOfUser = props => {
    let history = useHistory();
    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Chương trình",
            dataIndex: "promotion",
            render: promotion => promotion.title
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: status => (
                <Tag color={FormStatus.findColor(status)} key={status}>
                    {FormStatus.findName(status)}
                </Tag>
            ),
        },
        {
            title: "Ngày đăng ký",
            dataIndex: "createdTime",
            render: createdTime => TimeUtils.toDDMMYYYY(createdTime * 1000),
        },
        {
            title: "Hành động",
            dataIndex: "id",
            render: (id, promotion) => (
                <Button color="primary"
                    className="btn btn-primary"
                    onClick={() => history.push(`/promotions/forms/${id}?promotionId=${promotion.id}`)}>
                    Chi tiết
                </Button>
            ),
        },
    ]
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [filter, setFilter] = useState(props.filter)

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await FormModel.getForms(
            {
                search: filter?.search,
                status: filter?.status,
                type: filter?.type,
                userId: filter?.userId,
                cityId: filter?.cityId,
                promotionId: filter?.promotionId,
                matchId: filter?.matchId,
                page: newPagination.current,
                pagesize: newPagination.pageSize
            }
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData({ current: 1, pageSize: pagination.pageSize })
    }, [filter])


    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    return (
        <>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    )
}

export default TableFormOfUser
