import * as ApiHelper from '../api_helper'

class CementOrderModel {
    async find(status, page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                status,
                page: page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`order/find?${new URLSearchParams(params).toString()}`)
    }

    async findById(id) {
        return ApiHelper.get(`order/get?id=${id}`)
    }

    async updateStatus(id, status, note) {
        return ApiHelper.post2(`order/update-status?id=${id}`, { id, status, note });
    }
}

export default new CementOrderModel();
