import UserModel from "api/UserModel";
import { City } from "helpers/ProvinceUtil";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react"

const StatsUserCity = (props) => {

    const [options, setOptions] = useState({});

    const buildOption = (data) => {
        var series = []
        for (var i = 0; i < data.length; i++) {
            var metric = data[i];
            series.push({
                name: City.getName(metric.city),
                y: metric.total
            })
        }

        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: ''
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            series: [{
                name: 'Thành viên',
                colorByPoint: true,
                data: series
            }]
        }
    }

    const fetchStatsUserCity = async () => {
        let { start_time, end_time } = props;
        let start = Math.floor(
            start_time.toDate().getTime() / 1000
        )
        let end = Math.floor(
            end_time.toDate().getTime() / 1000
        )
        let result = await UserModel.statsUserCity(start, end);
        console.log(result.data)
        let data = result.data;
        let opt = buildOption(data)
        setOptions(opt)
    }

    useEffect(() => {
        fetchStatsUserCity();
    }, [])

    return (
        <HighchartsReact constructorType={"chart"}
            highcharts={Highcharts} options={options} />
    )
}

export default StatsUserCity
