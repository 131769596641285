import FormModel from "api/FormModel";
import UserModel from "api/UserModel";
import { City } from "helpers/ProvinceUtil";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react"

const StatsBagsCement = (props) => {
    const [isEmpty, set_isEmpty] = useState(false)
    const [options, setOptions] = useState({});

    const buildOption = (data) => {
        var series = []
        for (var i = 0; i < data.length; i++) {
            var metric = data[i];
            series.push({
                name: metric.cementName,
                y: metric.bags
            })
        }

        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: ''
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            series: [{
                name: 'Số bao',
                colorByPoint: true,
                data: series
            }]
        }
    }

    const fetchStatsBagCement = async () => {
        let { userId } = props;
        let result = await FormModel.statsBagCementUser(userId)
        let data = result.data;
        if (data.length == 0) {
            set_isEmpty(true);
        } else {
            let opt = buildOption(data)
            setOptions(opt)
        }
    }

    useEffect(() => {
        fetchStatsBagCement();
    }, [])

    return (
        <>
            { !isEmpty && <HighchartsReact constructorType={"chart"}
                highcharts={Highcharts} options={options} />}
            { isEmpty && <div style={{textAlign: 'center', fontWeight: '600'}}>Chưa có dữ liệu</div>}
        </>

    )
}

export default StatsBagsCement
