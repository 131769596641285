class FormStatus {
    static INIT = 1;
    static APPROVED = 2;
    static REJECTED = 3;
    static SENT_GIFT = 4;
    static RECEIVED = 5;
    static DRAFT = 0;

    static findName(value) {
        switch (value) {
            case this.INIT: return 'Chờ duyệt'
            case this.APPROVED: return 'Đã duyệt'
            case this.REJECTED: return 'Đã từ chối'
            case this.SENT_GIFT: return "Đã gửi quà"
            case this.RECEIVED: return "Đã nhận quà"
            case this.DRAFT: return "Chờ cập nhật hóa đơn"
        }
    }

    static findNameForPredictFootball(value) {
        switch (value) {
            case this.INIT: return 'Chờ kết quả'
            case this.APPROVED: return 'Dự đoán chính xác'
            case this.REJECTED: return 'Dự đoán không chính xác'
            case this.SENT_GIFT: return "Đã gửi quà"
            case this.RECEIVED: return "Đã nhận quà"
        }
    }

    static findColor(value) {
        switch (value) {
            case this.INIT: return '#8bc34a'
            case this.RECEIVED: return '#6f42c1'
            case this.SENT_GIFT: return '#ffc107'
            case this.APPROVED: return '#28a745'
            case this.REJECTED: return '#9e9e9e'
            case this.DRAFT: return '#f1b44c'
        }
    }
}

export default FormStatus;