// TableMember

import { Button, Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import TimeUtils from "helpers/time.utils"
import { Link, useHistory } from "react-router-dom"
import { success, error } from "helpers/notification"
import { PopupConfirm } from "components/pop-confirm"
import queryString from "query-string"
import MatchModel from "api/MatchModel"
import MatchFootballStatus from "constants/MatchFootballStatus"
import BroadcastModal from "../modal/BroadcastModal"
import {
    Row, Col,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap"
import classnames from "classnames";


const TableMatch = props => {
    let history = useHistory()
    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Đội nhà",
            dataIndex: "cHost",
            render: cHost => cHost.name,
        },
        {
            title: "Đội Khách",
            dataIndex: "cGuest",
            render: cGuest => cGuest.name,
        },
        {
            title: "Bắt đầu",
            dataIndex: "timeStart",
            render: timeStart => TimeUtils.toTimeAndDate(timeStart),
        },
        {
            title: "Số lượt dự đoán",
            dataIndex: "totalPredict",
        },
        {
            title: "Dự đoán chính xác",
            dataIndex: "totalPredictCorrect",
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: status => (
                <Tag color={MatchFootballStatus.findColor(status)} key={status}>
                    {MatchFootballStatus.findName(status)}
                </Tag>
            ),
        },
        {
            title: "Hành động",
            render: (_, record) => (
                <>
                    {record?.status === MatchFootballStatus.INIT && (
                        <div style={{ width: "100px" }}>
                            <div className="col-12 mb-1">
                                <Button
                                    type="primary"
                                    size="small"
                                    style={{ width: "100%" }}
                                    onClick={() => btn_approved(record)}
                                >
                                    Duyệt
                                </Button>
                            </div>
                            <div className="col-12 mb-1">
                                <Button size="small" style={{ width: "100%" }}>
                                    <Link to={`/promotions/matchs/edit/${record.id}`}>
                                        Chỉnh sửa
                                    </Link>
                                </Button>
                            </div>
                        </div>
                    )}
                    {record?.status !== MatchFootballStatus.INIT && (
                        <div style={{ width: "100px" }}>
                            <div className="col-12 mb-1">
                                <Button
                                    type="primary"
                                    size="small"
                                    style={{ width: "100%" }}
                                    onClick={() => {
                                        history.push(
                                            `/forms?promotionId=${props.promotionId}&matchId=${record.id}`
                                        )
                                    }}
                                >
                                    Chi tiết
                                </Button>
                            </div>
                            {record?.broadcastId == null && (
                                <div className="col-12 mb-1">
                                    <Button
                                        size="small"
                                        style={{ width: "100%" }}
                                        onClick={() => btn_broadcast(record)}
                                    >
                                        Broadcast
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </>
            ),
        },
    ]

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [filter, setFilter] = useState({
        ...props.filter,
        ...queryString.parseUrl(history.location.search).query,
    })
    const [isBroadcastOpen, setIsBroadcastOpen] = useState(false)
    const [isApprovedOpen, setIsApprovedOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)

    const btn_approved = row => {
        setIsApprovedOpen(true)
        setSelectedRow(row)
    }

    const btn_broadcast = row => {
        setIsBroadcastOpen(true)
        setSelectedRow(row)
    }

    const updateStatus = async (id, status) => {
        setIsApprovedOpen(false)
        setIsBroadcastOpen(false)
        let result = await MatchModel.updateStatus(id, status)
        if (result.error != 0) {
            error("Duyệt trận đấu thất bại")
            return
        }
        success("Duyệt trận đấu thành công!")
        fetchData(pagination)
    }

    const createBroadcast = async (matchId, promotionId, timeStart) => {
        setIsApprovedOpen(false)
        setIsBroadcastOpen(false)
        let result = await MatchModel.createBroadcast(
            matchId,
            promotionId,
            timeStart
        )
        if (result.error != 0) {
            error("Tạo broadcast thất bại")
            return
        }
        success("Tạo broadcast thành công!")
        fetchData(pagination)
    }

    const fetchData = async newPagination => {
        setLoading(true)
        let results = await MatchModel.find(
            newPagination.current,
            newPagination.pageSize
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData({
            current: 1,
            pageSize: 10,
        })
    }, [filter])

    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    const export_excel = () => {
        MatchModel.export(props.promotionId)
    }

    return (
        <>
            <Row>
                <Col sm={4}>
                    <Nav pills>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: true
                                })}
                                onClick={() => {
                                    history.push(
                                        `/promotions/matchs/${props.promotionId}`
                                    )
                                }}
                            >
                                <i className="bx bx-chat font-size-20 d-sm-none" />
                                <span className="d-none d-sm-block">Dánh sách trận đấu</span>
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: false
                                })}
                                onClick={() => {
                                    history.push(
                                        `/promotions/matchs/leaderboard/${props.promotionId}`
                                    )
                                }}
                            >
                                <i className="bx bx-chat font-size-20 d-sm-none" />
                                <span className="d-none d-sm-block">Bảng Xếp hạng</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
                <Col sm={8}>
                    <div className="text-sm-end">
                        <div className="btn-group mb-3" role="group">
                            <Button
                                color="primary"
                                className="btn btn-primary "
                                style={{ marginLeft: "10px" }}
                                size={24}
                                onClick={export_excel}
                            >
                                Export
                            </Button>
                            <Button type="primary" style={{ marginLeft: "10px" }} size={24}>
                                <Link to={`/promotions/matchs/create`}>Thêm trận đấu</Link>
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            {isBroadcastOpen && (
                <BroadcastModal
                    isOpen={isBroadcastOpen}
                    toggle={() => setIsBroadcastOpen(false)}
                    handleSubmit={timeStart =>
                        createBroadcast(selectedRow.id, props.promotionId, timeStart)
                    }
                    title="Bạn có muốn Broadcast trận đấu này?"
                />
            )}
            {isApprovedOpen && (
                <PopupConfirm
                    isOpen={isApprovedOpen}
                    toggle={() => setIsApprovedOpen(false)}
                    handleSubmit={() =>
                        updateStatus(selectedRow.id, MatchFootballStatus.APPROVED)
                    }
                    title="Bạn có muốn duyêt trận đấu này?"
                    content={{
                        id: selectedRow.id,
                        host: selectedRow.cHost.name,
                        guest: selectedRow.cGuest.name,
                    }}
                />
            )}
        </>
    )
}

export default TableMatch
