import moment from 'moment';
export default class TimeUtils {

    static format(time, format) {
        return moment(time).format(format);
    }

    static toDDMMYYYY(timestamp) {
        let today = new Date(timestamp);
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();

        return dd + "/" + mm + "/" + yyyy;
    }

    static toString(timestamp) {
        let date = new Date(timestamp);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return day + ' ' + month + ', ' + year;
    }

    static toTimeAndDate(timestamp) {
        let date = new Date(timestamp);
        let hour = date.getHours();
        let minutes = date.getMinutes()
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return hour + "h" + minutes + " - " + day + '/' + month

    }

    static toStringForSysten(timestamp) {
        let date = new Date(timestamp);
        return date.getFullYear() + "-" + date.getMonth() + 1 + "-" + date.getDate();
    }

    static parseDate(str) {
        let arr = str.split("-");
        return new Date(arr[0], arr[1] - 1, arr[2]);
    }

    static toStringNotYear(date) {
        let mon = date.getMonth() + 1;
        let day = date.getDate();
        return day + ', tháng' + mon;
    }

    static formatDuration(seconds) {
        let hours = Math.floor(seconds / 3600);
        let remainingSeconds = seconds % 3600;
        let minutes = Math.floor(remainingSeconds / 60);
        let remainingSecondsFinal = remainingSeconds % 60;

        if (hours > 0) {
            return `${hours} giờ ${minutes} phút ${remainingSecondsFinal} giây`;
        } else if (minutes > 0) {
            return `${minutes} phút ${remainingSecondsFinal} giây`;
        } else {
            return `${remainingSecondsFinal} giây`;
        }
    }
}