import * as ApiHelper from '../api_helper'

class OAChatModel {
    async find(roleId, page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                roleId,
                page: page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`chat/oa/find-recent?${new URLSearchParams(params).toString()}`)
    }

    async findMessage(oaid, followerId, page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                oaid,
                followerId,
                page: page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`chat/oa/find-message?${new URLSearchParams(params).toString()}`)
    }
}

export default new OAChatModel();
