// TableMember

import { Avatar, Button, Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import TimeUtils from "helpers/time.utils"
import { useHistory } from "react-router-dom"
import {
    Col,
} from "reactstrap"
import { Row } from "reactstrap"
import queryString from 'query-string'
import * as UserType from '../../../constants/UserType'
import ConversationStatus from "constants/ConversationStatus"
import NotificationModel from "api/chat/NotificationModel"


// TableNotification
const TableMessage = props => {
    const { topicId } = props?.filter;
    let history = useHistory();

    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Tên",
            dataIndex: "user",
            render: (user) => {
                const { avatar } = user;
                return (
                    <>
                        <Avatar src={avatar} size="large" />
                    </>
                )
            }
        },
        {
            title: "Tên",
            dataIndex: "user",
            render: (user) => user.name
        },
        {
            title: "Tài khoản",
            dataIndex: "user",
            render: user => UserType.findName(user.roleId)
        },
        {
            title: "Vấn đề",
            dataIndex: "topic",
            render: topic => topic.name
        },
        {
            title: "Tiếp nhận",
            dataIndex: "createdTime",
            render: createdTime => TimeUtils.format(createdTime * 1000, "HH:mm - DD/MM/YYYY")

        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: status => (
                <Tag color={ConversationStatus.findColor(status)} key={status}>
                    {ConversationStatus.findName(status)}
                </Tag>
            ),
        },
        {
            title: "Hành động",
            render: (_, record) => (
                <>
                    <Button type="primary" size="small" style={{ width: "100%" }}
                        onClick={() => history.push(`/message/${record.id}`)} >
                        Chi tiết
                    </Button>

                </>
            ),
        },
    ]

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)

    const [filter, setFilter] = useState({
        current: 1,
        pageSize: 10,
        topicId,
        status: 0,
        ...queryString.parseUrl(history.location.search).query
    })

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.replace({ search: url })
    };

    const fetchData = async () => {
        setLoading(true)
        let results = await NotificationModel.find(
            filter.status,
            filter.topicId,
            filter.current,
            filter.pageSize
        )
        setData(results?.data)
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
        serialize2Url()
    }, [filter])

    useEffect(() => {
        setFilter({ ...filter, topicId: topicId })
    }, [topicId])

    const handleTableChange = (newPagination, filters, sorter) => {
        setFilter({ ...filter, newPagination })
    }


    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="text-sm-end mb-3">
                        <div className="btn-group" role="group">
                            <select
                                className="form-control"
                                style={{ width: 150, marginLeft: "10px", lineHeight: '1.3' }}
                                onChange={e =>
                                    setFilter({ ...filter, status: Number(e.target.value) })
                                }
                                value={filter.status}
                            >
                                <option value={0}>Tất cả</option>
                                <option value={ConversationStatus.INIT}>
                                    {ConversationStatus.findName(ConversationStatus.INIT)}
                                </option>
                                <option value={ConversationStatus.PROCESSING}>
                                    {ConversationStatus.findName(ConversationStatus.PROCESSING)}
                                </option>
                                <option value={ConversationStatus.DONE}>
                                    {ConversationStatus.findName(ConversationStatus.DONE)}
                                </option>
                            </select>
                        </div>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data?.list || []}
                pagination={{ ...filter, total: (data?.totalPage * data?.pageSize || 0) }}
                loading={loading}
                onChange={handleTableChange}
            />

        </>
    )
}

export default TableMessage
