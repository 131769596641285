import React from 'react';
import { useState } from "react";
import {
    Button,
    Card,
    Col,
    Label,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form
} from "reactstrap";
import { success } from "helpers/notification"
import { error } from "helpers/notification"
import TopicSurveyModel from 'api/TopicSurveyModel';
import { Select } from 'antd';


const FormTopicModal = (props) => {
    const modal = props.open || false;
    const [form, setForm] = useState({
    })

    const isValid = () => {
        if (!form.title) {
            return false;
        }

        if (!form.gettingStarted) {
            return false;
        }

        if (!form.payload) {
            return false;
        }

        if (!form.fields || form.fields.length <= 0) {
            return false;
        }

        return true;
    }

    const submit = async () => {
        if (!isValid()) {
            return;
        }

        TopicSurveyModel.create(form)
            .then(resp => {
                if (resp.error == 0) {
                    props.onSuccess && props.onSuccess()
                    success("Tạo thành công")
                } else {
                    props.onClose && props.onClose()
                    error(resp.msg)
                }
            })
    }


    return (
        <>
            <Modal
                isOpen={modal}
            >
                <ModalHeader tag="h4">
                    Chủ đề
                </ModalHeader>
                <ModalBody className='pt-0'>
                    <Card>
                        <Form>
                            <Row>
                                <Col sm={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="roleIds">Tên</Label>
                                        <input
                                            type='text'
                                            className="form-control"
                                            onChange={(e) => {
                                                setForm({ ...form, title: e.target.value })
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </Col>
                                <Col sm={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="roleIds">Tin nhắn bắt đầu</Label>
                                        <textarea
                                            type='text'
                                            className="form-control"
                                            onChange={(e) => {
                                                setForm({ ...form, gettingStarted: e.target.value })
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </Col>
                                <Col sm={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="roleIds">Payload</Label>
                                        <input
                                            type='text'
                                            className="form-control"
                                            onChange={(e) => {
                                                setForm({ ...form, payload: e.target.value })
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="mb-3 templating-select select2-container">
                                        <label className="control-label">Fields</label>
                                        <Select
                                            mode="tags"
                                            size={10}
                                            onChange={(value) => {
                                                setForm({ ...form, fields: value })
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                    <ModalFooter>
                        <Button
                            onClick={() => { props.onClose && props.onClose() }}
                            type="button"
                            color="secondary"
                        >
                            Đóng
                        </Button>
                        <Button
                            type="button"
                            color="success"
                            onClick={() => { submit() }}
                        >
                            Đồng ý
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </>
    )
}

export default FormTopicModal;