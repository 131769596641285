import React, { useEffect, useState } from "react"

import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Select, DatePicker, Button } from "antd"
import moment from "moment"
import UploadImage from "components/UploadImage"
import { City, District } from "helpers/ProvinceUtil"
import ArticleModel from "api/ArticleModel"
import { success, error } from "helpers/notification"
import { useHistory } from "react-router-dom"
import * as UserType from "constants/UserType"
import * as PostType from "constants/PostType"
import MyEditor from 'components/CKEditor';
import PromotionModel from "api/PromotionModel"

const { RangePicker } = DatePicker
const dateFormat = "DD/MM/YYYY"

const EditArticles = props => {
    const [start_time, set_start_time] = useState(moment().subtract(60, "days"))
    const [end_time, set_end_time] = useState(moment())
    const [selectedCities, setSelectedCities] = useState([])
    const [selectedDistricts, setSelectedDistricts] = useState([])
    const [validation, setValidation] = useState([])
    const [isSubmit, setIsSubmit] = useState(false)
    const [done, setDone] = useState(false)
    const [promotions, setPromotions] = useState([])

    const [field, setField] = useState({
        timeStart: start_time.toDate().getTime(),
        timeEnd: end_time.toDate().getTime(),
    })
    const history = useHistory()

    let cities = City.getList()
    let filteredCities = cities.filter(o => !selectedCities.includes(o.key))

    let districts = selectedCities.flatMap(item =>
        District.getList(City.getByName(item).id)
    )
    let filteredDistricts = districts.filter(
        o => !selectedDistricts.includes(o.key)
    )

    const changeCalendar = data => {
        set_start_time(data[0])
        set_end_time(data[1])
        let start = moment(data[0]).toDate().getTime()
        let end = moment(data[1]).toDate().getTime()
        setField({ ...field, timeStart: start, timeEnd: end })
    }

    const change_images = data => {
        setField({ ...field, cover: data[0]?.url })
    }

    const updatePost = async e => {
        e.preventDefault()
        setIsSubmit(true)
        let isError = validate()
        if (isError) {
            return
        }
        let result = await ArticleModel.update(props.match.params.id, field)
        if (result.error != 0) {
            error(result.msg)
            return
        }
        success("Cập nhập bài viết thành công!")
        history.push(`/articles`)
    }

    const getPost = async id => {
        setDone(false)
        let result = await ArticleModel.get(id)
        setField({ ...result.data, roleIds: result.data?.roleUsers.length == 2 ? 0 : result.data.roleUsers })
        let start = moment(result.data.timeStart);
        let end = moment(result.data.timeEnd)
        set_start_time(start)
        set_end_time(end)
        setDone(true)
    }

    const validate = () => {
        let isError = false
        let tmp = {}
        if (!field?.title) {
            isError = true
            tmp = { ...tmp, title: "Thiếu tiêu đề" }
        }
        if (!field?.summary) {
            isError = true
            tmp = { ...tmp, summary: "Thiếu mô tả" }
        }
        if (!field?.content) {
            isError = true
            tmp = { ...tmp, content: "Thiếu nội dung" }
        }
        if (!field?.cover) {
            isError = true
            tmp = { ...tmp, cover: "Thiếu ảnh bìa" }
        }
        setValidation(tmp)
        return isError
    }

    const getPromotion = async () => {
        let result = await PromotionModel.getPromotions(null, null, null, null, 1, 20)
        setPromotions(result.data.list)
    }

    useEffect(() => {
        getPromotion()
    }, [])


    useEffect(() => {
        let temp = []
        selectedCities.forEach(item => temp.push(City.getByName(item)))
    }, [selectedCities])

    useEffect(() => {
        let temp = []
        selectedDistricts.forEach(item => temp.push(item))
    }, [selectedDistricts])

    useEffect(() => {
        getPost(props.match.params.id)
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Bài viết"} breadcrumbItem="Tạo bài viết mới" />
                    <Card>
                        {done && <CardBody>
                            <Form>
                                <div className="mb-3">
                                    <Label htmlFor="title">
                                        Tiêu đề{" "}
                                        {
                                            <span style={{ color: "red" }}>
                                                {validation["title"]}
                                            </span>
                                        }
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="title"
                                        placeholder="Nhập tiêu đề bài viết"
                                        onChange={e => {
                                            setField({ ...field, title: e.target.value })
                                        }}
                                        invalid={isSubmit && validation["title"] != null}
                                        value={field.title}
                                    />
                                </div>

                                <Row>
                                    <Col md={6}>
                                        <div className="mb-3 templating-select select2-container">
                                            <label className="control-label">Tỉnh /Thành Phố</label>
                                            <Select
                                                mode="multiple"
                                                onChange={value => {
                                                    setSelectedCities(value)
                                                    let temp = []
                                                    value.forEach(item =>
                                                        temp.push(City.getByName(item).id)
                                                    )
                                                    setField({ ...field, cityIds: temp })
                                                }}
                                                style={{
                                                    width: "100%",
                                                }}
                                                value={field.cityIds?.map(item => City.getName(item))}
                                            >
                                                {filteredCities.map(item => (
                                                    <Select.Option key={item.key} value={item.value}>
                                                        {item.value}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3 templating-select select2-container">
                                            <label className="control-label">Quận/huyện</label>
                                            <Select
                                                mode="multiple"
                                                onChange={value => {
                                                    setSelectedDistricts(value)
                                                    let temp = []
                                                    value.forEach(item =>
                                                        temp.push(District.getByName(item).id)
                                                    )
                                                    setField({ ...field, districtIds: temp })
                                                }}
                                                style={{
                                                    width: "100%",
                                                }}
                                                value={field.districtIds?.map(item => District.getName(item))}
                                            >
                                                {filteredDistricts.map(item => (
                                                    <Select.Option key={item.key} value={item.value}>
                                                        {item.value}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={3}>
                                        <div className="mb-3">
                                            <Label htmlFor="roleIds">Ai xem được bài viết này?</Label>
                                            <select id="roleIds"
                                                className="form-control"
                                                value={field?.roleIds}
                                                onChange={e => setField({
                                                    ...field,
                                                    roleIds: Number(e.target.value),
                                                    roleUsers: e.target.value == 0 ? [2, 3] : [Number(e.target.value)]
                                                })}
                                            >
                                                <option value={0}>Tất cả</option>
                                                <option value={UserType.CONSTRUCTOR}>{UserType.findName(UserType.CONSTRUCTOR)}</option>
                                                <option value={UserType.RETAILER}>{UserType.findName(UserType.RETAILER)}</option>
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm={3}>
                                        <div className="mb-3">
                                            <Label htmlFor="type">Bài viết cho chương trình?</Label>
                                            <select id="type"
                                                className="form-control"
                                                value={field?.type}
                                                onChange={e => setField({ ...field, type: e.target.value })}
                                            >
                                                <option value={0}>Chọn...</option>
                                                <option value={PostType.HAS_PROMOTION}>Có</option>
                                                <option value={PostType.NO_PROMOTION}>Không</option>
                                            </select>
                                        </div>
                                    </Col>
                                    {field?.type == PostType.HAS_PROMOTION && (
                                        <Col sm={3}>
                                            <div className="mb-3">
                                                <Label htmlFor="promotion">Chương trình</Label>
                                                <select
                                                    id="promotion"
                                                    className="form-control"
                                                    value={field?.promotionId}
                                                    onChange={e => setField({ ...field, promotionId: Number(e.target.value) })}>
                                                    <option value={0}>Chọn...</option>
                                                    {promotions
                                                        .filter(item => {
                                                            if (field.roleUsers.length == 2) {
                                                                return true;
                                                            }
                                                            return item.roleUsers.includes(...field?.roleUsers)
                                                        }

                                                        )
                                                        .map(item => (
                                                            <option value={item.id} key={item.id}>
                                                                {item.title}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        </Col>
                                    )}
                                    <Col sm={3}>
                                        <Label htmlFor="duration" style={{ width: "100%" }}>
                                            Khoảng thời gian
                                        </Label>
                                        <RangePicker
                                            value={[
                                                moment(start_time, dateFormat),
                                                moment(end_time, dateFormat),
                                            ]}
                                            onChange={changeCalendar}
                                            format={dateFormat}
                                        />
                                    </Col>
                                </Row>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="preview">Xem trước?</Label>
                                        <select
                                            id="preview"
                                            className="form-control"
                                            value={field?.preview}
                                            onChange={e =>
                                                setField({
                                                    ...field,
                                                    preview:
                                                        e.target.value,
                                                })
                                            }
                                        >
                                            <option value={true}>
                                                Cho phép
                                            </option>
                                            <option value={false}>
                                                Không cho
                                            </option>
                                        </select>
                                    </div>
                                </Col>
                                <div className="mb-3">
                                    <Label htmlFor="summary">
                                        Tóm tắt (<b style={{color: 'red'}}>{field?.summary?.length || 0}</b>/250 ký tự){" "}
                                        {
                                            <span style={{ color: "red" }}>
                                                {validation["summary"]}
                                            </span>
                                        }{" "}
                                    </Label>
                                    <textarea
                                        name="textarea"
                                        label="Textarea"
                                        rows="4"
                                        className={`form-control ${validation["summary"] ? "is-invalid" : ""
                                            }`}
                                        spellCheck="false"
                                        onChange={e =>
                                            setField({ ...field, summary: e.target.value })
                                        }
                                        value={field.summary}
                                    />
                                </div>

                                <div className="mb-3">
                                    <Label htmlFor="content">
                                        Nội dung{" "}
                                        {
                                            <span style={{ color: "red" }}>
                                                {validation["content"]}
                                            </span>
                                        }
                                    </Label>
                                    <MyEditor data={field?.content} setData={(tmp) => setField({ ...field, content: tmp })} />

                                </div>

                                <div className="mb-3 justify-content-end">
                                    <Label htmlFor="duration">
                                        Ảnh đại diện{" "}
                                        {
                                            <span style={{ color: "red" }}>
                                                {validation["cover"]}
                                            </span>
                                        }
                                    </Label>
                                    <Row className="mb-3">
                                        <Col sm={12}>
                                            <UploadImage change_images={change_images} size={1} exits_images={[{ url: field?.cover }]} />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mb-3 justify-content-end">
                                    {/* <Button danger size="small" style={{ width: "100%" }} onClick={() => btn_delete(record)}>
                                        Xóa
                                    </Button> */}
                                    <button
                                        onClick={updatePost}
                                        className="btn btn-primary w-md "
                                        style={{ float: "right" }}
                                    >
                                        Chỉnh sửa bài viết
                                    </button>
                                </div>
                            </Form>
                        </CardBody>}
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    )
}

export default EditArticles
