import * as ApiHelper from '../api_helper'

class HotDealFormModel {
    async getForms({ search, status, type, userId, cityId, promotionId, matchId, start, end, page, pageSize }) {
        let params = Object.fromEntries(
            Object.entries({
                search, status, type, userId, cityId, promotionId, matchId, start, end, page: page - 1, pageSize
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`form/find?${new URLSearchParams(params).toString()}`)
    }

    async getForm(formId) {
        return ApiHelper.get(`hotdeal/form/get?id=${formId}`)
    }

    async updateStatus(id, status, note) {
        return ApiHelper.post2(`hotdeal/form/update-status?id=${id}`, { status, note });
    }
}

export default new HotDealFormModel();
