// TableMember

import { Button, Dropdown, Image, Menu, Popconfirm, Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import * as PostStatus from "constants/PostStatus"
import * as ArticleStatus from "constants/PostStatus"
import TimeUtils from "helpers/time.utils"
import { Link, useHistory } from "react-router-dom"
import ArticleModel from "api/ArticleModel"
import { success, error } from "helpers/notification"
import { PopupConfirm } from "components/pop-confirm"
import queryString from "query-string"
import BroadcastType from "constants/BroadcastType"
import { BarsOutlined } from '@ant-design/icons';
import {
    Row, Col,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap"
import classNames from "classnames"

const Tab = {
    1: {
        id: 1,
        name: "Bài viết",
        template: false
    },
    2: {
        id: 2,
        name: "Template",
        template: true
    }
}
const TableArticle = props => {
    let history = useHistory()
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [filter, setFilter] = useState({
        tab: 1,
        timeActive: false,
        ...props.filter,
        ...queryString.parseUrl(history.location.search).query,
    })
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [isShowOpen, setIsShowOpen] = useState(false)
    const [isApprovedOpen, setIsApprovedOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedPosts, setSelectedPosts] = useState([])
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Ảnh",
            dataIndex: "cover",
            render: cover => {
                return <Image src={cover} width={100} height={56} />
            },
        },
        ((filter?.tab == "2" && !filter.templateId) ? {
            title: "Template",
            dataIndex: "name",
        } : {
            title: "Tiêu đề",
            dataIndex: "title",
        }),
        {
            title: "Bắt đầu",
            dataIndex: "timeStart",
            render: timeStart => TimeUtils.toDDMMYYYY(timeStart),
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: status => (
                <Tag color={PostStatus.findColor(status)} key={status}>
                    {PostStatus.findName(status)}
                </Tag>
            ),
        },
        {
            title: "Hành động",
            render: (_, record) => {
                const template = record.template;
                return (
                    <>
                        <Dropdown
                            overlay={
                                <Menu
                                    items={[
                                        (!template && {
                                            key: "1",
                                            label: (
                                                <div className="col-12 mb-1" style={{ display: "inline" }}>
                                                    <Button
                                                        size="small"
                                                        style={{ width: "100%", display: record?.status === PostStatus.INIT ? "inline" : 'none' }}
                                                        onClick={() => btn_approved(record)}
                                                    >
                                                        Duyệt
                                                    </Button>
                                                </div>
                                            ),
                                        }),
                                        {
                                            key: "2",
                                            label: (
                                                <Button type="link" className="col-12 mb-1">
                                                    <Link to={`/articles/${record.id}/edit`} style={{ width: "100%" }}>Chỉnh sửa</Link>
                                                </Button>
                                            ),
                                        },
                                        (!template && {
                                            key: "3",
                                            label: (
                                                <Button type="link" className="col-12 mb-1" style={{ display: record?.status === PostStatus.APPROVED ? "inline" : "none" }}>
                                                    <Link
                                                        to={`/broadcasts/create?type=${BroadcastType.POST}&postIds=${record.id}`} style={{ width: "100%" }}
                                                    >
                                                        Broadcast
                                                    </Link>
                                                </Button>
                                            ),
                                        }),
                                        (!template && {
                                            key: "4",
                                            label: (
                                                <Button type="link" className="col-12 mb-1"
                                                    style={{
                                                        width: "100%",
                                                        display: "inline"
                                                    }}
                                                    onClick={() => {
                                                        success("copied");
                                                        navigator.clipboard.writeText(`https://zalo.me/s/2644005922350377431/news/${record.id}`)
                                                    }}
                                                >
                                                    Copy URL cửa hàng
                                                </Button>
                                            ),
                                        }),
                                        (!template && {
                                            key: "5",
                                            label: (
                                                <Button type="link" className="col-12 mb-1"
                                                    onClick={() => {
                                                        success("copied");
                                                        navigator.clipboard.writeText(`https://zalo.me/s/2604973040500424243/news/${record.id}`)
                                                    }}
                                                    style={{
                                                        width: "100%",
                                                        display: "inline"
                                                    }}>
                                                    Copy URL nhà thầu
                                                </Button>
                                            ),
                                        }),
                                        (!template && {
                                            key: "6",
                                            label: (
                                                <div className="col-12 mb-1">
                                                    <Button
                                                        danger
                                                        size="small"
                                                        onClick={() => btn_delete(record)}
                                                        style={{ width: "100%", display: record.status !== PostStatus.REMOVED ? "inline" : "none" }}
                                                    >
                                                        Ẩn
                                                    </Button>
                                                </div>
                                            ),
                                        }),
                                        (!template && {
                                            key: "7",
                                            label: (
                                                <div className="col-12 mb-1">
                                                    <Button
                                                        danger
                                                        size="small"
                                                        onClick={() => btn_show(record)}
                                                        style={{ width: "100%", display: record.status === PostStatus.REMOVED ? "inline" : "none" }}
                                                    >
                                                        Hiện
                                                    </Button>
                                                </div>
                                            ),
                                        }),
                                        (template && {
                                            key: "8",
                                            label: (
                                                <Button type="link" className="col-12 mb-1">
                                                    <a href={`/articles?tab=2&templateId=${record.id}`}
                                                        style={{ width: "100%" }}>Danh sách bài viết</a>
                                                </Button>
                                            ),
                                        })
                                    ]
                                    }
                                />
                            }
                            placement="bottom"
                            arrow
                        >
                            <Button type="text"><BarsOutlined /></Button>
                        </Dropdown >
                    </>
                )
            }
        },
    ]

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        )
        let url = queryString.stringify(params)
        history.replace({ search: url })
    }

    const btn_delete = row => {
        setIsDeleteOpen(true)
        setSelectedRow(row)
    }

    const btn_show = row => {
        setIsShowOpen(true)
        setSelectedRow(row)
    }

    const btn_approved = row => {
        setIsApprovedOpen(true)
        setSelectedRow(row)
    }

    const updateStatus = async (id, status, msgSuccess, msgFailed) => {
        setIsApprovedOpen(false)
        setIsDeleteOpen(false)
        setIsShowOpen(false)
        let result = await ArticleModel.updateStatus(id, status)
        if (result.error != 0) {
            error(msgFailed)
            return
        }
        success(msgSuccess)
        fetchData(pagination)
    }

    const fetchData = async newPagination => {
        setLoading(true)

        if (filter.templateId) {
            let results = await ArticleModel.getArticlesByTemplate(filter.templateId);
            setData(results?.data);
            setPagination({
                ...newPagination,
                pageSize: results?.data?.length || 0,
                current: 1,
                total: results?.data?.length || 0,
            })
        } else {
            let results = await ArticleModel.getArticles(
                filter?.title,
                filter?.status,
                filter?.timeActive,
                Tab[filter.tab].template,
                newPagination.current,
                newPagination.pageSize
            )
            setData(results?.data?.list)
            setPagination({
                ...newPagination,
                total: results?.data?.totalPage * newPagination.pageSize,
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData({ current: 1, pageSize: pagination.pageSize })
        serialize2Url()
    }, [filter])

    const handleSelectAllArtilce = (e) => {
        if (selectAllChecked) {
            setSelectAllChecked(false);
            setSelectedPosts([])
        } else {
            setSelectAllChecked(true);
            let newSelectedPosts = [...selectedPosts]
            data.forEach(item => {
                let exits = selectedPosts.find(s => s.id == item.id);
                if (!exits) {
                    newSelectedPosts.push(item);
                }
            });
            setSelectedPosts(newSelectedPosts);
        }
    }

    if (filter?.tab == "2" && filter.templateId > 0) {
        columns.unshift({
            title: <input type="checkbox" checked={selectAllChecked} onChange={handleSelectAllArtilce} />,
            render: (_, record) => {
                const checked = selectedPosts.find(s => s.id == record.id);
                return (
                    <input value={record.id} type="checkbox" checked={checked} onChange={(e) => {
                        if (checked) {
                            setSelectedPosts([...selectedPosts.filter(s => s.id != e.target.value)])
                        } else {
                            setSelectedPosts([...selectedPosts, record])
                        }
                    }} />
                )
            }
        })
    }

    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    return (
        <>
            <Row>
                <Col sm={4}>
                    <Nav pills>
                        {Object.values(Tab).map((tab, index) => {
                            return (
                                <NavItem key={index}>
                                    <NavLink
                                        className={classNames({
                                            active: filter?.tab == tab.id
                                        })}
                                        onClick={() => {
                                            setFilter({
                                                ...filter, tab: tab.id,
                                                templateId: undefined
                                            });
                                            setPagination({ current: 1, pageSize: 10 })
                                        }}
                                    >
                                        <i className="bx bx-chat font-size-20 d-sm-none" />
                                        <span className="d-none d-sm-block">{tab.name}</span>
                                    </NavLink>
                                </NavItem>
                            )
                        })}
                    </Nav>
                </Col>
                <Col sm={8}>
                    <div className="text-sm-end mb-3">
                        <div className="btn-group " role="group">
                            <input
                                className="form-control"
                                type="search"
                                placeholder="Tiêu đề bài viết"
                                style={{ marginLeft: "10px" }}
                                onChange={e => setFilter({ ...filter, title: e.target.value })}
                            />

                            <select
                                className="form-control"
                                style={{ marginLeft: "10px" }}
                                onChange={e =>
                                    setFilter({ ...filter, status: Number(e.target.value) })
                                }
                                value={filter?.status ? filter?.status : 0}
                            >
                                <option value={0}>Trạng thái</option>
                                <option value={ArticleStatus.INIT}>
                                    {ArticleStatus.findName(ArticleStatus.INIT)}
                                </option>
                                <option value={ArticleStatus.APPROVED}>
                                    {ArticleStatus.findName(ArticleStatus.APPROVED)}
                                </option>
                                <option value={ArticleStatus.REMOVED}>
                                    {ArticleStatus.findName(ArticleStatus.REMOVED)}
                                </option>
                            </select>
                            <Button type="primary" style={{ marginLeft: "10px", height: '36px', borderRadius: '0.25rem' }} size={28}>
                                <Link to={`/articles/create`}>Tạo bài viết</Link>
                            </Button>
                            {selectedPosts && selectedPosts.length >= 1 &&
                                <Popconfirm
                                    placement="leftBottom"
                                    title={
                                        <>
                                            <p>
                                                Bạn có chắc muốn broadcast các bài viết sau?
                                            </p>
                                            <ul>
                                                {selectedPosts.map((post, index) => (
                                                    <li key={post.id}>{post.title}</li>
                                                ))}
                                            </ul>
                                        </>
                                    }
                                    onConfirm={() => {
                                        let postIds = selectedPosts.map(s => s.id).join(",");
                                        history.push(`/broadcasts/create?type=2&postIds=${postIds}`)
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="dashed" style={{ color: "red", marginLeft: "10px", height: '36px', borderRadius: '0.25rem' }} size={28}>
                                        Broadcast
                                    </Button>
                                </Popconfirm>

                            }
                        </div>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            {isDeleteOpen && (
                <PopupConfirm
                    isOpen={isDeleteOpen}
                    toggle={() => setIsDeleteOpen(false)}
                    handleSubmit={() => updateStatus(selectedRow.id, PostStatus.REMOVED, 'Xóa bài viết thành công', 'Không thể xóa bài viết')}
                    title="Bạn có muốn xóa bài viết này?"
                    content={{
                        id: selectedRow.id,
                        title: selectedRow.title,
                        summary: selectedRow.summary,
                    }}
                />
            )}
            {isShowOpen && (
                <PopupConfirm
                    isOpen={isShowOpen}
                    toggle={() => setIsShowOpen(false)}
                    handleSubmit={() => updateStatus(selectedRow.id, PostStatus.INIT, 'Đã hiển thị lại bài viết thành công', 'Không thể hiện thị lại bài viết')}
                    title="Bạn có muốn hiện lại bài viết này"
                    content={{
                        id: selectedRow.id,
                        title: selectedRow.title,
                        summary: 'Lưu ý: Sau khi hiện bài viết sẽ quay về trạng thái chờ duyệt',
                    }}
                />
            )}
            {isApprovedOpen && (
                <PopupConfirm
                    isOpen={isApprovedOpen}
                    toggle={() => setIsApprovedOpen(false)}
                    handleSubmit={() => updateStatus(selectedRow.id, PostStatus.APPROVED, 'Duyệt thành công', 'Duyệt bài viết thất bại')}
                    title="Bạn có muốn duyêt bài viết này?"
                    content={{
                        id: selectedRow.id,
                        title: selectedRow.title,
                        summary: selectedRow.summary,
                    }}
                />
            )}
        </>
    )
}

export default TableArticle
