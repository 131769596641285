import React, { useEffect, useRef, useMemo } from 'react';
import { useState } from "react";
import FormAudienceModal from '../modal/form-audience-modal'
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import AudienceModel from 'api/AudienceModel';

const AudienceInput = (props) => {
    const [modal, setModal] = useState(false);
    const [value, setValue] = useState();

    useEffect(() => {
        if (props.value) {
            setValue(props.value)
        }
    }, [props])

    useEffect(() => {
        if (props.value) {
            AudienceModel.get(props.value)
                .then(resp => {
                    if (resp.error == 0) {
                        const audience = resp.data;
                        setValue({ label: audience.alias || audience.id, value: audience.id });
                    }
                })
        }
    }, [props.value])

    return (
        <>
            <DebounceSelect
                showSearch
                value={value}
                className={'custom-select-ant'}
                fetchOptions={search}
                onChange={(option) => {
                    props.onChange(option.value)
                }}
                style={{
                    width: '100%',
                }}
            />
            <FormAudienceModal value={props.value} open={modal} toggle={setModal} onSubmitSuccess={(id) => {
                props.onChange(id)
            }} />
        </>
    )
}


function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);


    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
        />
    );
}

async function search(text) {
    return AudienceModel.search(text)
        .then((resp) => resp.data)
        .then((data) => {
            return data.map((result) => ({
                label: `${result.alias}`,
                value: result.id,
            }))
        });
}

export default AudienceInput;