import UserModel from "api/UserModel";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react"
import TimeUtils from 'helpers/time.utils';

const StatsUserDate = (props) => {

    const [option, setOption] = useState()

    const buildOption = (data) => {
        var categories = []
        var retailers = [];
        var contractors = [];
        for (var i = data.length - 1; i >= 0; i--) {
            retailers.push(data[i].retailer);
            contractors.push([data[i].constructor]);
            categories.push(TimeUtils.toStringNotYear(TimeUtils.parseDate(data[i].date)))
        }
        var dataSeries = [
            {
                "name": "Cửa hàng",
                "data": retailers,
            },
            {
                "name": "Nhà thầu",
                "data": contractors
            }
        ]
        return {
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: categories,
                labels: {
                    format: '{value:%e %b}'
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },

            plotOptions: {
                column: {
                    stacking: 'normal',
                    // dataLabels: {
                    //     enabled: true
                    // },
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            colors: ['#009EE0', '#ff9800'],
            series: dataSeries
        }
    }

    const fetchStatsUserDate = async () => {
        let { start_time, end_time } = props
        let result = await UserModel.statsUserDate(start_time, end_time);
        let data = result.data;
        let opt = buildOption(data)
        setOption(opt)
    }

    useEffect(() => {
        fetchStatsUserDate();
    }, [])

    return (
        <HighchartsReact constructorType={"chart"}
            highcharts={Highcharts} options={option} />
    )
}

export default StatsUserDate
