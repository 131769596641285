import * as ApiHelper from './api_helper'

class BroadcastModel {
    async find(status, start, end, page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                status,
                start,
                end,
                page: page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`broadcast/find?${new URLSearchParams(params).toString()}`)
    }

    async create(form) {
        let { content, name, timeStart, type, userIds, typeTarget = 1, audienceId } = form;
        let data = Object.fromEntries(Object.entries({ content, name, timeStart, type, userIds, typeTarget, audienceId }).filter(([_, v]) => v != null && v != 0));
        return ApiHelper.post2(`broadcast/create`, data);
    }

    async get(id) {
        return ApiHelper.get(`broadcast/get?id=${id}`);
    }

    async updateStatus(id, status) {
        return ApiHelper.get(`broadcast/update-status?id=${id}&status=${status}`);
    }

    async preview(id, phones) {
        let data = { phones }
        return ApiHelper.post(`broadcast/preview?id=${id}`, data);
    }

    async export(status, start, end) {
        let params = Object.fromEntries(
            Object.entries({
                status, start, end
            }).filter(([_, v]) => v != null && v != 0)
        );
        window.open(ApiHelper.getApiUrl() +
            `broadcast/export-excel?${new URLSearchParams(params).toString()}`, '_blank');
        return;
    }
}

export default new BroadcastModel();
