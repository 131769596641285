import React, { useEffect, useState } from "react"
import { Card, CardBody, Container, Row, Col, NavItem, NavLink, Nav, CardHeader } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import queryString from "query-string"
import { useHistory } from "react-router-dom"
import TableAudience from "./table/TableAudience";


const Audience = props => {
    let history = useHistory()

    const [filter, setFilter] = useState({
        ...props.filter,
        ...queryString.parseUrl(history.location.search).query,
    })

    useEffect(() => {
        serialize2Url()
    }, [filter])

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        )
        let url = queryString.stringify(params)
        history.replace({ search: url })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={"Audience"}
                    />
                    <Card>
                        <CardBody>
                            <TableAudience />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Audience
