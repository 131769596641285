import { Tag } from "antd"
import React, { useEffect, useState } from "react"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Table,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { City } from "helpers/ProvinceUtil"
import FormStatus from "constants/FormStatus"
import FormType from "constants/FormType"
import FormModel from "api/FormModel"
import { error, success } from "helpers/notification"
import TimeUtils from "helpers/time.utils"
import { PopupConfirm, PopupConfirmWithNote } from "components/pop-confirm"
import ListGift from "../gifts"

const PredictFootballForm = props => {
    const FORM_ID = props.formId;

    const [form, setForm] = useState({})
    const [isApprovedOpen, setIsApprovedOpen] = useState(false)
    const [isRejectOpen, setIsRejectOpen] = useState(false)

    const updateStatus = async (status, note) => {
        setIsApprovedOpen(false)
        setIsRejectOpen(false)
        let result = await FormModel.updateStatus(form?.id, status, note)
        if (result.error != 0) {
            error("Thất bại")
            return
        }
        success("Thành công")
        fetchData()
    }

    const fetchData = async () => {
        const resp = await FormModel.getForm(FORM_ID)
        if (resp.error != 0) {
            error("Tải lại trang!")
            return
        }
        setForm(resp.data)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Forms"} breadcrumbItem="Chi tiết" />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm="6">
                                    <div className="table-responsive">
                                        <Table className="table-nowrap mb-0">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">ID :</th>
                                                    <td>{form?.id}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Họ tên :</th>
                                                    <td>{form?.user?.name}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">SDT :</th>
                                                    <td><b>{form?.user?.phone}</b></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Tỉnh/Thành phố:</th>
                                                    <td>{City.getName(form?.user?.cityId)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Trạng thái :</th>
                                                    <td>
                                                        <Tag color={FormStatus.findColor(form?.status)}>
                                                            {FormStatus.findName(form?.status)}
                                                        </Tag>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Loại đơn:</th>
                                                    <td>
                                                        <Tag color={'#40a9ff'}>
                                                            {FormType.findName(form?.type)}
                                                        </Tag>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Ghi chú:</th>
                                                    <td>{form?.note}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Ngày tạo:</th>
                                                    <td>
                                                        {TimeUtils.toDDMMYYYY(form?.createdTime * 1000)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="table-responsive">
                                        <Table className="table-nowrap mb-0">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">ID trận đấu: </th>
                                                    <td>
                                                        {form?.match?.id}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Đội chủ nhà: </th>
                                                    <td>
                                                        {form?.match?.oHost?.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Đội khách: </th>
                                                    <td>
                                                        {form?.match?.oGuest?.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Dự đoán: </th>
                                                    <td>
                                                        {form?.winner !== 0 ?
                                                            (form?.winner === form?.match?.oGuest?.id ?
                                                                form?.match?.oGuest?.name + " thắng"
                                                                : form?.match?.oHost?.name + " thắng")
                                                            : "Hòa"}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3" style={{ justifyContent: "end" }}>
                                <Col sm={4}>
                                    {(form.status === FormStatus.INIT)
                                        && <>
                                            <Button
                                                color="primary"
                                                className="btn btn-primary "
                                                style={{ marginLeft: "10px" }}
                                                onClick={() => { setIsApprovedOpen(true) }}
                                            >
                                                Duyệt
                                            </Button>
                                            <Button
                                                color="danger"
                                                className="btn btn-danger "
                                                style={{ marginLeft: "10px" }}
                                                onClick={() => { setIsRejectOpen(false) }}
                                            >
                                                Từ chối
                                            </Button>
                                        </>}
                                </Col>
                            </Row>
                            <PopupConfirm
                                isOpen={isApprovedOpen}
                                toggle={() => setIsApprovedOpen(false)}
                                handleSubmit={() => updateStatus(FormStatus.APPROVED, "")}
                                title="Bạn có muốn duyêt duyệt này?"
                                content={{ id: form.id, status: form.status, type: form.type }}
                            />

                            <PopupConfirmWithNote
                                isOpen={isRejectOpen}
                                toggle={() => setIsRejectOpen(false)}
                                handleSubmit={note => updateStatus(FormStatus.REJECTED, note)}
                                title="Bạn có muốn từ chối đơn này?"
                            />
                        </CardBody>
                    </Card>
                    {form?.giftOrderDTO && <ListGift giftOrder={form?.giftOrderDTO} />}
                </Container>
            </div>
        </React.Fragment>
    )
}

export default PredictFootballForm;
