import React, { useEffect, useMemo, useState } from "react"
import {
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
} from "reactstrap"
import classnames from "classnames"


//Import Breadcrumb
import "./index.css"
import TableMessage from "./table/TableMessage"
import TopicModel from "api/chat/TopicModel"

//redux

const Message = props => {
    const [topics, setTopics] = useState([])
    const [config, setConfig] = useState({
        topicId: 0,
    })

    const fetchTopic = async () => {
        let result = await TopicModel.find(config.topicId)
        setTopics(result.data.list)
    }

    useEffect(() => {
        fetchTopic()
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody style={{ padding: '5px' }}>
                                    <Nav pills>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: config.topicId === 0
                                                })}
                                                onClick={() => {
                                                    setConfig({ topicId: 0 })
                                                }}
                                                style={{ position: "relative", marginRight: '20px' }}
                                            >
                                                <i className="bx bx-chat font-size-20 d-sm-none" />
                                                <span className="d-none d-sm-block">Tất cả</span>
                                            </NavLink>
                                        </NavItem>

                                        {topics && topics.map(item => (
                                            <NavItem key={item.id}>
                                                <NavLink
                                                    className={classnames({
                                                        active: config.topicId === item.id,
                                                    })}
                                                    onClick={() => {
                                                        setConfig({ ...config, topicId: item.id })
                                                    }}
                                                    style={{ position: "relative", marginRight: '20px' }}
                                                >
                                                    <i className="bx bx-group font-size-20 d-sm-none" />
                                                    <span className="d-none d-sm-block">{item.name}</span>
                                                    {item.count > 0 && (
                                                        <span className="button__badge">
                                                            {item.count}
                                                        </span>
                                                    )}
                                                </NavLink>
                                            </NavItem>
                                        ))}
                                    </Nav>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <TableMessage filter={{ topicId: config.topicId }} />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Message;



