import React, { useEffect, useState } from "react"
import { Card, CardBody, Container, Row, Col, NavItem, NavLink, Nav, CardHeader } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableSurvey from "./table/TableSurvey";
import classnames from "classnames";
import queryString from "query-string"
import { useHistory } from "react-router-dom"
import TableTagOptionSurvey from "./table/TableTopicSurvey";


const Survey = props => {
    let history = useHistory()

    const [filter, setFilter] = useState({
        ...props.filter,
        ...queryString.parseUrl(history.location.search).query,
    })

    useEffect(() => {
        serialize2Url()
    }, [filter])

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        )
        let url = queryString.stringify(params)
        history.replace({ search: url })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={"Khảo sát"}
                    />
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col sm={12}>
                                    <div className="text-sm-end mb-3">
                                        <Row>
                                            <Col sm={8}>
                                                <Nav pills>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({
                                                                active: !filter.tab || filter.tab == 'BROADCAST'
                                                            })}
                                                            onClick={() => {
                                                                setFilter({ ...filter, tab: 'BROADCAST' })
                                                            }}
                                                        >
                                                            <i className="bx bx-chat font-size-20 d-sm-none" />
                                                            <span className="d-none d-sm-block">Chiến dịch</span>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({
                                                                active: filter.tab == 'TOPIC'
                                                            })}

                                                            onClick={() => {
                                                                setFilter({ ...filter, tab: 'TOPIC' })
                                                            }}
                                                        >
                                                            <i className="bx bx-chat font-size-20 d-sm-none" />
                                                            <span className="d-none d-sm-block">Chủ đề</span>
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>
                    <Card>
                        <CardBody>
                            {(!filter?.tab || filter?.tab == 'BROADCAST') &&
                                <TableSurvey />
                            }
                            {(filter?.tab == 'TOPIC') &&
                                <TableTagOptionSurvey />
                            }
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Survey
