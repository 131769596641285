// TableMember

import { Avatar, Button, Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import { Link, useHistory } from "react-router-dom"
import queryString from 'query-string'
import {
    Row, Col,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap"
import classnames from "classnames";
import ConstructionModel from "api/ConstructionModel"
import { City, District } from "helpers/ProvinceUtil"
import ConstructionType from "constants/ConstructionType"
import ConstructionStatus from "constants/ConstructionStatus"
import UserConstructionModel from "api/UserConstructionModel"


// TableSurvey
const TableConstruction = props => {
    let history = useHistory();

    let columns = [
        {
            title: "ID",
            dataIndex: "id",
            render: id => id
        },
        {
            title: "Loại công trình",
            dataIndex: "type",
            render: type => <Tag color={ConstructionType.findColor(type)}>
                {ConstructionType.findName(type)}
            </Tag>
        },
        {
            title: "Địa chỉ",
            dataIndex: "address",
            render: address => address
        },
        {
            title: "Tỉnh thành",
            dataIndex: "cityId",
            render: cityId => City.getName(cityId)
        },
        {
            title: "Quận huyện",
            dataIndex: "districtId",
            render: districtId => District.getName(districtId)
        },
        {
            title: "Trang thái",
            dataIndex: "status",
            render: status => <Tag color={ConstructionStatus.findColor(status)}>
                {ConstructionStatus.findName(status)}
            </Tag>
        },
        {
            title: "Hành động",
            dataIndex: "id",
            render: id => (
                <Button color="primary"
                    className="btn btn-primary"
                    onClick={() => history.push(`/constructions/${id}`)}>
                    Chi tiết
                </Button>
            ),
        },
    ]

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [filter, setFilter] = useState({ ...props.filter, ...queryString.parseUrl(history.location.search).query })

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.replace({ search: url })
    };

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await ConstructionModel.find(
            newPagination.current,
            newPagination.pageSize
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }


    useEffect(() => {
        fetchData({ current: 1, pageSize: pagination.pageSize })
        serialize2Url()
    }, [filter])


    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    const exportExcel = () => {
        UserConstructionModel.export()
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="text-sm-end mb-3">
                        <Row>
                            <Col sm={12}>
                                <div className="btn-group" role="group">
                                    <Button
                                        type="primary"
                                        onClick={exportExcel}
                                    >
                                        Export
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />

        </>
    )
}

export default TableConstruction
