import * as ApiHelper from './api_helper'

class CountryModel {
    async find(page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                page: page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`country/find?${new URLSearchParams(params).toString()}`)
    }

}

export default new CountryModel();
