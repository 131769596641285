import * as ApiHelper from './api_helper'

class AuthModel {
    async getMembers(search, labelId, roleId, city, status, page, pageSize) {
        let params = Object.fromEntries(
            Object.entries({
                search, roleId, city, status, labelId,
                page: page && page - 1,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );
        return ApiHelper.get(`user/find?${new URLSearchParams(params).toString()}`)
    }

    async getMember(id) {
        return ApiHelper.get(`user/get?id=${id}`)
    }

    async countMembers(start, end) {
        return ApiHelper.get(`user/count?start=${start}&end=${end}`);
    }

    async countLabels(start, end) {
        return ApiHelper.get(`user/count-label?start=${start}&end=${end}`);
    }

    async export(status, city, labelId, roleId) {
        let param = Object.fromEntries(
            Object.entries({
                status,
                city,
                roleId,
                labelId,
            }).filter(([_, v]) => v != null && v != 0)
        );
        window.open(ApiHelper.getApiUrl() +
            `user/export-excel?${new URLSearchParams(param).toString()}`, '_blank');
        return;
    }

    async statsUserCity(start, end) {
        return ApiHelper.get(`user/stats-user-city?start=${start}&end=${end}`)
    }

    async statsUserDate(start, end) {
        return ApiHelper.get(`user/stats-user-date?start=${start}&end=${end}`)
    }

    async update(id, data) {
        return ApiHelper.post(`user/update?id=${id}`, data);
    }

    async updateStatus(id, status, note) {
        return ApiHelper.post2(`user/update-status?id=${id}`, { status, note });
    }

    async findIdsByListPhone(phones, roleId) {
        return ApiHelper.post(`user/find-ids`, { phones, roleId });
    }

    async findIds(roleId, labelId, cityIds, districtIds) {
        let params = Object.fromEntries(
            Object.entries({
                roleId, labelId, cityIds, districtIds
            }).filter(([_, v]) => v != null && v != 0)
        );

        return ApiHelper.get(`user/find-ids?${new URLSearchParams(params).toString()}`)
    }

    async analytics(start, end) {
        let params = Object.fromEntries(
            Object.entries({
                start, end
            }).filter(([_, v]) => v != null && v != 0)
        );

        return ApiHelper.get(`user/analytics?${new URLSearchParams(params).toString()}`)
    }
}

export default new AuthModel();
