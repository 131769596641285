// TableMember

import { Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"
import queryString from "query-string"
import {
    Row, Col,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap"
import classnames from "classnames";
import { City } from "helpers/ProvinceUtil"
import AccumulationModel from '../../../api/AccumulationModel'
import * as UserType from '../../../constants/UserType'
import { Button } from 'reactstrap';

const TableLeaderBoad = props => {
    let history = useHistory()
    let columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Thành viên",
            dataIndex: "user",
            render: user => user.name,
        },
        {
            title: "Số điện thoại",
            dataIndex: "user",
            render: user => user.phone,
        },
        {
            title: "Tỉnh thành",
            dataIndex: "user",
            render: user => City.getName(user.cityId),
        },
        {
            title: "Chức vụ",
            dataIndex: "user",

            render: user => <Tag color={UserType.findColor(user.roleId)} key={user.roleId}>
                {UserType.findName(user.roleId)}
            </Tag>
        },
        {
            title: "Điểm",
            dataIndex: "point",
            render: point => <Tag color={"#4caf50"}>{point}</Tag>
        },

    ]

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [filter, setFilter] = useState({
        ...props.filter,
        ...queryString.parseUrl(history.location.search).query,
    })
    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.replace({ search: url })
    };

    const fetchData = async newPagination => {
        setLoading(newPagination)
        let results = await AccumulationModel.leaderboard(
            {
                search: filter?.search,
                cityId: filter?.cityId,
                promotionId: props.promotionId,
                roleId: filter?.roleId,
                page: newPagination.current,
                pagesize: newPagination.pageSize
            }
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData({
            current: 1,
            pageSize: 10,
        })
        serialize2Url()
    }, [filter])

    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    const export_excel = () => {
        AccumulationModel.export_leader_broad({ ...filter, promotionId: props.promotionId })
    }

    return (
        <>
            <Row>
                <Col sm={4}>
                    <Nav pills>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: false
                                })}
                                onClick={() => {
                                    history.push(
                                        `/promotions/matchs/${props.promotionId}`
                                    )
                                }}
                            >
                                <i className="bx bx-chat font-size-20 d-sm-none" />
                                <span className="d-none d-sm-block">Dánh sách trận đấu</span>
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: true
                                })}
                                onClick={() => {
                                    history.push(
                                        `/promotions/matchs/leaderboard/${props.promotionId}`
                                    )
                                }}
                            >
                                <i className="bx bx-chat font-size-20 d-sm-none" />
                                <span className="d-none d-sm-block">Bảng Xếp hạng</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
                <Col sm={12} className="mb-3 text-end" >
                    <div className="btn-group" role="group">
                        <input
                            className="form-control"
                            type="search"
                            placeholder="SDT hoặc tên khách hàng"
                            style={{ marginLeft: "10px" }}
                            onChange={e => setFilter({ ...filter, search: e.target.value })}
                        />
                        <select
                            className="form-control"
                            style={{ marginLeft: "10px" }}
                            onChange={e =>
                                setFilter({ ...filter, cityId: Number(e.target.value) })
                            }
                            value={filter?.cityId ? filter?.cityId : 0}
                        >
                            <option value={0}>Tỉnh/Thành Phố</option>
                            {City.getList().map(value => {
                                return (
                                    <option key={value.key} value={value.key}>
                                        {value.value}
                                    </option>
                                )
                            })}
                        </select>
                        <select
                            className="form-control"
                            style={{ marginLeft: "10px" }}
                            onChange={e =>
                                setFilter({ ...filter, roleId: Number(e.target.value) })
                            }
                            value={filter?.roleId ? filter?.roleId : 0}
                        >
                            <option value={0}>Khách hàng</option>
                            <option value={UserType.CONSTRUCTOR}>{UserType.findName(UserType.CONSTRUCTOR)}</option>
                            <option value={UserType.RETAILER}>{UserType.findName(UserType.RETAILER)}</option>
                        </select>
                        <Button
                            color="primary"
                            className="btn btn-primary "
                            style={{ marginLeft: "10px" }}
                            size={24}
                            onClick={export_excel}
                        >
                            Export
                        </Button>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    )
}

export default TableLeaderBoad
