// TableMember

import React from "react";
import { useParams } from "react-router-dom";
import HotDealTableForm from "pages/forms/tablev2/HotDealTableForm";

const HotdealProgram = props => {
    const { promotionId } = useParams()

    return (
        <>
            <HotDealTableForm promotionId={promotionId} />
        </>
    )
}

export default HotdealProgram
