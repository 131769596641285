class PromotionStatus {
    static INIT = 1;
    static APPROVED = 2;
    static REMOVED = 3;

    static findName(value) {
        switch (value) {
            case this.INIT: return 'Khởi tạo'
            case this.APPROVED: return 'Đã công khai'
            case this.REMOVED: return 'Đã xóa'
        }
    }

    static findColor(value) {
        switch (value) {
            case this.INIT: return 'orange'
            case this.APPROVED: return '#20c997'
            case this.REMOVED: return '#dc3545'
        }
    }
}

export default PromotionStatus;