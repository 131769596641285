// TableMember

import { Avatar, Table, Tag } from "antd"
import React, { useEffect, useState } from "react"

import { Link, useHistory } from "react-router-dom"
import queryString from 'query-string'
import {
    Button
} from "reactstrap"
import UserConstructionModel from "api/UserConstructionModel"
import { City } from "helpers/ProvinceUtil"
import * as CementUtils from "helpers/CementUtil"


// TableSurvey
const TableUserConstruction = props => {
    const constructionId = props.constructionId
    let history = useHistory();

    let columns = [
        {
            title: "ID",
            dataIndex: "id",
            render: id => id
        },
        {
            title: "Ảnh",
            dataIndex: "user",
            render: user => <Avatar src={user?.avatar} size="large" />,
        },
        {
            title: "Họ tên",
            dataIndex: "user",
            render: user => user.name
        },
        {
            title: "SDT",
            dataIndex: "user",
            render: user => user.phone
        },
        {
            title: "Tỉnh/Thành Phố",
            dataIndex: "user",
            render: user => City.getName(user?.cityId),
        },
        {
            title: "Tích lũy",
            dataIndex: "cementAccumulation",
            render: cementAccumulation => {
                if (!cementAccumulation) {
                    return ''
                }
                return Object.entries(cementAccumulation)
                    .map(([key, value]) => {
                        return (
                            <div key={key}>
                                {CementUtils.findById(Number(key)).name} : <b>{value}</b>
                                <br />
                            </div>
                        )
                    })
            }
        },
        {
            title: "Hành động",
            dataIndex: "id",
            render: id => (
                <Button color="primary"
                    className="btn btn-primary"
                    onClick={() => history.push(`/loyalty-invoice/forms?userConstructionId=${id}`)}>
                    Danh sách đơn
                </Button>
            ),
        },
    ]

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })

    const [filter, setFilter] = useState({ ...props.filter, ...queryString.parseUrl(history.location.search).query })

    const serialize2Url = () => {
        let params = Object.fromEntries(
            Object.entries(filter).filter(([_, v]) => v != null && v != 0)
        );
        let url = queryString.stringify(params)
        history.replace({ search: url })
    };

    const fetchData = async (newPagination) => {
        setLoading(true)
        let results = await UserConstructionModel.find(
            constructionId,
            newPagination.current,
            newPagination.pageSize
        )
        setData(results?.data?.list)
        setPagination({
            ...newPagination,
            total: results?.data?.totalPage * newPagination.pageSize,
        })
        setLoading(false)
    }


    useEffect(() => {
        fetchData({ current: 1, pageSize: pagination.pageSize })
        serialize2Url()
    }, [filter])


    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(newPagination)
        fetchData(newPagination)
    }

    return (
        <>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />

        </>
    )
}

export default TableUserConstruction
