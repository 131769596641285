class CementOrderStatus {
    static INIT = 1;
    static ADMIN_APPROVED = 2;
    static ADMIN_REJECTED = 3;
    static CANCELED = 4;
    static DISTRIBUTOR_ACCEPTED = 20;
    static DISTRIBUTOR_REJECTED = 21;
    static DELIVERED = 30;
    static DONE = 50;

    static findNameByStatus(status) {
        switch (status) {
            case this.INIT:
                return 'Chờ xác nhận';
            case this.ADMIN_APPROVED:
                return 'Đã xác nhận';
            case this.ADMIN_REJECTED:
            case this.DISTRIBUTOR_REJECTED:
                return 'Từ chối';
            case this.DISTRIBUTOR_ACCEPTED:
                return 'Đã xác nhận'
            case this.DELIVERED:
                return 'Đang giao'
            case this.DONE:
                return 'Đã hoàn thành'
            case this.CANCELED:
                return 'Đã hủy'
            default:
                return 'Lỗi'
        }
    }
    static findBgColorByStatus(status) {
        switch (status) {
            case this.INIT:
            case this.ADMIN_APPROVED:
                return '#64a338';
            case this.ADMIN_REJECTED:
            case this.DISTRIBUTOR_REJECTED:
                return '#FF2A04';
            case this.DISTRIBUTOR_ACCEPTED:
                return '#3865a3'
            case this.DELIVERED:
                return '#ffcc00'
            case this.DONE:
                return '#7cd992'
            case this.CANCELED:
                return '#7B8089'
            default:
                return 'Lỗi'
        }
    }
}

export default CementOrderStatus;