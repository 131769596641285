
class CurrencyUtils {
    static formatVND = (number) => {
        const symbol = "đ";

        const formattedNumber = number.toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND",
        });

        return formattedNumber.replace("₫", symbol);
    };
}

export { CurrencyUtils }